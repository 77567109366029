import { convertObjectToQueryString } from 'common/utils/convertObjectToQueryString';
import { AutomationStatus } from 'components/AutomationStatus/AutomationStatus';
import { BinaryIcon } from 'components/BinaryIcon/BinaryIcon';
import { Button } from 'components/Button/Button';
import { CreateButton } from 'components/Button/Buttons';
import { usePaginatedAutomationsWithProject } from 'modules/api/locHub/automations/query/paginatedList/paginatedList';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppLayout } from '../../../components/AppLayout/AppLayout';
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs';
import { DataTable } from '../../../components/DataTable/DataTable';
import { AdminTabs } from '../../../components/Tabs/Tabs';
import { TD } from '../../../components/TD/TD';
import { HomePageCrumb } from '../../Overview/HomePage/HomePage';
import { AdminPageCrumb } from '../AdminPage/AdminPage';

export const AutomationListPageCrumb: Breadcrumb = {
  page: 'Administration.AutomationListPage',
  icon: 'sync',
  location: '/admin/automations',
};

export const AutomationListPage: React.FC = (): ReactElement => {
  const [t] = useTranslation();
  const namespace = AutomationListPageCrumb.page;
  const [page, setPage] = useState<number | undefined>(undefined);
  const [archiveToggle, setArchiveToggle] = useState<string>(convertObjectToQueryString({ archived: 'false' }));
  const automations = usePaginatedAutomationsWithProject(page, { query: archiveToggle });
  return (
    <AppLayout breadcrumbs={[HomePageCrumb, AdminPageCrumb, AutomationListPageCrumb]}>
      <AdminTabs>
        <CreateButton
          id={'create-automation-button'}
          i18nKey="Administration.AutomationListPage.createButton"
          to="/admin/automations/new"
        />
        <DataTable
          i18nKey={namespace}
          setPage={setPage}
          data={automations.success ? automations.data : undefined}
          columns={['project', 'name', 'active', 'createdAt', 'lastRunTime', 'lastRunStatus', '']}
          setArchiveToggle={setArchiveToggle}
          archiveToggleText={t(`Administration.AutomationListPage.toggleButton`)}
          render={({
            id,
            project,
            name,
            active,
            createdAt,
            lastRunTimestamp,
            errorMessage,
            archived,
          }): ReactElement => (
            <tr key={id}>
              <TD>
                <Link to={`/projects/${project.id}`}>{project.name}</Link>
              </TD>
              <TD wrap>
                {name}&nbsp;
                {archived && (
                  <span className="tag is-light is-info">{t('Administration.AutomationListPage.archived')}</span>
                )}
              </TD>
              <TD>
                <BinaryIcon value={active} />
              </TD>
              <TD>{t('locale.date', { date: new Date(createdAt) })}</TD>
              <TD>
                {' '}
                {new Date(lastRunTimestamp).getTime() > new Date(createdAt).getTime() + 100 &&
                  t('locale.fromNow', { date: new Date(lastRunTimestamp) })}
              </TD>
              <TD>
                <AutomationStatus
                  texts={{
                    success: t(`${namespace}.success`),
                    error: t(`${namespace}.error`),
                    pending: t(`${namespace}.pending`),
                  }}
                  createdAt={new Date(createdAt)}
                  lastRunTimestamp={new Date(lastRunTimestamp)}
                  errorMessage={errorMessage}
                />
              </TD>
              <TD>
                <div className="buttons is-small is-pulled-right">
                  <Button to={`/admin/automations/${id}`} i18nKey="Administration.AutomationListPage.viewButton">
                    <span className="icon is-small">
                      <i className="fas fa-info" />
                    </span>
                  </Button>
                  <Button to={`/admin/automations/${id}/edit`} i18nKey="Administration.AutomationListPage.editButton">
                    <span className="icon is-small">
                      <i className="fas fa-edit" />
                    </span>
                  </Button>
                </div>
              </TD>
            </tr>
          )}
        />
      </AdminTabs>
    </AppLayout>
  );
};

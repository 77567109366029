import { dictionary } from 'common/language/dictionary';
import { I18nNamespace } from 'common/language/namespace';

export const AutomationDelayInputI18n = {
  configuration: { namespace: I18nNamespace.AUTOMATION_DELAY_INPUT, labelKey: 'label' },
  translations: {
    label: dictionary.automationDelay,
    placeholder: dictionary.automationDelay,
    validationMaximumError: dictionary.validationMaximumError,
    validationMinimumError: dictionary.validationMinimumError,
    validationRequiredError: dictionary.validationRequiredError,
  },
};

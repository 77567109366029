import React, { Children, ReactElement, createContext, useContext, useMemo } from 'react';

import { Step, StepInfo, Props as StepProps } from './Step';

export type Props<T extends object> = {
  children: ReactElement<StepProps<T>> | ReactElement<StepProps<T>>[];
  startIndex?: number;
  index?: number;
  onIndexChange?: (newIndex: number) => void;
};

function Wizard<T extends object>({ children, startIndex, index, onIndexChange }: Props<T>): ReactElement {
  const steps = Children.toArray(children);

  const { currentStep, nextStep, prevStep, gotoStep } = useWizard(steps.length - 1, startIndex, index, onIndexChange);
  const context = useMemo(
    () => ({
      currentStep,
      nextStep,
      prevStep,
      gotoStep,
    }),
    [currentStep, gotoStep, nextStep, prevStep],
  );

  return (
    <Wizard.Context.Provider value={context}>
      {steps.filter((step, i) => i <= (index || currentStep))}
    </Wizard.Context.Provider>
  );
}

function useWizard<T extends object>(
  size: number,
  startIndex = 0,
  index?: number,
  onIndexChange?: (newIndex: number) => void,
): Omit<StepInfo<T>, 'value'> {
  // useEffect(() => onIndexChange && onIndexChange(index || startIndex), [index]);

  const i = index || startIndex;

  const nextStep = (): void => {
    if (i >= size) return;
    if (onIndexChange) onIndexChange(i + 1);
  };
  const prevStep = (): void => {
    if (i <= 0) return;
    if (onIndexChange) onIndexChange(i - 1);
  };
  const gotoStep = (step: number): void => {
    if (step < 0 || step > size) return;
    if (onIndexChange) onIndexChange(step);
  };

  return {
    currentStep: i,
    nextStep,
    prevStep,
    gotoStep,
  };
}

export function useWizardContext(): StepInfo<object> {
  const context = useContext(Wizard.Context);

  if (!context) {
    throw new Error('Wizard.Step cannot be rendered outside of a Wizard-component');
  }
  return context;
}

Wizard.Step = function<T extends object>(props: StepProps<T>): ReactElement {
  return <Step {...props} />;
};
Wizard.Context = createContext<StepInfo<object> | null>(null);

export default Wizard;

export class Field {
  private constructor() {
    // no-op
  }

  public static isEmpty(value: string | undefined): boolean {
    if (value === undefined) {
      return true;
    }
    if (!value.length) {
      return true;
    }
    return value.trim().length === 0;
  }
}

import { AutomationId } from 'modules/api/locHub/automations/automation/id/id';
import { AutomationListPageCrumb } from 'pages/Administration/AutomationListPage/AutomationListPage';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export interface Props {
  automationId?: AutomationId;
}

export const JobAutomationTag: React.FC<Props> = ({ automationId }): ReactElement => {
  const [t] = useTranslation();
  return (
    <>
      {automationId && (
        <Link to={`${AutomationListPageCrumb.location}/${automationId}`} style={{ marginRight: '4px' }}>
          <span className="tag is-light is-info">{t('Overview.JobsListPage.automation')}</span>
        </Link>
      )}
    </>
  );
};

export const dictionary = {
  selectAll: {
    en: 'Select all',
    nl: 'Selecteer alles',
  },
  deselectAll: {
    en: 'Deselect all',
    nl: 'Deselecteer alles',
  },
  noOptions: {
    en: 'No options are available',
    nl: 'Er zijn geen opties beschikbaar',
  },
  recursiveCrawl: {
    en: 'Include subfolders recursively',
    nl: 'Voeg submappen recursief toe',
  },
  automaticallyMarkForTranslation: {
    en: 'Automatically mark for translation',
    nl: 'Automatisch markeren voor vertaling',
  },
  automaticallyAcceptTranslation: {
    en: 'Automatically accept translation',
    nl: 'Vertaling automatisch accepteren',
  },
  filter: {
    en: 'Filter',
    nl: 'Filter',
  },
  clear: {
    en: 'Clear',
    nl: 'Doorzichtig',
  },
  save: {
    en: 'Save',
    nl: 'Sparen',
  },
  cms: {
    en: 'Content Management System',
    nl: 'Contentmanagement systeem',
  },
  selectCms: {
    en: 'Please select a CMS',
    nl: 'Selecteer een CMS',
  },
  cmsPaths: {
    en: 'Content Management System paths',
    nl: 'Content Management System-paden',
  },
  selectCmsPaths: {
    en: 'Select Content Management System paths',
    nl: 'Selecteer Content Management System-paden',
  },
  project: {
    en: 'Project',
    nl: 'Project',
  },
  selectProject: {
    en: 'Please select a Project',
    nl: 'Selecteer een project',
  },
  createProject: {
    en: 'Create Project',
    nl: 'Project maken',
  },
  createProjectDescription: {
    en: 'Create a new project and add it to LocHub.',
    nl: 'Maak een nieuw project en voeg het toe aan LocHub.',
  },
  createAutomation: {
    en: 'Create Automation',
    nl: 'Automatisering maken',
  },
  createAutomationDescription: {
    en: 'Create a new project automation.',
    nl: 'Maak een nieuwe projectautomatisering.',
  },
  editJob: {
    en: 'Edit Job',
    nl: 'Job Bewerk',
  },
  editJobDescription: {
    en: 'Edit the properties of a Job.',
    nl: 'Bewerk de eigenschappen van een Job.',
  },
  jobName: {
    en: 'Job name',
    nl: 'Job naam',
  },
  projectName: {
    en: 'Project name',
    nl: 'Project naam',
  },
  automationName: {
    en: 'Automation name',
    nl: 'Automatisering naam',
  },
  projectDescription: {
    en: 'Project description',
    nl: 'Projectbeschrijving',
  },
  automationDescription: {
    en: 'Automation description',
    nl: 'Automation beschrijving',
  },
  automationPeriod: {
    en: 'Time between runs (in minutes)',
    nl: 'Tijd tussen runs (in minuten)',
  },
  automationDelay: {
    en: 'The grace period for which not to pick up recently changed files (in minutes)',
    nl: 'De respijtperiode waarvoor recent gewijzigde bestanden niet worden opgehaald (in minuten)',
  },
  enabled: {
    en: 'Enabled',
    nl: 'Ingeschakeld',
  },
  dataLoadingError: {
    en: 'Failed to load data from the server.',
    nl: 'Kan gegevens van de server niet laden.',
  },
  jobUpdateSuccess: {
    en: 'Job was updated successfully.',
    nl: 'Job is succesvol bijgewerkt.',
  },
  jobUpdateFailure: {
    en: 'Failed to update Job.',
    nl: 'Job kan niet worden bijgewerkt.',
  },
  projectCreateSuccess: {
    en: 'Project was created successfully.',
    nl: 'Project is met succes gemaakt.',
  },
  projectCreateFailure: {
    en: 'Failed to create Project.',
    nl: 'Kan project niet maken.',
  },
  automationCreateSuccess: {
    en: 'Project Automation was created successfully.',
    nl: 'Projectautomatisering is met succes gemaakt.',
  },
  automationCreateFailure: {
    en: 'Failed to create Project Automation.',
    nl: 'Kan projectautomatisering niet maken.',
  },
  automationUpdateSuccess: {
    en: 'Project Automation was updated successfully.',
    nl: 'Projectautomatisering is succesvol bijgewerkt.',
  },
  automationUpdateFailure: {
    en: 'Failed to update Project Automation.',
    nl: 'Het updaten van Project Automation is mislukt.',
  },
  archivedJobNotEditable: {
    en: 'Archived jobs may not be updated any more.',
    nl: 'Gearchiveerde taken worden mogelijk niet meer bijgewerkt.',
  },
  jobNotFound: {
    en: 'Job with id {{jobId}} does not exist.',
    nl: 'De gekozen opdracht met id {{jobId}} bestaat niet.',
  },
  validationMaxLengthError: {
    en: 'Maximum length of {{maxLength}} characters exceeded.',
    nl: 'Maximale lengte van {{maxLength}} tekens overschreden.',
  },
  validationMinimumError: {
    en: 'Value may not be lower than {{minimum}}.',
    nl: 'Waarde mag niet lager zijn dan {{minimum}}.',
  },
  validationMaximumError: {
    en: 'Value may not be higher than {{maximum}}.',
    nl: 'Waarde mag niet hoger zijn dan {{maximum}}.',
  },
  validationRequiredError: {
    en: 'This field is required.',
    nl: 'dit veld is verplicht.',
  },
  submit: {
    en: 'Submit',
    nl: 'Verzenden',
  },
  reset: {
    en: 'Reset',
    nl: 'Reset',
  },
  cancel: {
    en: 'Cancel',
    nl: 'Annuleer',
  },
  deleteJob: {
    en: 'Delete Job',
    nl: 'Job verwijderen',
  },
  archiveJobSuccess: {
    en: 'Job was archived successfully.',
    nl: 'Job is met succes gearchiveerd.',
  },
  archiveJobFailure: {
    en: 'Failed to archive Job.',
    nl: 'Kan Job niet archiveren.',
  },
  deleteJobSuccess: {
    en: 'Job was deleted successfully.',
    nl: 'Job is succesvol verwijderd.',
  },
  deleteJobFailure: {
    en: 'Failed to delete Job.',
    nl: 'Kan Job niet verwijderen.',
  },
  archiveJob: {
    en: 'Archive Job',
    nl: 'Job archiveren',
  },
  targetLanguages: {
    en: 'Target Languages',
    nl: 'Doeltalen',
  },
  selectTargetLanguage: {
    en: 'Please select target languages',
    nl: 'Selecteer de doeltalen',
  },
  sourceLanguage: {
    en: 'Source Language',
    nl: 'Brontaal',
  },
  selectSourceLanguage: {
    en: 'Please select a source Language',
    nl: 'Selecteer een brontaal',
  },
  sourceLanguagesFilter: {
    en: 'Source Languages which should be picked up',
    nl: 'Brontalen die moeten worden opgepikt',
  },
  dangerZone: {
    en: 'Danger Zone',
    nl: 'Gevarenzone',
  },
  dangerZoneWarning: {
    en: 'These operations are potentially destructive.',
    nl: 'Deze operaties kunnen onomkeerbare gevolgen hebben.',
  },
  confirm: {
    en: 'Click to confirm',
    nl: 'Klik om te bevestigen',
  },
};

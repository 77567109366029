import { Api } from 'modules/api/api';
import { AssetDto } from 'modules/api/locHub/assets/asset/asset';
import { InputType } from 'modules/api/locHub/inputs/input/type/type';
import { JobId } from 'modules/api/locHub/jobs/job/id/id';
import { JobDto } from 'modules/api/locHub/jobs/job/job';
import { TapiccStatus } from 'modules/api/locHub/tasks/task/status/status';
import { TaskType } from 'modules/api/locHub/tasks/task/type/type';
import { Dispatch } from 'react';

import { isError } from '../../../../common/interfaces/common';
import { LanguageInfo } from '../TaskCreatePage';
import { TaskErrorMessage } from './ConfirmStep';

export function createLanguageInfo(e: AssetDto, job: undefined | JobDto): LanguageInfo<AssetDto> {
  return {
    data: e,
    sourceLanguage: {
      code: e.language,
      cms: undefined,
      default: '',
    },
    targetLanguageCodes: job?.defaultTargetLanguages || [],
    task: {
      status: undefined,
      error: undefined,
    },
  };
}

export async function submit(
  jobId: JobId,
  languageInfo: LanguageInfo<AssetDto>[],
  setLanguageInfo: Dispatch<LanguageInfo<AssetDto>>,
): Promise<void> {
  const callStack = languageInfo.map(async info => {
    setLanguageInfo({
      ...info,
      task: {
        status: 'pending',
        error: undefined,
      },
    });

    try {
      for (const targetLanguage of info.targetLanguageCodes) {
        const task = await Api.locHub.tasks.create({
          jobId,
          name: info.data.name,
          sourceLanguage: info.sourceLanguage.code,
          targetLanguage: targetLanguage,
          status: TapiccStatus.PENDING,
          type: TaskType.TRANSLATION,
        });
        await Api.locHub.inputs.create({
          assetId: info.data.id,
          inputAs: InputType.SOURCE,
          taskId: task.id,
        });
      }
      setLanguageInfo({ ...info, task: { status: 'success', error: undefined } });
      return;
    } catch (e) {
      if (isError(e)) {
        setLanguageInfo({ ...info, task: { status: 'error', error: { key: e.message } } });
      } else {
        setLanguageInfo({ ...info, task: { status: 'error', error: handleErrorMessage() } });
      }
      return;
    }
  });

  await Promise.all(callStack);
}

function handleErrorMessage(): TaskErrorMessage {
  return { key: 'Overview.TaskCreatePage.summary.error.unknown' };
}

import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export interface Props {
  i18nKey: string;
}

export const WizardStep: React.FC<Props> = ({ i18nKey, children }): ReactElement => {
  const [t] = useTranslation();

  return (
    <div className="box step">
      <h2 className="title">{t(`${i18nKey}.title`)}</h2>
      <p className="subtitle">{t(`${i18nKey}.description`)}</p>
      <hr />
      <div className="is-clearfix">{children}</div>
    </div>
  );
};

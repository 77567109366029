import { TFunction } from 'i18next';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { XillioEntity } from '../../common/interfaces/xillio';
import { Translatable } from '../../common/language';
import { classNames } from '../../common/utils/classNames';
import { LanguageTag } from '../LanguageTag/LanguageTag';
import { FileIcon } from './FileIcon';
import { getDescription, getEntityName, getPrettyFileZie, getShort, isFolder } from './utilities';

// Object containing all extra metadata we want to show.
// It's an object with a key, which is used as the name (for id's and translation)
// The value is a function with an entity and the mapping to the relevant value.
const metadata: { [key: string]: (e: XillioEntity, t: TFunction) => undefined | string | number | ReactElement } = {
  sourceLanguage: e => {
    const language = e.original.language?.tag;
    return language ? (
      <div className="has-text-centered">
        <LanguageTag language={language} />
      </div>
    ) : (
      undefined
    );
  },
  createdAt: (e, t) => {
    const date = e.original.created?.date;
    if (date) {
      const result = t('locale.date', { date: new Date(date) });
      return result || undefined;
    }
    return undefined;
  },
  updatedAt: (e, t) => {
    const date = e.original.modified?.date;
    if (date) {
      const result = t('locale.fromNow', { date: new Date(date) });
      return result || undefined;
    }
    return undefined;
  },
  fileSize: e => getPrettyFileZie(e) || '-',
  contentType: e => e.original.contentType?.displayName,
};

// Header row for the File table in the FileBrowser.
export const FileHeader: React.FC<Translatable & { selected: boolean; toggleSelection: () => void }> = ({
  i18nKey,
  selected,
  toggleSelection,
}): ReactElement => {
  const [t] = useTranslation();

  return (
    <div className="fb-row fb-header">
      <div className="fb-cell cell-selection">
        <Selection selected={selected} onClick={toggleSelection} />
      </div>
      <div className="fb-cell cell-icon">{/* Icon */}</div>
      <div className="fb-cell cell-name">{t(`${i18nKey}.table.name`)}</div>
      {Object.keys(metadata).map(key => (
        <div key={key} className={classNames('fb-cell', 'cell-' + key)}>
          {t(`${i18nKey}.table.${key}`)}
        </div>
      ))}
    </div>
  );
};

export const FileRow: React.FC<Translatable & {
  entity: XillioEntity;
  setParent: () => void;
  selected: boolean;
  toggleSelection: () => void;
}> = ({ entity, setParent, selected, toggleSelection }): ReactElement => {
  const [t] = useTranslation();

  return (
    <div className="fb-row" onClick={toggleSelection}>
      <div className="fb-cell cell-selection">
        <Selection selected={selected} onClick={toggleSelection} />
      </div>
      <div className="fb-cell cell-icon">
        <FileIcon entity={entity} />
      </div>
      <div className="fb-cell cell-name">
        <div className="cell__content">
          <span
            className={classNames(isFolder(entity) && 'is-clickable')}
            onClick={(e): void => {
              if (isFolder(entity)) {
                e.preventDefault();
                e.stopPropagation();
                setParent();
              }
            }}
          >
            {getShort(getEntityName(entity))}
          </span>
        </div>
        {getDescription(entity) && (
          <div className="cell__description">
            <span>{getShort(getDescription(entity))}</span>
          </div>
        )}
        {/* Kebab menu*/}
      </div>
      {Object.entries(metadata).map(([key, val]) => (
        <div key={key} className={classNames('fb-cell', 'cell-' + key, 'has-text-grey-light')}>
          {val(entity, t)}
        </div>
      ))}
    </div>
  );
};

const Selection: React.FC<{ selected: boolean; onClick: () => void }> = ({ selected, onClick }): ReactElement => (
  <span
    className={classNames('selection-input', selected && 'is-selected')}
    onClick={(e): void => {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    }}
  >
    {selected && <i className="fas fa-check" />}
  </span>
);

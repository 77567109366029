import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export const NotFoundPage: React.FC = (): ReactElement => {
  const [t] = useTranslation('');

  return (
    <div>
      <h1>{t('Errors.NotFoundPage.title')}</h1>
      <p>{t('Errors.NotFoundPage.description')}</p>
    </div>
  );
};

import { Api } from 'modules/api/api';
import { QueryConfiguration } from 'modules/api/locHub/entity/query/configuration/configuration';
import { useLocHubDtoQuery } from 'modules/api/locHub/entity/query/detail/detail';
import { QueryResult, useLocHubQuery } from 'modules/api/locHub/entity/query/query';
import { Projects } from 'modules/api/locHub/projects/projects';

import { JobId } from '../../job/id/id';
import { JobDto } from '../../job/job';
import { JobDtoWithProject, Jobs } from '../../jobs';

export const useJobDto = (
  id: JobId | undefined,
  configuration: QueryConfiguration<JobDto | undefined, unknown> = { error: {}, query: {} },
): QueryResult<JobDto> => {
  return useLocHubDtoQuery(Api.locHub.jobs, id, configuration);
};

export const useJobDtoWithProject = (
  id: JobId | undefined,
  configuration: QueryConfiguration<JobDtoWithProject | undefined, unknown> = { error: {}, query: {} },
): QueryResult<JobDtoWithProject> => {
  configuration.query.enabled = id;
  return useLocHubQuery<JobDtoWithProject>(
    [Jobs.path, id, { with: Projects.path }],
    async () => {
      if (!id) {
        throw new Error(`Unexpected missing id`);
      }
      return Jobs.getDtoWithProject(id);
    },
    configuration,
  );
};

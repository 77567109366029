import { useApiError } from 'common/hooks/useApiError';
import { ValidationError } from 'common/interfaces/common';
import { useLanguageDto } from 'modules/api/locHub/languages/query/detail/detail';
import { Field } from 'modules/form/field/field';
import { LoadingPage } from 'pages/Common/LoadingPage/LoadingPage';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { getChanges } from '../../../common/utils/getChanges';
import { AppLayout } from '../../../components/AppLayout/AppLayout';
import { Box } from '../../../components/Box/Box';
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs';
import { Form } from '../../../components/Form/Form';
import { Code, Description, Name } from '../../../components/Input';
import { Api } from '../../../modules/api/api';
import { HttpError } from '../../../modules/api/error/error';
import { HomePageCrumb } from '../../Overview/HomePage/HomePage';
import { AdminPageCrumb } from '../AdminPage/AdminPage';
import { LanguageListPageCrumb } from '../LanguageListPage/LanguageListPage';

export const LanguageEditPageCrumb: Breadcrumb = {
  page: 'Administration.LanguageEditPage',
  icon: 'edit',
  location: '/admin/languages/:languageId',
};

export const LanguageEditPage: React.FC = (): ReactElement => {
  const apiError = useApiError();
  const [t] = useTranslation();
  const languageId = useParams<{ languageId: string }>().languageId;
  const language = useLanguageDto(languageId, {
    error: {
      handler: (error: Error): void => {
        history.push('/admin/languages');
        apiError.locHub.handle(
          error,
          t('Administration.LanguageEditPage.errorNotFound', {
            languageId: languageId,
          }),
        );
      },
    },
    query: {},
  });

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [code, setCode] = useState('');
  const [active, setActive] = useState<boolean>(false);

  const changes = language.success
    ? getChanges(
        {
          name,
          description,
          code,
        },
        language.data,
      )
    : {};

  useEffect((): void => {
    if (language.success) {
      setName(language.data.name);
      setDescription(language.data.description || '');
      setCode(language.data.code);
      setActive(language.data.active);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language.success]);

  const [formError, setFormError] = useState<ValidationError>();
  const history = useHistory();

  if (!language) {
    return <LoadingPage />;
  }

  return (
    <AppLayout breadcrumbs={[HomePageCrumb, AdminPageCrumb, LanguageListPageCrumb, LanguageEditPageCrumb]}>
      <Box i18nKey={LanguageEditPageCrumb.page}>
        <Form
          i18nKey="Administration.LanguageEditPage"
          cancelTo="/admin/languages"
          disabled={(!active && Object.keys(changes).length === 0) || Field.isEmpty(name) || Field.isEmpty(code)}
          error={formError}
          onSubmit={async (): Promise<void> => {
            try {
              setFormError(undefined);
              await Api.locHub.languages.update(languageId, changes);
              history.push('/admin/languages');
            } catch (error) {
              if (error instanceof Error && error.message === 'A language with this code already exists.') {
                setFormError({
                  errors: [{ field: 'code', codes: ['Unique.languageCode'] }],
                });
              } else {
                if (error instanceof HttpError && error.body) {
                  setFormError(await error.body);
                }
              }
            }
          }}
        >
          <Name
            i18nKey="Administration.LanguageEditPage.nameField"
            value={name}
            onChange={setName}
            errors={formError}
            required
            disabled={active}
          />
          <Description
            i18nKey="Administration.LanguageEditPage.descriptionField"
            value={description}
            onChange={setDescription}
            errors={formError}
            disabled={active}
          />
          <Code
            i18nKey="Administration.LanguageEditPage.codeField"
            value={code}
            onChange={setCode}
            errors={formError}
            required
            disabled={active}
          />
        </Form>
      </Box>
    </AppLayout>
  );
};

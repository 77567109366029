import { FileSize } from 'common/enums/file/size/size';
import { Page, Pagination } from 'common/interfaces/common';
import { asyncFileReader } from 'components/FileDrop/utilities';
import { Api } from 'modules/api/api';
import { queryCache } from 'react-query';

import { AssetDto } from '../assets/asset/asset';
import { QueryBuilder } from '../entity/query/builder/builder';
import { EntityRelation } from '../entity/relation/relation';
import { JobDto } from '../jobs/job/job';
import { LocHub } from '../lochub';
import { TaskId } from '../tasks/task/id/id';
import { TapiccStatus, TaskStatus } from '../tasks/task/status/status';
import { Task } from '../tasks/task/task';
import { InputId } from './input/id/id';
import { InputDto, NewInput } from './input/input';

export class Inputs {
  public static readonly path: string = '/api/internal/inputs';
  private static readonly uploadLimit: number = 100 * FileSize.MEGABYTES;
  private static getBaseUrl(parameters: Record<string, string | number | undefined> = {}): URL {
    return Api.getUrl(Inputs.path, parameters);
  }

  private static getDetailUrl(id: InputId): URL {
    return Api.getUrl(`${Inputs.path}/${id}`);
  }

  private static async translate(file: File, input: InputDto, job: JobDto, task: Task): Promise<void> {
    if (file.size > Inputs.uploadLimit) {
      throw new Error(`File ${file.name} is larger than allowed upload limit (${Inputs.uploadLimit})`);
    }
    const buffer = await asyncFileReader(file);
    if (buffer === null) {
      throw new Error(`Unable to read file ${file.name}`);
    }
    const originalAsset: AssetDto = await Api.locHub.assets.getDto(input.assetId);
    const newAsset = await Api.locHub.assets.create({
      name: originalAsset.name,
      description: originalAsset.description,
      originalFileName: originalAsset.name,
      projectId: job.projectId,
      language: task.targetLanguage,
      mimeType: file.type ? file.type : originalAsset.mimeType,
    });
    await Api.locHub.assets.uploadContent(newAsset.id, buffer);
    await Api.locHub.deliverables.create({
      assetId: newAsset.id,
      deliverableAs: input.inputAs,
      deliverableForId: input.id,
      taskId: task.id,
    });
  }

  public static async getMany(page: number, options?: Pagination): Promise<Page<InputDto>> {
    return LocHub.getJson(Inputs.getBaseUrl({ page, ...options }));
  }

  public static async getAll(): Promise<InputDto[]> {
    return LocHub.getAll(Inputs.path);
  }

  public static async getManyByTask(taskId: TaskId, page: number, options?: Pagination): Promise<Page<InputDto>> {
    return LocHub.getJson(
      Inputs.getBaseUrl({
        page,
        ...options,
        query: new QueryBuilder().addRelation(EntityRelation.TASK, taskId).build(),
      }),
    );
  }

  public static async getAllByTask(taskId: TaskId): Promise<InputDto[]> {
    return LocHub.getAll(Inputs.path, { query: new QueryBuilder().addRelation(EntityRelation.TASK, taskId).build() });
  }

  public static async getDto(inputId: InputId): Promise<InputDto> {
    return LocHub.getJson(Inputs.getDetailUrl(inputId));
  }

  public static async create(input: NewInput): Promise<InputDto> {
    const result = await LocHub.postJson<NewInput, InputDto>(Inputs.getBaseUrl(), input);
    queryCache.invalidateQueries(Inputs.path);
    return result;
  }

  public static async update(inputId: InputId, input: Partial<InputDto>): Promise<InputDto> {
    const result = await LocHub.patchJson(Inputs.getDetailUrl(inputId), input);
    queryCache.invalidateQueries(Inputs.path);
    return result;
  }

  public static async delete(inputId: InputId): Promise<void> {
    await LocHub.delete(Inputs.getDetailUrl(inputId));
  }

  public static async download(input: InputDto): Promise<void> {
    return Api.locHub.assets.download(input.assetId);
  }

  public static async translateMany(files: File[], input: InputDto): Promise<TaskStatus> {
    const job: JobDto = await Api.locHub.jobs.getDto(input.jobId);
    const task: Task = await Api.locHub.tasks.get(input.taskId);
    await Promise.all(
      files.map(async file => {
        await Inputs.translate(file, input, job, task);
      }),
    );
    const isFullyDelivered = await Task.hasAllSourceInputsDelivered(task.id);
    if (isFullyDelivered) {
      await Api.locHub.tasks.setStatus(task.id, TapiccStatus.COMPLETED);
      return TapiccStatus.COMPLETED;
    }
    return task.status;
  }
}

import { useMessage } from 'components/Message/MessageProvider';
import { LocHubHttpError } from 'modules/api/locHub/error/error';
import { useTranslation } from 'react-i18next';

interface ErrorHandlers {
  handle: (error: unknown, errorText?: string) => void;
  handleMany: (error: unknown, mapping: Record<string, string>) => void;
}

export interface ApiErrorHandlers {
  locHub: ErrorHandlers;
}

enum ErrorText {
  UNKNOWN = 'errors.unknown',
  API = 'errors.Api',
}

export const useApiError = (): ApiErrorHandlers => {
  const message = useMessage();
  const [t] = useTranslation();

  const handleLocHubError = (error: unknown, errorText: string = t(ErrorText.API)): void => {
    if (error instanceof LocHubHttpError) {
      message.error(errorText, { id: error.id });
    } else {
      if (error instanceof Error) {
        message.error(errorText);
      } else {
        message.error(t(ErrorText.UNKNOWN));
      }
    }
  };

  const handleLocHubErrors = (error: unknown, mapping: Record<string, string>): void => {
    if (error instanceof LocHubHttpError && mapping[error.message]) {
      message.error(mapping[error.message], { id: error.id });
    } else {
      if (error instanceof Error && mapping[error.message]) {
        message.error(mapping[error.message]);
      } else {
        message.error(t(ErrorText.UNKNOWN));
      }
    }
  };

  return {
    locHub: {
      handle: handleLocHubError,
      handleMany: handleLocHubErrors,
    },
  };
};

import React, { ReactElement, useState } from 'react';

import { classNames } from '../../common/utils/classNames';
import { HtmlProps } from '../../common/utils/HTMLProps';

interface TooltipProps extends HtmlProps {
  message: string;
  position?: 'left' | 'right';
}

export const Tooltip: React.FC<TooltipProps> = ({ id, className, message, position, children }): ReactElement => {
  const [show, setShow] = useState(false);

  return (
    <div
      className={classNames(
        'tooltip',
        position === undefined ? '' : 'tooltip-' + position,
        className,
        show && 'is-visible',
      )}
    >
      <span className="message">{message}</span>
      <span id={id} onMouseOver={(): void => setShow(true)} onMouseLeave={(): void => setShow(false)}>
        {children}
      </span>
    </div>
  );
};

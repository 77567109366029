import { Api } from 'modules/api/api';
import { InputDto } from 'modules/api/locHub/inputs/input/input';
import { TaskStatus } from 'modules/api/locHub/tasks/task/status/status';

import { openFileBrowser } from '../../../components/FileDrop/utilities';

export async function uploadTranslation(input: InputDto): Promise<TaskStatus> {
  const files = await openFileBrowser();
  return Api.locHub.inputs.translateMany(files, input);
}

export function uploadTranslationDrop(data: DataTransfer, input: InputDto): Promise<TaskStatus> {
  const files = Array.from(data.files);
  return Api.locHub.inputs.translateMany(files, input);
}

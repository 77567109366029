import { useApiError } from 'common/hooks/useApiError';
import { AppLayout } from 'components/AppLayout/AppLayout';
import { Api } from 'modules/api/api';
import { HttpError } from 'modules/api/error/error';
import { AssetDto } from 'modules/api/locHub/assets/asset/asset';
import { JobDto } from 'modules/api/locHub/jobs/job/job';
import { ProjectDto } from 'modules/api/locHub/projects/project/project';
import { Field } from 'modules/form/field/field';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { useAsyncState } from '../../../common/hooks/useAsyncState';
import { Page } from '../../../common/interfaces/common';
import { getChanges } from '../../../common/utils/getChanges';
import { Box } from '../../../components/Box/Box';
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs';
import { ConfirmButton } from '../../../components/ConfirmButton/ConfirmButton';
import { Form } from '../../../components/Form/Form';
import { Description, Name } from '../../../components/Input';
import { LanguageSettings } from '../../../components/LanguageSettings/LanguageSettings';
import { HomePageCrumb } from '../HomePage/HomePage';
import { ProjectListPageCrumb } from '../ProjectListPage/ProjectListPage';

export const ProjectEditPageCrumb: Breadcrumb = {
  page: 'Overview.ProjectEditPage',
  icon: 'edit',
  location: '/projects/:projectId',
};

type SimpleProject = Omit<ProjectDto, 'defaultSourceLanguage' | 'defaultTargetLanguages'> & {
  defaultSourceLanguage?: string;
  defaultTargetLanguages: string[];
};

export function transformProject(project: ProjectDto): SimpleProject {
  return {
    ...project,
    defaultSourceLanguage: project.defaultSourceLanguage,
    defaultTargetLanguages: project.defaultTargetLanguages || [],
  };
}

export const ProjectEditPage: React.FC = (): ReactElement => {
  const apiError = useApiError();
  const projectId = useParams<{ projectId: string }>().projectId;
  const [t] = useTranslation();
  const [project] = useAsyncState(async () => {
    try {
      return transformProject(await Api.locHub.projects.getDto(projectId));
    } catch (error) {
      apiError.locHub.handle(
        error,
        t('Overview.ProjectEditPage.errorNotFound', {
          projectId: projectId,
        }),
      );
      history.push('/projects');
    }
  }, [projectId]);

  const [archived, setArchived] = useState<boolean>(false);
  const [jobs] = useAsyncState(async () => {
    try {
      return await Api.locHub.jobs.getManyByProject(projectId, 1);
    } catch (error) {
      apiError.locHub.handle(error);
    }
  });
  const [assets] = useAsyncState(async () => {
    try {
      return await Api.locHub.assets.getManyByProject(projectId, 1);
    } catch (error) {
      apiError.locHub.handle(error);
    }
  });
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [defaultSourceLanguage, setSourceLanguage] = useState<string>();
  const [defaultTargetLanguages, setTargetLanguages] = useState<string[]>([]);
  const changes = project
    ? getChanges(
        {
          name,
          description,
          defaultSourceLanguage,
          defaultTargetLanguages,
        },
        project,
      )
    : {};

  useEffect((): void => {
    if (project) {
      setArchived(project.archived);
      setDescription(project.description || '');
      setName(project.name);
      setSourceLanguage(project.defaultSourceLanguage);
      setTargetLanguages(project.defaultTargetLanguages);
    }
  }, [project]);

  const [error, setError] = useState();
  const history = useHistory();

  function projectHasJob(projectJobs: Page<JobDto> | undefined): boolean {
    if (projectJobs) {
      return projectJobs.totalElements > 0;
    } else {
      return false;
    }
  }

  function projectHasAsset(projectAssets: Page<AssetDto> | undefined): boolean {
    if (projectAssets) {
      return projectAssets.totalElements > 0;
    } else {
      return false;
    }
  }

  function checkProject(
    projectJobs: Page<JobDto> | undefined,
    projectAssets: Page<AssetDto> | undefined,
  ): React.ReactElement {
    if (projectHasJob(projectJobs) && projectHasAsset(projectAssets)) {
      return <h1>{t('Overview.ProjectEditPage.deleteWarningJobsAndAssets')}</h1>;
    } else if (projectHasJob(projectJobs)) {
      return <h1>{t('Overview.ProjectEditPage.deleteWarningJobs')}</h1>;
    } else if (projectHasAsset(projectAssets)) {
      return <h1>{t('Overview.ProjectEditPage.deleteWarningAssets')}</h1>;
    } else {
      return <></>;
    }
  }

  return (
    <AppLayout breadcrumbs={[HomePageCrumb, ProjectListPageCrumb, ProjectEditPageCrumb]}>
      <Box i18nKey={ProjectEditPageCrumb.page}>
        <Form
          i18nKey="Overview.ProjectEditPage"
          cancelTo="/projects"
          disabled={
            archived ||
            Object.keys(changes).length === 0 ||
            Field.isEmpty(name) ||
            Field.isEmpty(defaultSourceLanguage) ||
            !defaultTargetLanguages.length
          }
          error={error}
          onSubmit={async (): Promise<void> => {
            try {
              setError(undefined);
              await Api.locHub.projects.update(projectId, changes);
              history.push('/projects');
            } catch (e) {
              if (e instanceof HttpError && e.body) {
                setError(await e.body);
              }
            }
          }}
        >
          <Name
            i18nKey="Overview.ProjectEditPage.nameField"
            value={name}
            onChange={setName}
            errors={error}
            disabled={archived}
            required
          />
          <Description
            i18nKey="Overview.ProjectEditPage.descriptionField"
            value={description}
            errors={error}
            onChange={setDescription}
            disabled={archived}
          />
          <div className="column is-full">
            <LanguageSettings
              i18nKey="Overview.ProjectEditPage.languageSettings"
              source={defaultSourceLanguage}
              onSourceChange={setSourceLanguage}
              targets={defaultTargetLanguages}
              onTargetsChange={setTargetLanguages}
            />
          </div>
        </Form>
      </Box>
      <Box i18nKey="Overview.ProjectEditPageDangerZone">
        {!archived ? (
          <ConfirmButton
            i18nKey="Overview.ProjectEditPageDangerZone.archiveButton"
            className="is-danger"
            onConfirm={async (): Promise<void> => {
              await Api.locHub.projects.archive(projectId);
              setArchived(true);
            }}
          />
        ) : (
          <ConfirmButton
            i18nKey="Overview.ProjectEditPageDangerZone.deleteButton"
            className="is-danger"
            disabled={projectHasJob(jobs) || projectHasAsset(assets)}
            onConfirm={async (): Promise<void> => {
              await Api.locHub.projects.delete(projectId);
              history.push('/projects');
            }}
          />
        )}
        {checkProject(jobs, assets)}
      </Box>
    </AppLayout>
  );
};

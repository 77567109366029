import { XillioProvider } from 'common/interfaces/xillio';

export enum XillioProviderType {
  LOCHUB_QA_INSIGHTS = 'Lochub-QA-Insights',
}

export class XillioAnalyticsProviders {
  private static analytics = new Map<XillioProviderType, XillioProvider>([
    [
      XillioProviderType.LOCHUB_QA_INSIGHTS,
      {
        configurationType: XillioProviderType.LOCHUB_QA_INSIGHTS,
      },
    ],
  ]);

  public static getAll(): XillioProvider[] {
    return Array.from(XillioAnalyticsProviders.analytics.values());
  }
}

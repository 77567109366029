import React, { ReactElement } from 'react';

interface Props {
  id: string;
  name: string;
  value: boolean;
  actions: {
    toggle: (value: boolean) => void;
  };
  texts: {
    label: string;
  };
}

export const Toggle: React.FC<Props> = ({ id, name, value, actions, texts }): ReactElement => {
  /* Actions */
  const toggleAction = (): void => {
    actions.toggle(!value);
  };
  return (
    <div className="field">
      <input
        id={id}
        type="checkbox"
        name={name}
        className="switch is-rounded"
        checked={value}
        onChange={toggleAction}
      />
      <label htmlFor={id}>{texts.label}</label>
    </div>
  );
};

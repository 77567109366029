import { dictionary } from 'common/language/dictionary';
import { I18nNamespace } from 'common/language/namespace';

export const TargetLanguagesSelectI18n = {
  configuration: { namespace: I18nNamespace.TARGET_LANGUAGES_SELECT, labelKey: 'label' },
  translations: {
    label: dictionary.targetLanguages,
    placeholder: dictionary.selectTargetLanguage,
    noOptions: dictionary.noOptions,
    filterPlaceholder: dictionary.filter,
    selectAll: dictionary.selectAll,
    deselectAll: dictionary.deselectAll,
    save: dictionary.save,
  },
};

import { SwitchInput } from 'components/HookForm/Input/Switch/Switch';
import { EntityName } from 'modules/api/locHub/entity/name/name';
import React, { ReactElement } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AutomationMarkForTranslationInputI18n } from './I18n';

interface Props {
  control: UseFormMethods['control'];
  value?: boolean;
}

export const AutomationMarkForTranslation: React.FC<Props> = ({ value, control }): ReactElement => {
  const [t] = useTranslation();
  const namespace = AutomationMarkForTranslationInputI18n.configuration.namespace;
  const label = t(`${namespace}.label`);
  return (
    <SwitchInput
      id={`${EntityName.AUTOMATION}-mark-for-translation`}
      name={`${EntityName.AUTOMATION}MarkForTranslation`}
      label={label}
      control={control}
    />
  );
};

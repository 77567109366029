import { usePaginatedLanguages } from 'modules/api/locHub/languages/query/paginatedList/paginatedList';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppLayout } from '../../../components/AppLayout/AppLayout';
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs';
import { Button } from '../../../components/Button/Button';
import { CreateButton } from '../../../components/Button/Buttons';
import { DataTable } from '../../../components/DataTable/DataTable';
import { LanguageTag } from '../../../components/LanguageTag/LanguageTag';
import { AdminTabs } from '../../../components/Tabs/Tabs';
import { TD } from '../../../components/TD/TD';
import { HomePageCrumb } from '../../Overview/HomePage/HomePage';
import { AdminPageCrumb } from '../AdminPage/AdminPage';

export const LanguageListPageCrumb: Breadcrumb = {
  page: 'Administration.LanguageListPage',
  icon: 'language',
  location: '/admin/languages',
};

export const LanguageListPage: React.FC = (): ReactElement => {
  const [t] = useTranslation();
  const [page, setPage] = useState<number | undefined>(undefined);
  const languages = usePaginatedLanguages(page);
  return (
    <AppLayout breadcrumbs={[HomePageCrumb, AdminPageCrumb, LanguageListPageCrumb]}>
      <AdminTabs>
        <CreateButton
          id={'create-language-button'}
          i18nKey="Administration.LanguageListPage.createButton"
          to="/admin/languages/new"
        />
        <DataTable
          i18nKey={LanguageListPageCrumb.page}
          data={languages.success ? languages.data : undefined}
          setPage={setPage}
          columns={['name', 'code', 'createdAt', 'updatedAt', 'description', '']}
          render={({ id, name, code, createdAt, updatedAt, description }): ReactElement => (
            <tr key={id}>
              <TD wrap>{name}</TD>
              <TD>
                <LanguageTag language={code} />
              </TD>
              <TD>{t('locale.date', { date: new Date(createdAt) })}</TD>
              <TD>{t('locale.fromNow', { date: new Date(updatedAt) })}</TD>
              <TD>{description}</TD>
              <TD>
                <div className="buttons is-small is-pulled-right has-addons">
                  <Button to={`/admin/languages/${id}`} i18nKey="Administration.LanguageListPage.editButton">
                    <span className="icon is-small">
                      <i className="fas fa-edit" />
                    </span>
                  </Button>
                </div>
              </TD>
            </tr>
          )}
        />
      </AdminTabs>
    </AppLayout>
  );
};

import { dictionary } from 'common/language/dictionary';
import { I18nNamespace } from 'common/language/namespace';

export const AutomationNameInputI18n = {
  configuration: { namespace: I18nNamespace.PROJECT_NAME_INPUT, labelKey: 'label' },
  translations: {
    label: dictionary.automationName,
    placeholder: dictionary.automationName,
    validationMaxLengthError: dictionary.validationMaxLengthError,
    validationRequiredError: dictionary.validationRequiredError,
  },
};

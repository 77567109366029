/**
 * The root level of localization. This 'app' localization contains a collection of application modules which are localized.
 */

export interface AppLocalization {
  [module: string]: ModuleLocalization;
}

/**
 * The module level of localization. This resource contains a collection of localized pages.
 */
export interface ModuleLocalization {
  [page: string]: PageLocalization;
}

/**
 * A localized page contains a title, description and a collection of localized components.
 */
export interface PageLocalization {
  title: ResourceLocalization;
  description: ResourceLocalization;

  [component: string]: ComponentLocalization;
}

/**
 * A localized component can mean several things. Use this type alias to address them all.
 */
export type ComponentLocalization =
  | ResourceLocalization
  | NestedResourceLocalization
  | TextFieldLocalization
  | SelectFieldLocalization
  | ActionButtonLocalization
  | ButtonLocalization
  | ConfirmButtonLocalization
  | LanguageSettingsLocalization
  | DescriptiveLocalization;

export interface NestedResourceLocalization {
  [component: string]: ComponentLocalization;
}

/**
 * This represents the localization of a form text field.
 */
export interface TextFieldLocalization {
  label: ResourceLocalization;
  placeholder?: ResourceLocalization;
}

/**
 * This represents the localization of a dropdown select field.
 */
export interface SelectFieldLocalization {
  label: ResourceLocalization;
  options: { [key: string]: ResourceLocalization };
}

/**
 * This represents the localization of a button or a link which is displayed as a button.
 */
export interface ButtonLocalization {
  text: ResourceLocalization;
}

export interface ConfirmButtonLocalization extends ButtonLocalization {
  confirm: ResourceLocalization;
}

/**
 * Action buttons may also be canceled.
 */
export interface ActionButtonLocalization extends ButtonLocalization {
  cancel: ResourceLocalization;
}

export interface HeaderLocalization {
  heading: ResourceLocalization;
}

export interface LanguageSettingsLocalization {
  sourceLanguageField: TextFieldLocalization & {
    prompt: ResourceLocalization;
  };
  targetLanguagesField: TextFieldLocalization & {
    selectAll: ResourceLocalization;
    deselectAll: ResourceLocalization;
    save: ResourceLocalization;
    filter: ResourceLocalization;
    noOptions: ResourceLocalization;
  };
}

export interface DescriptiveLocalization {
  title: ResourceLocalization;
  description: ResourceLocalization;

  [component: string]: ResourceLocalization;
}

/**
 * The lowest possible localization level: The raw strings.
 */
export interface ResourceLocalization {
  nl: string;
  en: string;
}

export interface ResourceTree {
  [key: string]: ResourceTree | ResourceLocalization;
}

export function isResourceLocalization(resource: object): resource is ResourceLocalization {
  return 'en' in resource && 'nl' in resource && Object.keys(resource).length === 2;
}

import { dictionary } from 'common/language/dictionary';
import { I18nNamespace } from 'common/language/namespace';

export const JobEditPageI18n = {
  configuration: { namespace: I18nNamespace.JOB_EDIT_PAGE, labelKey: 'label' },
  translations: {
    title: dictionary.editJob,
    description: dictionary.editJobDescription,
    label: dictionary.editJob,
    loadingError: dictionary.dataLoadingError,
    jobUpdateSuccess: dictionary.jobUpdateSuccess,
    jobUpdateFailure: dictionary.jobUpdateFailure,
    archivedJobNotEditable: dictionary.archivedJobNotEditable,
    jobNotFound: dictionary.jobNotFound,
    archiveButton: {
      text: dictionary.archiveJob,
      confirm: dictionary.confirm,
      success: dictionary.archiveJobSuccess,
      failure: dictionary.archiveJobFailure,
    },
    deleteButton: {
      text: dictionary.deleteJob,
      confirm: dictionary.confirm,
      success: dictionary.deleteJobSuccess,
      failure: dictionary.deleteJobFailure,
    },
    dangerZone: {
      title: dictionary.dangerZone,
      description: dictionary.dangerZoneWarning,
    },
  },
};

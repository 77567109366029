import { toCamelCase } from 'common/utils/string/string';
import { FormField } from 'components/HookForm/Field/Field';
import { EntityName } from 'modules/api/locHub/entity/name/name';
import React, { ReactElement } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { FieldError } from 'react-hook-form/dist/types';

interface Props {
  entity: EntityName;
  field: string;
  required: boolean;
  value?: number;
  min: number;
  max: number;
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
  texts: {
    label: string;
    placeholder: string;
    validationMinError: string;
    validationMaxError: string;
    validationRequiredError: string;
  };
}

const isLetter = (key: string): boolean => {
  const keyCode = key.charCodeAt(0);
  return keyCode > 64 && keyCode < 91;
};
const isSpecialCharacter = (key: string): boolean => {
  const keyCode = key.charCodeAt(0);
  return (keyCode > 185 && keyCode < 193) || (keyCode > 218 && keyCode < 223);
};

export const DurationInput: React.FC<Props> = ({
  entity,
  field,
  required,
  value,
  min,
  max,
  register,
  errors,
  texts,
}): ReactElement => {
  const icon = 'fa-hourglass';
  const name = toCamelCase(`${entity} ${field}`);
  const id = `${name}-input`;
  /* Methods */
  const getErrorMessage = (filedError: FieldError): string => {
    if (filedError.type === 'min') {
      return texts.validationMinError;
    }
    if (filedError.type === 'max') {
      return texts.validationMaxError;
    }
    if (filedError.type === 'required') {
      return texts.validationRequiredError;
    }
    throw new Error(`Unexpected validation error of type ${filedError.type}`);
  };
  const error = errors[name] && getErrorMessage(errors[name]);
  return (
    <FormField label={texts.label} name={name} error={error} leftIcon={icon} required={required}>
      <input
        className="input"
        type="number"
        placeholder={texts.placeholder}
        id={id}
        name={`${name}`}
        ref={register({ required, min, max })}
        min={min}
        max={max}
        value={value}
        required={required}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>): void => {
          if (isLetter(event.key) || isSpecialCharacter(event.key)) {
            event.preventDefault();
          }
        }}
      />
    </FormField>
  );
};

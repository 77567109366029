import { MutableRefObject, useEffect } from 'react';

export function useMouseEvent<T>(fn: (e: MouseEvent) => void): void {
  useEffect(() => {
    document.addEventListener('mousedown', fn);
    return (): void => {
      document.removeEventListener('mousedown', fn);
    };
  });
}

export function useOutsideClickEvent<T>(fn: (e: MouseEvent) => void, reference: MutableRefObject<T>): void {
  useMouseEvent(e => {
    // We need to disable this error as it is a known conflict with typescript
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    if (reference.current && !reference.current.contains(e.target)) {
      fn(e);
    }
  });
}

import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Translatable } from '../../common/language';
import { classNames } from '../../common/utils/classNames';
import { HtmlProps } from '../../common/utils/HTMLProps';

export interface Props extends HtmlProps, Translatable {
  action?: ReactElement;
}

export const Box: React.FC<Props> = ({ i18nKey, className, id, children, action }): ReactElement => {
  const [t] = useTranslation();

  return (
    <div className={classNames('box', className)} id={id}>
      {action && <div className="action">{action}</div>}
      <div className="titles-container">
        <h2 className="title">{t(`${i18nKey}.title`)}</h2>
        <p className="subtitle">{t(`${i18nKey}.description`)}</p>
      </div>
      <hr />
      <div className="is-clearfix">{children}</div>
    </div>
  );
};

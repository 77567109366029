import React, { ReactElement } from 'react';

import { CmsConfiguration, XillioConnector } from '../../common/interfaces/xillio';

export const ConnectorIcon: React.FC<{ configuration: CmsConfiguration | XillioConnector }> = ({
  configuration,
}): ReactElement => {
  const type = configuration.configurationType.toLowerCase();

  const connectors = [
    'aem',
    'bitbucket',
    'box',
    'contentful',
    'dropbox',
    'drupal',
    'egnyte',
    'filenet',
    'github',
    'gitlab',
    'googledrive',
    'jsonrpc',
    'onedrive',
    'opentext',
    'salesforce',
    'sharepoint',
    'sharepointv2',
    'sitecore',
    'wordpress',
    'zendesk',
  ];
  if (connectors.includes(type)) {
    return (
      <i className={'connector-' + type}>
        <img src={`${process.env.PUBLIC_URL}/connectors/${type}.png`} alt={type} />
      </i>
    );
  }
  return <i className="connector-unknown" />;
};

import { AppLayout } from 'components/AppLayout/AppLayout';
import { Api } from 'modules/api/api';
import { Field } from 'modules/form/field/field';
import React, { ReactElement, useState } from 'react';
import { useHistory } from 'react-router';

import { Box } from '../../../components/Box/Box';
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs';
import { Form } from '../../../components/Form/Form';
import { Description, Name } from '../../../components/Input';
import { LanguageSettings } from '../../../components/LanguageSettings/LanguageSettings';
import { HomePageCrumb } from '../HomePage/HomePage';
import { ProjectListPageCrumb } from '../ProjectListPage/ProjectListPage';

export const ProjectCreatePageCrumb: Breadcrumb = {
  page: 'Overview.ProjectCreatePage',
  icon: 'plus',
  location: '/projects/new',
};

export const ProjectCreatePage: React.FC = (): ReactElement => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [sourceLanguage, setSourceLanguage] = useState<string>();
  const [targetLanguages, setTargetLanguages] = useState<string[]>([]);
  const [error, setError] = useState();
  const history = useHistory();

  return (
    <AppLayout breadcrumbs={[HomePageCrumb, ProjectListPageCrumb, ProjectCreatePageCrumb]}>
      <Box i18nKey={ProjectCreatePageCrumb.page}>
        <Form
          i18nKey="Overview.ProjectCreatePage"
          cancelTo="/projects"
          disabled={Field.isEmpty(name) || Field.isEmpty(sourceLanguage) || !targetLanguages.length}
          error={error}
          onSubmit={async (): Promise<void> => {
            try {
              setError(undefined);
              await Api.locHub.projects.create({
                name,
                description,
                defaultSourceLanguage: sourceLanguage,
                defaultTargetLanguages: targetLanguages,
              });
              history.push('/projects');
            } catch (e) {
              setError(e);
            }
          }}
        >
          <Name
            i18nKey="Overview.ProjectCreatePage.nameField"
            value={name}
            onChange={setName}
            errors={error}
            required
          />
          <Description
            i18nKey="Overview.ProjectCreatePage.descriptionField"
            value={description}
            onChange={setDescription}
            errors={error}
          />
          <div className="column is-full">
            <LanguageSettings
              i18nKey="Overview.ProjectCreatePage.languageSettings"
              source={sourceLanguage}
              onSourceChange={setSourceLanguage}
              targets={targetLanguages}
              onTargetsChange={setTargetLanguages}
            />
          </div>
        </Form>
      </Box>
    </AppLayout>
  );
};

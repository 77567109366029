import { HttpError } from 'modules/api/error/error';

export interface LanguageCodeConflictBody {
  timestamp: string;
  status: 409;
  error: 'Conflict';
  message: string;
  path: '/api/internal/languages';
  errorId: string;
}

export class LanguageCodeConflict extends HttpError<LanguageCodeConflictBody> {
  public constructor(response: Response, body: LanguageCodeConflictBody) {
    super(response, body);
    Object.setPrototypeOf(this, LanguageCodeConflict.prototype);
    this.message = body.message;
  }
}

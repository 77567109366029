import { ApiErrorHandlers, useApiError } from 'common/hooks/useApiError';
import {
  QueryConfiguration,
  defaultQueryConfiguration,
} from 'modules/api/locHub/entity/query/configuration/configuration';
import {
  AnyQueryKey,
  QueryResult as OriginalQueryResult,
  PaginatedQueryResult,
  usePaginatedQuery,
  useQuery,
} from 'react-query';

import { ApiHookErrorConfiguration } from './configuration/error/error';

export interface LoadingQueryResult {
  error: false;
  stale: boolean;
  fetching: boolean;
  loading: true;
  success: false;
}

export interface UnsuccessfulQueryResult {
  error: true;
  stale: boolean;
  loading: false;
  fetching: boolean;
  success: false;
}

export interface SuccessfulQueryResult<Data> {
  error: false;
  stale: boolean;
  loading: false;
  fetching: boolean;
  success: true;
  data: Data;
}

export type QueryResult<Data> = SuccessfulQueryResult<Data> | UnsuccessfulQueryResult | LoadingQueryResult;

class Query {
  private constructor() {
    /* no-op */
  }

  private static getError(
    result: OriginalQueryResult<unknown, unknown> | PaginatedQueryResult<unknown, unknown>,
  ): UnsuccessfulQueryResult {
    return {
      error: true,
      stale: result.isStale,
      loading: false,
      fetching: result.isFetching,
      success: false,
    };
  }

  private static getLoading(
    result: OriginalQueryResult<unknown, unknown> | PaginatedQueryResult<unknown, unknown>,
  ): LoadingQueryResult {
    return {
      error: false,
      stale: result.isStale,
      loading: true,
      fetching: result.isFetching,
      success: false,
    };
  }

  private static getData<Data>(result: OriginalQueryResult<Data | undefined>): SuccessfulQueryResult<Data> {
    if (!result.data) {
      throw new Error('Unexpected missing data');
    }
    return {
      error: false,
      stale: result.isStale,
      loading: false,
      fetching: result.isFetching,
      success: true,
      data: result.data,
    };
  }

  private static getPaginatedData<Data>(result: PaginatedQueryResult<Data | undefined>): SuccessfulQueryResult<Data> {
    if (!result.resolvedData) {
      throw new Error('Unexpected missing data');
    }
    return {
      error: false,
      stale: result.isStale,
      loading: false,
      fetching: result.isFetching,
      success: true,
      data: result.resolvedData,
    };
  }

  public static handleException(
    error: unknown,
    configuration: ApiHookErrorConfiguration,
    apiError: ApiErrorHandlers,
  ): void {
    if (configuration.handler && error instanceof Error) {
      configuration.handler(error);
    } else {
      apiError.locHub.handle(error, configuration.text);
    }
    throw new Error('Incorrect error received');
  }

  public static getResult<Data>(result: OriginalQueryResult<Data | undefined, unknown>): QueryResult<Data> {
    if (result.isLoading) {
      return Query.getLoading(result);
    }
    if (result.isSuccess) {
      return Query.getData(result);
    }
    return Query.getError(result);
  }

  public static getPaginatedResult<Data>(result: PaginatedQueryResult<Data | undefined, unknown>): QueryResult<Data> {
    if (result.isLoading) {
      return Query.getLoading(result);
    }
    if (result.isSuccess) {
      return Query.getPaginatedData(result);
    }
    return Query.getError(result);
  }
}

export const useLocHubQuery = <Data>(
  key: AnyQueryKey,
  query: () => Promise<Data>,
  configuration: QueryConfiguration<Data | undefined, unknown> = { error: {}, query: {} },
): QueryResult<Data> => {
  const apiError = useApiError();
  const result = useQuery<Data | undefined, AnyQueryKey, [unknown, unknown[]]>(
    key,
    async () => {
      try {
        return await query();
      } catch (error) {
        Query.handleException(error, configuration.error, apiError);
      }
    },
    { ...defaultQueryConfiguration, ...configuration.query },
  );
  return Query.getResult(result);
};

export const usePaginatedLocHubQuery = <Data>(
  key: AnyQueryKey,
  query: () => Promise<Data>,
  configuration: QueryConfiguration<Data | undefined, unknown> = { error: {}, query: {} },
): QueryResult<Data> => {
  const apiError = useApiError();
  const result = usePaginatedQuery<Data | undefined, AnyQueryKey, [unknown, unknown[]]>(
    key,
    async () => {
      try {
        return await query();
      } catch (error) {
        Query.handleException(error, configuration.error, apiError);
      }
    },
    { ...defaultQueryConfiguration, ...configuration.query },
  );
  return Query.getPaginatedResult(result);
};

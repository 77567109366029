import { CmsConfiguration } from 'common/interfaces/xillio';
import { SingleSelect, SingleSelectTexts } from 'components/Input/Select/SingleSelect/SingleSelect';
import React, { ReactElement } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { FieldError } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';

import { CmsSelectI18n } from './I18n';

interface Props {
  control: UseFormMethods['control'];
  errors: UseFormMethods['errors'];
  configurations: CmsConfiguration[];
  disabled?: boolean;
}

export const CmsSelect: React.FC<Props> = ({ control, errors, configurations, disabled }): ReactElement => {
  const [t] = useTranslation();
  const namespace = CmsSelectI18n.configuration.namespace;
  const name = 'cmsId';
  const icon = 'fa-box';
  /* Methods */
  const getErrorMessage = (filedError: FieldError): string => {
    throw new Error(`Unexpected validation error of type ${filedError.type}`);
  };
  /* Texts */
  const texts: SingleSelectTexts = {
    controls: {
      clear: t(`${namespace}.clear`),
      save: t(`${namespace}.save`),
    },
    options: { noOptions: t(`${namespace}.noOptions`) },
    filter: {
      placeholder: t(`${namespace}.filterPlaceholder`),
    },
    input: {
      label: t(`${namespace}.label`),
      placeholder: t(`${namespace}.placeholder`),
    },
  };
  const options = new Map();
  for (const cms of configurations) {
    options.set(cms.id, { id: cms.id, label: cms.name });
  }
  const error = errors[name] && getErrorMessage(errors[name]);
  return (
    <Controller
      name={name}
      control={control}
      render={({ onChange, value }): ReactElement => {
        return (
          <SingleSelect
            texts={texts}
            icon={icon}
            name={name}
            options={options}
            value={value}
            error={error}
            actions={{
              valuesUpdated: onChange,
            }}
            required={true}
            disabled={disabled}
          />
        );
      }}
    />
  );
};

import { HttpError } from 'modules/api/error/error';
import { queryCache } from 'react-query';

import { Page, Pagination } from '../../../../common/interfaces/common';
import { Api } from '../../api';
import { LanguageId } from '../languages/language/id/id';
import { LanguageDto, NewLanguage } from '../languages/language/language';
import { LocHub } from '../lochub';
import { LanguageCodeConflict } from './error/conflict/languageCode/languageCode';

export class Languages {
  public static readonly path: string = '/api/internal/languages';

  private static getBaseUrl(parameters: Record<string, string | number | undefined> = {}): URL {
    return Api.getUrl(Languages.path, parameters);
  }

  private static getDetailUrl(id: LanguageId): URL {
    return Api.getUrl(`${Languages.path}/${id}`);
  }

  public static async getDto(languageId: LanguageId): Promise<LanguageDto> {
    return LocHub.getJson(Languages.getDetailUrl(languageId));
  }

  public static async getMany(page: number, options?: Pagination): Promise<Page<LanguageDto>> {
    return LocHub.getJson(Languages.getBaseUrl({ page, ...options }));
  }

  public static async getAll(): Promise<LanguageDto[]> {
    return Api.locHub.getAll(Languages.path);
  }

  public static async create(language: NewLanguage): Promise<LanguageDto> {
    try {
      const result = await LocHub.postJson<NewLanguage, LanguageDto>(Languages.getBaseUrl(), language);
      queryCache.invalidateQueries(Languages.path);
      return result;
    } catch (error) {
      if (error instanceof HttpError && error.body.message === 'A language with the provided code already exists') {
        throw new LanguageCodeConflict(error.response, error.body);
      }
      throw error;
    }
  }

  public static async update(languageId: LanguageId, language: Partial<LanguageDto>): Promise<LanguageDto> {
    const result = LocHub.patchJson(Languages.getDetailUrl(languageId), language);
    queryCache.invalidateQueries(Languages.path);
    return result;
  }
}

import { useApiError } from 'common/hooks/useApiError';
import { BinaryIcon } from 'components/BinaryIcon/BinaryIcon';
import { AssetDto } from 'modules/api/locHub/assets/asset/asset';
import { InputDto } from 'modules/api/locHub/inputs/input/input';
import { Task } from 'modules/api/locHub/tasks/task/task';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Translatable } from '../../../common/language';
import { FileDrop } from '../../../components/FileDrop/FileDrop';
import { LanguageTag } from '../../../components/LanguageTag/LanguageTag';
import { useMessage } from '../../../components/Message/MessageProvider';
import { TD } from '../../../components/TD/TD';
import { DropdownActions } from './DropdownActions/DropdownActions';
import { uploadTranslationDrop } from './utillities';

export interface Props extends Translatable {
  task: Task;
  input: InputDto;
  asset: AssetDto;
}

export const InputDetailRow: React.FC<Props> = ({ i18nKey, task, input, asset }): ReactElement => {
  const apiError = useApiError();
  const [t] = useTranslation();
  const message = useMessage();
  const [delivered, setDelivered] = useState(Boolean(input.deliverableIds));

  /* State modifiers */
  const onDelivered = (): void => {
    setDelivered(true);
  };

  /* Actions */
  const onDrop = async (data: DataTransfer): Promise<void> => {
    try {
      const taskStatusAfterUpload = await uploadTranslationDrop(data, input);
      if (taskStatusAfterUpload !== task.status) {
        task.status = taskStatusAfterUpload;
      }
      onDelivered();
      message.success(t(`${i18nKey}.actions.uploadTranslation.success`, { asset: asset.fileOriginalName }));
    } catch (error) {
      apiError.locHub.handle(
        error,
        t(`${i18nKey}.actions.uploadTranslation.failure`, { asset: asset.fileOriginalName }),
      );
    }
  };

  return (
    <FileDrop onDrop={onDrop} disabled={!task.isConfirmed()}>
      <tr>
        <TD wrap>{asset.fileOriginalName}</TD>
        <TD>{input.inputAs}</TD>
        <TD>
          <LanguageTag language={task.sourceLanguage} />
        </TD>
        <TD>
          <LanguageTag language={task.targetLanguage} />
        </TD>
        <TD>
          <BinaryIcon value={delivered} />
        </TD>
        <TD>
          <div className="field has-addons is-pulled-right">
            <DropdownActions
              i18nKey={i18nKey}
              task={task}
              input={input}
              asset={asset}
              delivered={delivered}
              actions={{ delivered: onDelivered }}
            />
          </div>
        </TD>
      </tr>
    </FileDrop>
  );
};

import { dictionary } from 'common/language/dictionary';
import { I18nNamespace } from 'common/language/namespace';

export const AutomationPathInputI18n = {
  configuration: { namespace: I18nNamespace.AUTOMATION_PATHS_INPUT, labelKey: 'label' },
  translations: {
    label: dictionary.cmsPaths,
    placeholder: dictionary.selectCmsPaths,
    save: dictionary.save,
    required: dictionary.validationRequiredError,
  },
};

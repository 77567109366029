import { NavBar } from 'components/NavBar/NavBar';
import React, { ReactElement } from 'react';

import { classNames } from '../../common/utils/classNames';
import { BreadCrumbs, Breadcrumb } from '../Breadcrumbs/Breadcrumbs';

export interface Props {
  breadcrumbs?: Breadcrumb[];
  className?: string;
  showItems?: boolean;
}

export const AppLayout: React.FC<Props> = ({ children, breadcrumbs, className, showItems }): ReactElement => (
  <div className={classNames('app-layout', className)}>
    <NavBar showItems={showItems} />
    <div className="main container">
      {breadcrumbs && <BreadCrumbs breadcrumbs={breadcrumbs} />}
      {children}
    </div>
    <footer className="footer"></footer>
  </div>
);

export enum EntityRelation {
  ASSET = 'assetId',
  AUTOMATION = 'automationId',
  DELIVERABLE = 'deliverableId',
  INPUT = 'inputId',
  JOB = 'jobId',
  LANGUAGE = 'languageId',
  PROJECT = 'projectId',
  TASK = 'taskId',
  USER = 'userId',
}

import { AutomationListPageCrumb } from 'pages/Administration/AutomationListPage/AutomationListPage';
import { ConnectorsPageCrumb } from 'pages/Administration/ConnectorsPage/ConnectorsPage';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { classNames } from '../../common/utils/classNames';
import { LanguageListPageCrumb } from '../../pages/Administration/LanguageListPage/LanguageListPage';
import { ProvidersPageCrumb } from '../../pages/Administration/ProvidersPage/ProvidersPage';
import { UserListPageCrumb } from '../../pages/Administration/UserListPage/UserListPage';
import { Breadcrumb } from '../Breadcrumbs/Breadcrumbs';

export interface Props {
  tabs: Breadcrumb[];
}

export const Tabs: React.FC<Props> = ({ tabs, children }): ReactElement => {
  const [t] = useTranslation();
  const location = useLocation();
  return (
    <>
      <div className="tabs is-boxed">
        <ul>
          {tabs.map(
            (tab): ReactElement => (
              <li key={tab.page} className={classNames(location.pathname.startsWith(tab.location) && 'is-active')}>
                <NavLink to={tab.location}>
                  <span className="icon is-small">
                    {typeof tab.icon === 'string' ? <i className={`fas fa-${tab.icon}`} /> : tab.icon}
                  </span>
                  <span>{t(`${tab.page}.title`)}</span>
                </NavLink>
              </li>
            ),
          )}
        </ul>
      </div>
      <div className="tab-content box">{children}</div>
    </>
  );
};

export const AdminTabs: React.FC<Omit<Props, 'tabs'>> = (props): ReactElement => (
  <Tabs
    tabs={[UserListPageCrumb, LanguageListPageCrumb, ConnectorsPageCrumb, AutomationListPageCrumb, ProvidersPageCrumb]}
    {...props}
  />
);

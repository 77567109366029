import React, { ReactElement } from 'react';

import { XillioEntity } from '../../common/interfaces/xillio';
import { ParentEntity } from './EntityBrowser';
import { FileEntity } from './FileEntity';
import { FolderEntity } from './FolderEntity';

export interface EntityOptions {
  selected: boolean;
  onSelect: () => void;
  nextParent: (parent: XillioEntity) => void;
}

export function createOptions(
  entity: XillioEntity,
  nextParent: (parent: XillioEntity) => void,
  selection: string[],
  onSelectionChange: (values: string[]) => void,
): EntityOptions {
  const id = getId(entity);

  return {
    selected: selection.includes(id),
    onSelect: (): void => toggleSelection(id, selection, onSelectionChange),
    nextParent: nextParent,
  };
}

export function toggleSelection(path: string, selection: string[], onChange: (values: string[]) => void): void {
  if (selection.includes(path)) {
    onChange(selection.filter(e => e !== path));
  } else {
    onChange([...selection, path]);
  }
}

export function getId(entity: XillioEntity): string {
  return entity.configurationId + entity.path;
}

export function getEntityName(entity: ParentEntity): string {
  if (entity === 'root') return 'All Configurations';
  return entity.original.name.displayName || entity.original.name.systemName;
}

export function getIcon(entity: XillioEntity): ReactElement {
  let className = 'icon has-text-grey-lighter fas fa-file';

  const mimeType = entity.original.mimeType;
  if (mimeType?.type === 'application/pdf') {
    className += '-pdf';
  } else if (mimeType?.type.startsWith('image/')) {
    className += '-image';
  }
  return <i className={className} />;
}

export function isConfiguration(entity: ParentEntity): boolean {
  return entity !== 'root' && entity.path === '/';
}

export function isFolder(entity: XillioEntity): boolean {
  return entity.original.container?.hasChildren || false;
}

export function sortEntities(a: XillioEntity, b: XillioEntity): number {
  if (isFolder(a) && !isFolder(b)) return -1;
  if (!isFolder(a) && isFolder(b)) return 1;
  return getEntityName(a).localeCompare(getEntityName(b));
}

export function renderEntity(entity: XillioEntity, options: EntityOptions): ReactElement {
  if (isFolder(entity)) return <FolderEntity key={getId(entity)} entity={entity} options={options} />;
  return <FileEntity key={getId(entity)} entity={entity} options={options} />;
}

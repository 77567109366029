import { Api } from 'modules/api/api';
import { TaskId } from 'modules/api/locHub/tasks/task/id/id';
import { TapiccStatus, TaskStatus } from 'modules/api/locHub/tasks/task/status/status';
import { Task } from 'modules/api/locHub/tasks/task/task';
import { queryCache } from 'react-query';

import { collectCallsWithErrors } from '../../../common/utils/collectCalls';

export async function markAllForTranslation(tasks: Task[]): Promise<[TaskId[], TaskId[]]> {
  const bulkResponse = await Api.locHub.tasks.setStatuses(
    tasks.map(task => ({ taskId: task.id, status: TapiccStatus.CONFIRMED })),
  );
  return Promise.resolve([
    Object.values(bulkResponse.data).map(task => task.id) || [],
    Object.keys(bulkResponse.errors).map(key => key, 10) || [],
  ]);
}

export async function publishAllToSource(tasks: Task[]): Promise<[TaskId[], { id: TaskId; error: unknown }[]]> {
  const calls = tasks.map(
    async (task: Task): Promise<string> => {
      try {
        const response = await Api.locHub.tasks.upload(task.id);
        if (Object.keys(response.errors).length) {
          throw Object.values(response.errors)[0];
        }
      } catch (error) {
        // eslint-disable-next-line no-throw-literal
        throw { id: task.id, error: error };
      }
      return task.id;
    },
  );
  return (await collectCallsWithErrors(calls)) as [TaskId[], { id: TaskId; error: unknown }[]];
}

export async function deleteTasksAndInputs(tasks: Task[]): Promise<void> {
  await Promise.all(
    tasks.map(async task => {
      const inputs = await Api.locHub.inputs.getAllByTask(task.id);
      for (const input of inputs) {
        await Api.locHub.inputs.delete(input.id);
      }
      await Api.locHub.tasks.delete(task.id);
    }),
  );
  queryCache.invalidateQueries(Api.locHub.inputs.path);
  queryCache.invalidateQueries(Api.locHub.tasks.path);
}

export function filterTasksByPhase(tasks: Task[], status: TaskStatus, selection: TaskId[]): Task[] {
  return tasks.filter(task => selection.includes(task.id) && task.isReadyForTransitionTo(status));
}

/**
 * Returns true if-and-only if this value is the first occurrence of this value in the array.
 * Can be used in conjunction with the `Array.filter()` do get only distinct values from the array,
 * as if it were a Set.
 */
export function distinct<T>(value: T, index: number, self: T[]): boolean {
  return index === self.indexOf(value);
}

export function getSelectionRange(tasks: Task[], selected: TaskId, lastSelection: TaskId | null): TaskId[] {
  const first = lastSelection === null ? 0 : tasks.findIndex(t => t.id === lastSelection);
  const last = tasks.findIndex(t => t.id === selected);

  const [start, end] = first < last ? [first, last] : [last, first];
  return tasks.filter((t, index) => index >= start && index <= end).map(t => t.id);
}

import { TimeDuration } from 'common/enums/time/duration/duration';
import { EntityName } from 'modules/api/locHub/entity/name/name';
import React, { ReactElement } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DurationInput } from '../../Common/Duration/Duration';
import { AutomationPeriodInputI18n } from './I18n';

const MINUTES_PER_YEAR = TimeDuration.REGULAR_YEAR / TimeDuration.MINUTE;

interface Props {
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
  value?: number;
}

export const AutomationPeriodInput: React.FC<Props> = ({ value, register, errors }): ReactElement => {
  const [t] = useTranslation();
  const minimum = 1;
  const maximum = MINUTES_PER_YEAR;
  const namespace = AutomationPeriodInputI18n.configuration.namespace;
  const label = t(`${namespace}.label`);
  const placeholder = t(`${namespace}.placeholder`);
  const validationMaxError = t(`${namespace}.validationMaximumError`, { maximum });
  const validationMinError = t(`${namespace}.validationMinimumError`, { minimum });
  const validationRequiredError = t(`${namespace}.validationRequiredError`);
  return (
    <DurationInput
      entity={EntityName.AUTOMATION}
      field="period"
      value={value}
      register={register}
      errors={errors}
      min={minimum}
      max={maximum}
      required={true}
      texts={{ label, placeholder, validationMinError, validationMaxError, validationRequiredError }}
    />
  );
};

import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Translatable } from '../../../common/language';
import { LanguageInfo } from '../../../pages/Overview/TaskCreatePage/TaskCreatePage';
import { LanguageTag } from '../../LanguageTag/LanguageTag';

export const DistinctTargetLanguages: React.FC<Translatable & {
  languageInfo: LanguageInfo<object>[];
}> = ({ i18nKey, languageInfo }): ReactElement => {
  const [t] = useTranslation();

  // Styles
  const overview: React.CSSProperties = {
    display: 'flex',
  };
  const targetStyle: React.CSSProperties = {
    flex: '1 0 0px',
    marginLeft: '10%',
  };

  return (
    <div className="overview-line" style={overview}>
      {t(`${i18nKey}.totalTargetLanguages`)}
      <div style={targetStyle}>
        {languageInfo
          .map(info => info.targetLanguageCodes)
          .reduce((total, current) => total.concat(current), [])
          .filter((value, index, self) => self.indexOf(value) === index)
          .sort()
          .map(lang => (
            <div className="tags has-addons is-pulled-right" key={lang}>
              <LanguageTag language={lang} />
              <span className="tag is-info metric">
                {languageInfo
                  .map(info => info.targetLanguageCodes.filter(l => l === lang).length)
                  .reduce((total, current) => total + current, 0)}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

function equals<T>(change: T | undefined, originalElement: T): boolean {
  if (Array.isArray(originalElement) && Array.isArray(change)) {
    return (
      originalElement.length === change.length && originalElement.every((value, index) => equals(value, change[index]))
    );
  }
  return change === originalElement;
}

export function getChanges<T extends {}>(changes: Partial<T>, original: T | undefined): Partial<T> {
  if (original === undefined) {
    return changes;
  }
  const result: Partial<T> = {};

  for (const key in changes) {
    if (changes.hasOwnProperty(key) && !equals(changes[key], original[key])) {
      result[key] = changes[key];
    }
  }

  return result;
}

export enum EntityName {
  ASSET = 'asset',
  AUTOMATION = 'automation',
  DELIVERABLE = 'deliverable',
  INPUT = 'input',
  JOB = 'job',
  LANGUAGE = 'language',
  PROJECT = 'project',
  TASK = 'task',
  USER = 'user',
}

import { Button, Props as ButtonProps } from 'components/Button/Button';
import React, { ReactElement } from 'react';

export interface Props extends ButtonProps {
  loading: boolean;
  onClick?: () => void;
}

export const LoadingButton: React.FC<Props> = ({ loading, children, ...props }): ReactElement => (
  <Button disabled={loading} {...props}>
    {loading ? <i className="fas fa-spin fa-spinner" style={{ marginRight: '8px' }} /> : children}
  </Button>
);

import { HttpError } from 'modules/api/error/error';
import { HttpStatusCode } from 'modules/api/status/status';
import { UUIDv4 } from 'modules/types/uuid/v4/v4';

interface LocHubErrorBody {
  readonly timestamp: string;
  readonly status: HttpStatusCode;
  readonly error: string; // eg. 'Not Found'
  readonly message: string; // eg. '0cb4f705-28fd-4e16-b54e-659748fa54e5 is not found'
  readonly path: string; // eg. '/api/internal/jobs/0cb4f705-28fd-4e16-b54e-659748fa54e5'
  readonly errorId: UUIDv4;
}

export class LocHubHttpError extends HttpError<null> {
  public readonly id: UUIDv4;
  public readonly timestamp: Date;
  public readonly code: HttpStatusCode;
  public readonly response: Response;
  public readonly url: string;

  public constructor(response: Response, body: LocHubErrorBody) {
    super(response, null);
    Object.setPrototypeOf(this, LocHubHttpError.prototype);
    this.id = body.errorId;
    this.timestamp = new Date(body.timestamp);
    this.message = body.message;
    this.code = response.status;
    this.response = response;
    this.url = response.url;
  }

  public static satisfy(body: Partial<LocHubErrorBody>): boolean {
    if (body.timestamp && body.status && body.error && body.message && body.path && body.errorId) {
      return true;
    }
    return false;
  }
}

import { dictionary } from 'common/language/dictionary';
import { I18nNamespace } from 'common/language/namespace';

export const AutomationCreatePageI18n = {
  configuration: { namespace: I18nNamespace.AUTOMATION_CREATE_PAGE, labelKey: 'label' },
  translations: {
    title: dictionary.createAutomation,
    description: dictionary.createAutomationDescription,
    label: dictionary.createAutomation,
    loadingError: dictionary.dataLoadingError,
    automationCreateSuccess: dictionary.automationCreateSuccess,
    automationCreateFailure: dictionary.automationCreateFailure,
  },
};

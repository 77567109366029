import React, { Dispatch, ReactElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useEscapeEvent } from '../../common/hooks/useKeyEvent';
import { useOutsideClickEvent } from '../../common/hooks/useMouseEvent';
import { Translatable } from '../../common/language';
import { classNames } from '../../common/utils/classNames';
import { HtmlProps } from '../../common/utils/HTMLProps';
import { LanguageInfo } from '../../pages/Overview/TaskCreatePage/TaskCreatePage';
import { Tooltip } from '../Tooltip/Tooltip';

interface Props extends Translatable, HtmlProps {
  i18nKey: string;
  languageInfo: LanguageInfo<object>;
  languages: string[];
  value: undefined | string;
  onChange: Dispatch<undefined | string>;
}

export const SourceLanguageDropdown: React.FC<Props> = ({
  i18nKey,
  languageInfo,
  languages,
  value,
  onChange,
  className,
}): ReactElement => {
  const [t] = useTranslation();
  const [changed, setChanged] = useState(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  // Hide the menu when clicking outside of it
  const ref = useRef(null);
  useOutsideClickEvent(() => setShowDropdown(false), ref);

  // Hide the menu when escape-key is pressed.
  useEscapeEvent(() => setShowDropdown(false));

  return (
    <div className={classNames('tags has-addons', className)} ref={ref}>
      {showDropdown ? (
        <select
          className="tag is-info is-light"
          onChange={(e): void => {
            setChanged(true);
            onChange(e.target.value);
            setShowDropdown(false);
          }}
        >
          <option className={'is-hidden'} hidden>
            {value}
          </option>
          {languages.map(lang => (
            <option key={lang} value={lang}>
              {lang}
            </option>
          ))}
        </select>
      ) : (
        <span onClick={(): void => setShowDropdown(true)}>
          <span className={classNames('tag', 'is-light', value ? 'is-info' : 'is-danger')}>
            {value || t(`${i18nKey}.tooltips.undefined`)}
          </span>
          <span className={classNames('tag', 'metric', value ? 'is-info' : 'is-danger')}>
            <InformationIcon
              i18nKey={i18nKey}
              value={value}
              changed={changed}
              languageTag={languageInfo.sourceLanguage.cms}
              defaultLanguage={languageInfo.sourceLanguage.default}
              languages={languages}
            />
          </span>
        </span>
      )}
    </div>
  );
};

export const InformationIcon: React.FC<{
  i18nKey: string;
  value: string | undefined;
  changed: boolean;
  languageTag: string | undefined;
  defaultLanguage: string | undefined;
  languages: string[];
}> = ({ i18nKey, value, changed, languageTag, defaultLanguage, languages }): ReactElement => {
  const [t] = useTranslation();

  const wrap = (key: string, icon: string, args?: object): ReactElement => (
    <Tooltip className={'tooltip-big'} message={t(`${i18nKey}.tooltips.${key}`, args)}>
      <i className={`fas fa-${icon}`} />
    </Tooltip>
  );

  if (!changed) {
    if (languageTag && defaultLanguage && languageTag !== defaultLanguage && value === languageTag) {
      return wrap('notMatching', 'exclamation-triangle', { tag: languageTag, default: defaultLanguage });
    }
    if (languageTag && !languages.includes(languageTag)) {
      return wrap('unknownTag', 'exclamation-triangle', { tag: languageTag });
    }
    if (!languageTag && !defaultLanguage) {
      return wrap('noInfo', 'exclamation-triangle');
    }
    if (languageTag || defaultLanguage) {
      return wrap('automatic', 'magic');
    }
  }
  return wrap('edited', 'edit', { tag: languageTag ?? defaultLanguage ?? '' });
};

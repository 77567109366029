import { ConfirmButton } from 'components/ConfirmButton/ConfirmButton';
import { useMessage } from 'components/Message/MessageProvider';
import { Api } from 'modules/api/api';
import { JobId } from 'modules/api/locHub/jobs/job/id/id';
import { useJobTasks } from 'modules/api/locHub/tasks/query/listByJob/listByJob';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { JobEditPageI18n } from './I18n';

interface Props {
  jobId: JobId;
  actions: {
    deleted?: () => void;
  };
}

export const JobDeleteButton: React.FC<Props> = ({ jobId, actions }): ReactElement => {
  const [t] = useTranslation();
  const message = useMessage();
  const jobTasks = useJobTasks(jobId);
  const namespace = `${JobEditPageI18n.configuration.namespace}.deleteButton`;
  return (
    <ConfirmButton
      i18nKey={namespace}
      className="is-danger"
      disabled={!jobTasks.success || jobTasks.data.length !== 0}
      loading={!jobTasks}
      onConfirm={async (): Promise<void> => {
        try {
          await Api.locHub.jobs.delete(jobId);
          if (actions.deleted) {
            actions.deleted();
          }
          message.success(t(`${namespace}.success`));
        } catch (error) {
          message.error(t(`${namespace}.failure`));
        }
      }}
    />
  );
};

import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { classNames } from '../../common/utils/classNames';

export const SelectionTile: React.FC<{
  icon: string;
  type: string;
  selected?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}> = ({ icon, type, onClick, selected, disabled }): ReactElement => {
  const [t] = useTranslation();
  return (
    <div className="tile is-parent">
      <div
        onClick={onClick}
        className={classNames(
          'tile is-child box has-text-centered source-section-card',
          selected && 'is-selected',
          disabled && 'is-disabled',
        )}
      >
        <i className={`fas fa-4x fa-${icon}`} />
        <h3 className="title is-4 is-spaced">{t(`${type}.title`)}</h3>
        <p className="subtitle is-6 has-text-weight-semibold">{t(`${type}.description`)}</p>
      </div>
    </div>
  );
};

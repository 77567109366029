import { ISODateWithTimezone } from 'modules/types/date/iso/iso';

import { AutomationId } from '../../automations/automation/id/id';
import { LocHubEntity } from '../../entity/entity';
import { ProjectId } from '../../projects/project/id/id';
import { JobId } from './id/id';

export class Job implements JobDto {
  public readonly id: JobId;
  public readonly name: string;
  public readonly description?: string;
  public readonly dueAt?: ISODateWithTimezone;
  public readonly projectId: ProjectId;
  public readonly automationId?: AutomationId;
  public readonly defaultSourceLanguage?: string;
  public readonly defaultTargetLanguages: string[];
  public readonly submitter?: string;
  public readonly createdAt: ISODateWithTimezone;
  public readonly updatedAt: ISODateWithTimezone;
  public readonly archivedAt?: ISODateWithTimezone;
  public readonly archived: boolean;
  public constructor(job: JobDto) {
    this.id = job.id;
    this.name = job.name;
    this.description = job.description;
    this.dueAt = job.dueAt;
    this.projectId = job.projectId;
    this.automationId = job.automationId;
    this.defaultSourceLanguage = job.defaultSourceLanguage;
    this.defaultTargetLanguages = job.defaultTargetLanguages;
    this.submitter = job.submitter;
    this.createdAt = job.createdAt;
    this.updatedAt = job.updatedAt;
    this.archivedAt = job.archivedAt;
    this.archived = job.archived;
  }

  private isCreatedByAemPlugin(): boolean {
    if (this.submitter && this.submitter === 'AEM Lochub translation service') {
      return true;
    }
    return false;
  }

  public mayUploadTasks(): boolean {
    if (this.isCreatedByAemPlugin()) {
      return false;
    }
    return true;
  }

  public mayDeleteTasks(): boolean {
    if (this.isCreatedByAemPlugin()) {
      return false;
    }
    return true;
  }

  public mayCreateTasks(): boolean {
    if (this.isCreatedByAemPlugin()) {
      return false;
    }
    return true;
  }
}

export interface JobDto extends LocHubEntity {
  name: string;
  description?: string;
  dueAt?: ISODateWithTimezone;
  projectId: ProjectId;
  automationId?: AutomationId;
  defaultSourceLanguage?: string;
  submitter?: string;
  defaultTargetLanguages: string[];
}

export interface NewJob {
  name: string;
  description?: string;
  dueAt?: ISODateWithTimezone;
  projectId: ProjectId;
  defaultSourceLanguage?: string;
  defaultTargetLanguages?: string[];
}

import { AppLayout } from 'components/AppLayout/AppLayout';
import { usePaginatedJobsWithProject } from 'modules/api/locHub/jobs/query/paginatedList/paginatedList';
import { useProjects } from 'modules/api/locHub/projects/query/list/list';
import { LoadingPage } from 'pages/Common/LoadingPage/LoadingPage';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs';
import { Metric } from '../../../components/Metric/Metric';
import { TD } from '../../../components/TD/TD';

export const HomePageCrumb: Breadcrumb = {
  page: 'Overview.HomePage',
  icon: <img src={`${process.env.PUBLIC_URL}/logos/logo-small.png`} alt="LocHub" />,
  location: '/home',
};

export const HomePage: React.FC = (): ReactElement => {
  const [t] = useTranslation();
  const projects = useProjects();
  const jobs = usePaginatedJobsWithProject(1, { sort: 'createdAt', direction: 'DESC' });
  if (projects.loading || jobs.loading) {
    return <LoadingPage />;
  }
  return (
    <AppLayout breadcrumbs={[HomePageCrumb]}>
      <div className="tile is-ancestor">
        <Metric
          i18nKey="Overview.HomePage.openJobsMetric"
          value={jobs.success ? jobs.data.totalElements : '-'}
          link="#/jobs"
        />
        <Metric
          i18nKey="Overview.HomePage.openProjectCountMetric"
          value={projects.success ? projects.data.length : '-'}
          link="#/projects"
        />
      </div>

      <div className="tile is-child box" id="home">
        <h3 className="title is-4 is-spaced">{t('Overview.HomePage.recentJobs')}</h3>
        <div className=" table-container">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <td>{t('Overview.HomePage.projectName')}</td>
                <td>{t('Overview.HomePage.jobName')}</td>
                <td>{t('Overview.HomePage.createdAt')}</td>
                <td>{t('Overview.HomePage.updatedAt')}</td>
              </tr>
            </thead>
            <tbody>
              {jobs.success ? (
                jobs.data.totalElements > 0 ? (
                  jobs.data.content.map(({ id, name, createdAt, updatedAt, project }) => (
                    <tr key={id}>
                      <TD wrap>
                        <a href={`#/projects/${project.id}`} className="has-text-link">
                          {project.name}
                        </a>
                      </TD>
                      <TD wrap>
                        <a href={`#/jobs/${id}`} className="has-text-link">
                          {name}
                        </a>
                      </TD>
                      <TD>
                        {t('locale.date', {
                          date: new Date(createdAt),
                        })}
                      </TD>
                      <TD>
                        {t('locale.fromNow', {
                          date: new Date(updatedAt),
                        })}
                      </TD>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7} className="has-text-centered">
                      {t('Overview.HomePage.emptyTable')}
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan={7} className="has-text-centered">
                    <i className="fas fa-spinner is-spinning" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </AppLayout>
  );
};

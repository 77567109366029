import { Api } from 'modules/api/api';
import { QueryConfiguration } from 'modules/api/locHub/entity/query/configuration/configuration';
import { QueryResult } from 'modules/api/locHub/entity/query/query';

import { useLocHubDtoQuery } from '../../../entity/query/detail/detail';
import { ProjectId } from '../../project/id/id';
import { ProjectDto } from '../../project/project';

export const useProjectDto = (
  id: ProjectId | undefined,
  configuration?: QueryConfiguration<ProjectDto | undefined, unknown>,
): QueryResult<ProjectDto> => {
  return useLocHubDtoQuery(Api.locHub.projects, id, configuration);
};

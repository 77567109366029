import React, { Children, MouseEvent, ReactElement, cloneElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useEscapeEvent } from '../../common/hooks/useKeyEvent';
import { useOutsideClickEvent } from '../../common/hooks/useMouseEvent';
import { Translatable } from '../../common/language';
import { classNames } from '../../common/utils/classNames';
import { HtmlProps } from '../../common/utils/HTMLProps';

interface Props extends HtmlProps {
  trigger?: ReactElement;
  direction?: 'left' | 'right';
}

export const Dropdown: React.FC<Props> = ({ id, className, trigger, direction = 'left', children }): ReactElement => {
  const [show, setShow] = useState(false);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  // Hide the menu when clicking outside of it
  const ref = useRef(null);
  useOutsideClickEvent(() => setShow(false), ref);

  // Hide the menu when escape-key is pressed.
  useEscapeEvent(() => setShow(false));

  // Clicking the ellipsis show toggle the menu
  const toggleShow = (e: MouseEvent): void => {
    setX(e.clientX);
    setY(e.clientY);
    e.stopPropagation();
    setShow(old => !old);
  };

  // Upon clicking a menu item, hide the menu and execute its function.
  const wrapOnClick = (e: MouseEvent, fn: (e: MouseEvent) => void): void => {
    setShow(false);
    e.stopPropagation();
    if (typeof fn === 'function') {
      fn(e);
    }
  };

  // Set a default trigger if none is given.
  if (!trigger) {
    trigger = (
      <span className="button">
        <i className="fas fa-ellipsis-v" />
      </span>
    );
  }

  return (
    <div id={id} className={classNames('dropdown', className)} ref={ref}>
      {cloneElement(trigger, { onClick: (e: MouseEvent) => toggleShow(e) })}
      <div
        className={classNames('menu', show && 'collapsed', 'is-' + direction)}
        style={{ position: 'fixed', top: `${y}px`, right: `${window.innerWidth - x}px` }}
      >
        {Children.map(children, child => {
          if (!child) return <></>;
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          return cloneElement(child, { onClick: (e: MouseEvent) => wrapOnClick(e, child.props.onClick) });
        })}
      </div>
    </div>
  );
};

export const DropdownOption: React.FC<HtmlProps &
  Translatable & {
    description?: string;
    icon?: string;
    onClick?: (e: MouseEvent) => void;
  }> = ({ i18nKey, i18nArgs, description, icon, onClick, id, className }): ReactElement => {
  const [t] = useTranslation();
  return (
    <div className={classNames('option', className)} id={id} onClick={onClick}>
      {icon && <i className={`fas is-size-6 fa-${icon}`} />}
      <span className="text is-size-7">{t(i18nKey, i18nArgs)}</span>
      {description && <span className="text is-size-7 description">{t(description)}</span>}
    </div>
  );
};

export const DropdownDivider: React.FC = () => <hr />;

import { XillioProvider } from 'common/interfaces/xillio';

export enum XillioProviderType {
  ONE_STOP_ASIA = '1-Stop Asia',
  TWO_M_LANGUAGE_SERVICES = '2M Language Services',
  AB_LANGUAGE_SOLUTIONS = 'AB Language Solutions',
  ADRIANA_SANDRU_TRADUCTIONS = 'Adriana Sandru Traductions',
  ALBION_LANGUAGES = 'Albion Languages',
  ALBA_TRANSLATING_COMPANY = 'Alba Translating Company',
  ALPHA_RC = 'Alpha CRC',
  ANGIRA_WORLDWIDE = 'Angira Worldwide',
  ANOUK_CO = 'Anouk Co',
  AP_PORTUGAL = 'AP Portugal',
  ARKI_BUSINESS_TRANSLATIONS = 'Arki Business Translations',
  ASPENA = 'Aspena',
  ATHENA_PARTHENOS = 'Athena Parthenos',
  ATTACHED_LANGUAGE_INTELLIGENCE = 'Attached Language Intelligence',
  BERBA = 'Berba',
  BUSINESS_SOLUTION_CENTER = 'Business Solution Center',
  CAUPENNE_CO = 'Caupenne CO',
  COMMIT_GLOBAL = 'Commit',
  CONNECT_GLOBAL_LLC = 'Connect Global LLC',
  CONNECTING_CONTENT = 'Connecting Content',
  CONTENT_QUO = 'Content Quo',
  CREATIVE_TRANSLATION_SERVICES_DESK = 'Creative Translation Services Desk',
  CREATIVE_WORDS = 'Creative Words',
  DARITRANS = 'Daritrans',
  DILKENT_TERCUME = 'Dilkent Tercume',
  E_ARABIZATION = 'e-Arabization',
  EC_INNOVATIONS = 'EC Innovations',
  EL_TURCO = 'El Turco',
  EMPOWER = 'Empower',
  EUROCOM = 'Eurocom',
  FARSI_LANGUAGE_SOLUTIONS = 'Farsi Language Solutions',
  FOLLOW_UP = 'Follow Up',
  GATEWAY_TRANSLATIONS = 'Gateway Translations',
  GLOBAL_LINGO = 'Global Lingo',
  GLOCALIZE_INC = 'Glocalize',
  GM_VOICES = 'GM Voices',
  HLA_HISPANO_LANGUAGE_ADVISORY = 'HLA Hispano Language Advisory',
  IDISC = 'iDisc',
  INLINGO = 'Inlingo',
  INTO23 = 'Into23',
  ITI_TECHNOLOGY = 'ITI Technology',
  ITL = 'ITL',
  JANUS_WORLDWIDE = 'Janus',
  KALEIDOSCOPE = 'Kaleidoscope',
  KALU_MEDIA = 'Kalu Media',
  KL_TRANSLATIONS_LTD = 'KL Translations',
  LANGUAGLINE = 'Languageline',
  LANGUAGEWEB24 = 'Languageweb24',
  LINGO_FOCUS = 'Lingo Focus',
  LINGOOWL = 'Lingoowl',
  LINGUA_GRECA = 'Lingua Greca',
  LINGUASERVE = 'Linguaserve',
  LITERRA_TRANSLATION_COMPANY = 'Literra Translation Company',
  LOCALEX = 'Localex',
  LOCALISED_WEB = 'Localised Web',
  LOCHUB_QA_INSIGHTS = 'Lochub QA Insights',
  LOGRUS_GLOBAL = 'Logrus Global',
  MEMOQ = 'MemoQ',
  MOTAWORD = 'Motaword',
  MOTHER_TONGUE = 'Mother Tongue',
  NATIVE_PRIME = 'Native Prime',
  NORDTEXT = 'Nordtext',
  OMADA = 'Omada',
  PLANET_LANGUAGES = 'Planet Languages',
  POWERLING = 'Powerling',
  POZENA = 'Pozena',
  PROTRANSLATE = 'Protranslate',
  QABIRIA = 'Qabiria',
  RUFILMS = 'RuFilms',
  RWS = 'RWS',
  SCRIPTIS_TRANSLATIONS_INC = 'Scriptis Translations Inc',
  SUPERTEXT = 'Supertext',
  TANDF = 'TF',
  TASMA = 'Tasama',
  TEXT_UNITED = 'Text United',
  THE_TRANSLATION_PEOPLE = 'The Translation People',
  TOIN_CORPORATION = 'Toin Corporation',
  TRADCREATION = 'TradCreation',
  TRANSLATE_5 = 'Translate-5',
  TRANSLATEFX = 'TranslateFX',
  TRANSLATEMEDIA = 'TranslateMedia',
  TRANSLATERSPRO = 'Translaterspro',
  TRANSLATORS_FAMILY = 'Translators Family',
  TTC_WETRANSALTE = 'TTC Wetranslate',
  TW_LANGUAGES = 'TW Languages',
  ULTRA_LOCALIZE = 'Ultra Localize',
  UNITED_LANGUAGE_GROUP = 'United Language Group',
  UNITED_LANGUAGE_SOLUTION = 'United Language Solution',
  WORLD_TRANSLATION = 'World Translation',
}

export class XillioProviders {
  private static providers = new Map<XillioProviderType, XillioProvider>([
    [
      XillioProviderType.ONE_STOP_ASIA,
      {
        configurationType: XillioProviderType.ONE_STOP_ASIA,
      },
    ],
    [
      XillioProviderType.TWO_M_LANGUAGE_SERVICES,
      {
        configurationType: XillioProviderType.TWO_M_LANGUAGE_SERVICES,
      },
    ],
    [
      XillioProviderType.AB_LANGUAGE_SOLUTIONS,
      {
        configurationType: XillioProviderType.AB_LANGUAGE_SOLUTIONS,
      },
    ],
    [
      XillioProviderType.ADRIANA_SANDRU_TRADUCTIONS,
      {
        configurationType: XillioProviderType.ADRIANA_SANDRU_TRADUCTIONS,
      },
    ],
    [
      XillioProviderType.ALBION_LANGUAGES,
      {
        configurationType: XillioProviderType.ALBION_LANGUAGES,
      },
    ],
    [
      XillioProviderType.ALBA_TRANSLATING_COMPANY,
      {
        configurationType: XillioProviderType.ALBA_TRANSLATING_COMPANY,
      },
    ],
    [
      XillioProviderType.ALPHA_RC,
      {
        configurationType: XillioProviderType.ALPHA_RC,
      },
    ],
    [
      XillioProviderType.ANGIRA_WORLDWIDE,
      {
        configurationType: XillioProviderType.ANGIRA_WORLDWIDE,
      },
    ],
    [
      XillioProviderType.ANOUK_CO,
      {
        configurationType: XillioProviderType.ANOUK_CO,
      },
    ],
    [
      XillioProviderType.AP_PORTUGAL,
      {
        configurationType: XillioProviderType.AP_PORTUGAL,
      },
    ],
    [
      XillioProviderType.ARKI_BUSINESS_TRANSLATIONS,
      {
        configurationType: XillioProviderType.ARKI_BUSINESS_TRANSLATIONS,
      },
    ],
    [
      XillioProviderType.ASPENA,
      {
        configurationType: XillioProviderType.ASPENA,
      },
    ],
    [
      XillioProviderType.ATHENA_PARTHENOS,
      {
        configurationType: XillioProviderType.ATHENA_PARTHENOS,
      },
    ],
    [
      XillioProviderType.ATTACHED_LANGUAGE_INTELLIGENCE,
      {
        configurationType: XillioProviderType.ATTACHED_LANGUAGE_INTELLIGENCE,
      },
    ],
    [
      XillioProviderType.BERBA,
      {
        configurationType: XillioProviderType.BERBA,
      },
    ],
    [
      XillioProviderType.BUSINESS_SOLUTION_CENTER,
      {
        configurationType: XillioProviderType.BUSINESS_SOLUTION_CENTER,
      },
    ],
    [
      XillioProviderType.CAUPENNE_CO,
      {
        configurationType: XillioProviderType.CAUPENNE_CO,
      },
    ],
    [
      XillioProviderType.COMMIT_GLOBAL,
      {
        configurationType: XillioProviderType.COMMIT_GLOBAL,
      },
    ],
    [
      XillioProviderType.CONNECT_GLOBAL_LLC,
      {
        configurationType: XillioProviderType.CONNECT_GLOBAL_LLC,
      },
    ],
    [
      XillioProviderType.CONNECTING_CONTENT,
      {
        configurationType: XillioProviderType.CONNECTING_CONTENT,
      },
    ],
    [
      XillioProviderType.CONTENT_QUO,
      {
        configurationType: XillioProviderType.CONTENT_QUO,
      },
    ],
    [
      XillioProviderType.CREATIVE_TRANSLATION_SERVICES_DESK,
      {
        configurationType: XillioProviderType.CREATIVE_TRANSLATION_SERVICES_DESK,
      },
    ],
    [
      XillioProviderType.CREATIVE_WORDS,
      {
        configurationType: XillioProviderType.CREATIVE_WORDS,
      },
    ],
    [
      XillioProviderType.DARITRANS,
      {
        configurationType: XillioProviderType.DARITRANS,
      },
    ],
    [
      XillioProviderType.DILKENT_TERCUME,
      {
        configurationType: XillioProviderType.DILKENT_TERCUME,
      },
    ],
    [
      XillioProviderType.E_ARABIZATION,
      {
        configurationType: XillioProviderType.E_ARABIZATION,
      },
    ],
    [
      XillioProviderType.EC_INNOVATIONS,
      {
        configurationType: XillioProviderType.EC_INNOVATIONS,
      },
    ],
    [
      XillioProviderType.EL_TURCO,
      {
        configurationType: XillioProviderType.EL_TURCO,
      },
    ],
    [
      XillioProviderType.EMPOWER,
      {
        configurationType: XillioProviderType.EMPOWER,
      },
    ],
    [
      XillioProviderType.EUROCOM,
      {
        configurationType: XillioProviderType.EUROCOM,
      },
    ],
    [
      XillioProviderType.FARSI_LANGUAGE_SOLUTIONS,
      {
        configurationType: XillioProviderType.FARSI_LANGUAGE_SOLUTIONS,
      },
    ],
    [
      XillioProviderType.FOLLOW_UP,
      {
        configurationType: XillioProviderType.FOLLOW_UP,
      },
    ],
    [
      XillioProviderType.GATEWAY_TRANSLATIONS,
      {
        configurationType: XillioProviderType.GATEWAY_TRANSLATIONS,
      },
    ],
    [
      XillioProviderType.GLOBAL_LINGO,
      {
        configurationType: XillioProviderType.GLOBAL_LINGO,
      },
    ],
    [
      XillioProviderType.GLOCALIZE_INC,
      {
        configurationType: XillioProviderType.GLOCALIZE_INC,
      },
    ],
    [
      XillioProviderType.GM_VOICES,
      {
        configurationType: XillioProviderType.GM_VOICES,
      },
    ],
    [
      XillioProviderType.HLA_HISPANO_LANGUAGE_ADVISORY,
      {
        configurationType: XillioProviderType.HLA_HISPANO_LANGUAGE_ADVISORY,
      },
    ],
    [
      XillioProviderType.IDISC,
      {
        configurationType: XillioProviderType.IDISC,
      },
    ],
    [
      XillioProviderType.INLINGO,
      {
        configurationType: XillioProviderType.INLINGO,
      },
    ],
    [
      XillioProviderType.INTO23,
      {
        configurationType: XillioProviderType.INTO23,
      },
    ],
    [
      XillioProviderType.ITI_TECHNOLOGY,
      {
        configurationType: XillioProviderType.ITI_TECHNOLOGY,
      },
    ],
    [
      XillioProviderType.ITL,
      {
        configurationType: XillioProviderType.ITL,
      },
    ],
    [
      XillioProviderType.JANUS_WORLDWIDE,
      {
        configurationType: XillioProviderType.JANUS_WORLDWIDE,
      },
    ],
    [
      XillioProviderType.KALEIDOSCOPE,
      {
        configurationType: XillioProviderType.KALEIDOSCOPE,
      },
    ],
    [
      XillioProviderType.KALU_MEDIA,
      {
        configurationType: XillioProviderType.KALU_MEDIA,
      },
    ],
    [
      XillioProviderType.KL_TRANSLATIONS_LTD,
      {
        configurationType: XillioProviderType.KL_TRANSLATIONS_LTD,
      },
    ],
    [
      XillioProviderType.LANGUAGLINE,
      {
        configurationType: XillioProviderType.LANGUAGLINE,
      },
    ],
    [
      XillioProviderType.LANGUAGEWEB24,
      {
        configurationType: XillioProviderType.LANGUAGEWEB24,
      },
    ],
    [
      XillioProviderType.LINGO_FOCUS,
      {
        configurationType: XillioProviderType.LINGO_FOCUS,
      },
    ],
    [
      XillioProviderType.LINGOOWL,
      {
        configurationType: XillioProviderType.LINGOOWL,
      },
    ],
    [
      XillioProviderType.LINGUA_GRECA,
      {
        configurationType: XillioProviderType.LINGUA_GRECA,
      },
    ],
    [
      XillioProviderType.LINGUASERVE,
      {
        configurationType: XillioProviderType.LINGUASERVE,
      },
    ],
    [
      XillioProviderType.LITERRA_TRANSLATION_COMPANY,
      {
        configurationType: XillioProviderType.LITERRA_TRANSLATION_COMPANY,
      },
    ],
    [
      XillioProviderType.LOCALEX,
      {
        configurationType: XillioProviderType.LOCALEX,
      },
    ],
    [
      XillioProviderType.LOCHUB_QA_INSIGHTS,
      {
        configurationType: XillioProviderType.LOCHUB_QA_INSIGHTS,
      },
    ],
    [
      XillioProviderType.LOCALISED_WEB,
      {
        configurationType: XillioProviderType.LOCALISED_WEB,
      },
    ],
    [
      XillioProviderType.LOGRUS_GLOBAL,
      {
        configurationType: XillioProviderType.LOGRUS_GLOBAL,
      },
    ],
    [
      XillioProviderType.MEMOQ,
      {
        configurationType: XillioProviderType.MEMOQ,
      },
    ],
    [
      XillioProviderType.MOTAWORD,
      {
        configurationType: XillioProviderType.MOTAWORD,
      },
    ],
    [
      XillioProviderType.MOTHER_TONGUE,
      {
        configurationType: XillioProviderType.MOTHER_TONGUE,
      },
    ],
    [
      XillioProviderType.NATIVE_PRIME,
      {
        configurationType: XillioProviderType.NATIVE_PRIME,
      },
    ],
    [
      XillioProviderType.NORDTEXT,
      {
        configurationType: XillioProviderType.NORDTEXT,
      },
    ],
    [
      XillioProviderType.OMADA,
      {
        configurationType: XillioProviderType.OMADA,
      },
    ],
    [
      XillioProviderType.PLANET_LANGUAGES,
      {
        configurationType: XillioProviderType.PLANET_LANGUAGES,
      },
    ],
    [
      XillioProviderType.POWERLING,
      {
        configurationType: XillioProviderType.POWERLING,
      },
    ],
    [
      XillioProviderType.POZENA,
      {
        configurationType: XillioProviderType.POZENA,
      },
    ],
    [
      XillioProviderType.PROTRANSLATE,
      {
        configurationType: XillioProviderType.PROTRANSLATE,
      },
    ],
    [
      XillioProviderType.QABIRIA,
      {
        configurationType: XillioProviderType.QABIRIA,
      },
    ],
    [
      XillioProviderType.RUFILMS,
      {
        configurationType: XillioProviderType.RUFILMS,
      },
    ],
    [
      XillioProviderType.RWS,
      {
        configurationType: XillioProviderType.RWS,
      },
    ],
    [
      XillioProviderType.SCRIPTIS_TRANSLATIONS_INC,
      {
        configurationType: XillioProviderType.SCRIPTIS_TRANSLATIONS_INC,
      },
    ],
    [
      XillioProviderType.SUPERTEXT,
      {
        configurationType: XillioProviderType.SUPERTEXT,
      },
    ],
    [
      XillioProviderType.TANDF,
      {
        configurationType: XillioProviderType.TANDF,
      },
    ],
    [
      XillioProviderType.TASMA,
      {
        configurationType: XillioProviderType.TASMA,
      },
    ],
    [
      XillioProviderType.TEXT_UNITED,
      {
        configurationType: XillioProviderType.TEXT_UNITED,
      },
    ],
    [
      XillioProviderType.THE_TRANSLATION_PEOPLE,
      {
        configurationType: XillioProviderType.THE_TRANSLATION_PEOPLE,
      },
    ],
    [
      XillioProviderType.TOIN_CORPORATION,
      {
        configurationType: XillioProviderType.TOIN_CORPORATION,
      },
    ],
    [
      XillioProviderType.TRADCREATION,
      {
        configurationType: XillioProviderType.TRADCREATION,
      },
    ],
    [
      XillioProviderType.TRANSLATE_5,
      {
        configurationType: XillioProviderType.TRANSLATE_5,
      },
    ],
    [
      XillioProviderType.TRANSLATEFX,
      {
        configurationType: XillioProviderType.TRANSLATEFX,
      },
    ],
    [
      XillioProviderType.TRANSLATEMEDIA,
      {
        configurationType: XillioProviderType.TRANSLATEMEDIA,
      },
    ],
    [
      XillioProviderType.TRANSLATERSPRO,
      {
        configurationType: XillioProviderType.TRANSLATERSPRO,
      },
    ],
    [
      XillioProviderType.TRANSLATORS_FAMILY,
      {
        configurationType: XillioProviderType.TRANSLATORS_FAMILY,
      },
    ],
    [
      XillioProviderType.TTC_WETRANSALTE,
      {
        configurationType: XillioProviderType.TTC_WETRANSALTE,
      },
    ],
    [
      XillioProviderType.TW_LANGUAGES,
      {
        configurationType: XillioProviderType.TW_LANGUAGES,
      },
    ],
    [
      XillioProviderType.ULTRA_LOCALIZE,
      {
        configurationType: XillioProviderType.ULTRA_LOCALIZE,
      },
    ],
    [
      XillioProviderType.UNITED_LANGUAGE_GROUP,
      {
        configurationType: XillioProviderType.UNITED_LANGUAGE_GROUP,
      },
    ],
    [
      XillioProviderType.UNITED_LANGUAGE_SOLUTION,
      {
        configurationType: XillioProviderType.UNITED_LANGUAGE_SOLUTION,
      },
    ],
    [
      XillioProviderType.WORLD_TRANSLATION,
      {
        configurationType: XillioProviderType.WORLD_TRANSLATION,
      },
    ],
  ]);

  public static getAll(): XillioProvider[] {
    return Array.from(XillioProviders.providers.values());
  }
}

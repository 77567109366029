import React, { ChangeEvent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, Props as FieldProps } from './Field';

export interface Props extends FieldProps {
  type?: 'email' | 'password' | 'text';
  value: string;
  onChange: (value: string) => void;
  validate?: (event: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  disabled?: boolean;
  maxLength?: number;
}

export const TextField: React.FC<Props> = ({
  onChange,
  leftIcon: icon,
  name,
  validate,
  type,
  required,
  value,
  errors,
  i18nKey,
  disabled,
  maxLength,
}): ReactElement => {
  const [t] = useTranslation('');
  return (
    <Field i18nKey={i18nKey} leftIcon={icon} name={name} errors={errors} required={required}>
      <input
        maxLength={maxLength}
        className="input"
        id={`${name}-input`}
        name={name}
        type={type}
        placeholder={t([`${i18nKey}.placeholder`, `${i18nKey}.label`])}
        required={required}
        value={value}
        disabled={disabled}
        data-testid={i18nKey}
        onChange={(event): void => {
          if (validate) {
            validate(event);
          }
          if (maxLength && event.target.value.length > maxLength) {
            onChange(event.target.value.substring(0, maxLength));
          } else {
            onChange(event.target.value);
          }
        }}
      />
    </Field>
  );
};

import { TimeDuration } from 'common/enums/time/duration/duration';
import { QueryOptions } from 'react-query';

import { ApiHookErrorConfiguration } from './error/error';

export interface QueryConfiguration<QueryResult, QueryError> {
  query: QueryOptions<QueryResult, QueryError>;
  error: ApiHookErrorConfiguration;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const defaultQueryConfiguration: QueryOptions<any, any> = {
  cacheTime: TimeDuration.HOUR,
};

import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Translatable } from '../../../common/language';
import { LanguageInfo } from '../../../pages/Overview/TaskCreatePage/TaskCreatePage';

export const TotalAssets: React.FC<Translatable & { languageInfo: LanguageInfo<object>[] }> = ({
  i18nKey,
  languageInfo,
}): ReactElement => {
  const [t] = useTranslation();
  return (
    <div className="overview-line">
      {t(`${i18nKey}.totalAssets`)}:
      <span className="is-pulled-right metric" id="total-assets">
        {languageInfo.length}
      </span>
    </div>
  );
};

import { Api } from 'modules/api/api';
import { QueryConfiguration } from 'modules/api/locHub/entity/query/configuration/configuration';
import { useLocHubActiveRecordQuery, useLocHubDtoQuery } from 'modules/api/locHub/entity/query/detail/detail';
import { QueryResult } from 'modules/api/locHub/entity/query/query';

import { TaskId } from '../../task/id/id';
import { Task, TaskDto } from '../../task/task';

export const useTask = (
  id: TaskId | undefined,
  configuration: QueryConfiguration<Task | undefined, unknown> = { error: {}, query: {} },
): QueryResult<Task> => {
  return useLocHubActiveRecordQuery(Api.locHub.tasks, id, configuration);
};

export const useTaskDto = (
  id: TaskId | undefined,
  configuration: QueryConfiguration<TaskDto | undefined, unknown> = { error: {}, query: {} },
): QueryResult<TaskDto> => {
  return useLocHubDtoQuery(Api.locHub.tasks, id, configuration);
};

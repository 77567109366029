import { Future } from 'common/utils/Future';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, Props as FieldProps } from './Field';

export type Option<V> = { value: V; label: string } | V;

function valueOf<V>(option: Option<V>): V {
  return typeof option === 'object' && 'value' in option ? option.value : option;
}

/**
 * Using the 'value'/'label' version of the Option type will cause the value of the option to become the
 * key and the label of the option to be displayed without any translation.
 */
export interface Props<V> extends FieldProps {
  disabled?: boolean;
  value?: V;
  options: Option<V>[];
  onChange: (value: V) => Future<void>;
}

export function SelectField<V extends { toString(): string }>({
  leftIcon: icon,
  name,
  i18nKey,
  value,
  options,
  onChange,
  disabled,
  required,
}: Props<V>): ReactElement {
  const [t] = useTranslation();

  const prompt = t([`${i18nKey}.prompt`, '']);

  return (
    <Field i18nKey={i18nKey} leftIcon={icon} name={name} required={required}>
      <div className="select is-fullwidth">
        <select
          id={`${name}-input`}
          value={value !== undefined ? value.toString() : undefined}
          disabled={disabled}
          data-testid={i18nKey}
          onChange={(event): void => {
            // find the correct option key to pass to onChange
            const newValue = event.target.value;
            const match = options.map(valueOf).filter(val => val.toString() === newValue)[0];
            onChange(match);
          }}
          required={required}
        >
          <option className={'is-hidden'} value="" hidden>
            {prompt}
          </option>
          {options.map(
            (option): ReactElement => {
              const val = valueOf(option);
              const label =
                typeof option === 'object' && 'label' in option
                  ? option.label
                  : t(`${i18nKey}.options.${val.toString()}`);
              return (
                <option key={val.toString()} value={val.toString()}>
                  {label}
                </option>
              );
            },
          )}
        </select>
      </div>
    </Field>
  );
}

import { LocHubEntityDto } from 'modules/api/locHub/entity/entity';
import { QueryConfiguration } from 'modules/api/locHub/entity/query/configuration/configuration';
import { QueryResult, useLocHubQuery } from 'modules/api/locHub/entity/query/query';
import { LocHubApi } from 'modules/api/locHub/lochub';

export const useLocHubListAllQuery = <Entity extends LocHubEntityDto>(
  api: LocHubApi,
  configuration: QueryConfiguration<Entity[] | undefined, unknown> = { error: {}, query: {} },
): QueryResult<Entity[]> => {
  return useLocHubQuery<Entity[]>(
    [api.path],
    async () => {
      return api.getAll() as Promise<Entity[]>;
    },
    configuration,
  );
};

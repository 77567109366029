import { Api } from 'modules/api/api';
import { QueryConfiguration } from 'modules/api/locHub/entity/query/configuration/configuration';
import { useLocHubDtoQuery } from 'modules/api/locHub/entity/query/detail/detail';
import { QueryResult } from 'modules/api/locHub/entity/query/query';

import { AutomationDto } from '../../automation/automation';
import { AutomationId } from '../../automation/id/id';

export const useAutomationDto = (
  id: AutomationId | undefined,
  configuration: QueryConfiguration<AutomationDto | undefined, unknown> = { error: {}, query: {} },
): QueryResult<AutomationDto> => {
  return useLocHubDtoQuery(Api.locHub.automations, id, configuration);
};

import { CmsConfiguration } from 'common/interfaces/xillio';
import { Api } from 'modules/api/api';
import { QueryConfiguration } from 'modules/api/locHub/entity/query/configuration/configuration';
import { QueryResult, useLocHubQuery } from 'modules/api/locHub/entity/query/query';

export const useCmsConfigurations = (
  configuration: QueryConfiguration<CmsConfiguration[] | undefined, unknown> = { error: {}, query: {} },
): QueryResult<CmsConfiguration[]> => {
  return useLocHubQuery<CmsConfiguration[]>(
    [Api.locHub.xillioApi.entities.path],
    async () => {
      return Api.locHub.xillioApi.getConfigurations();
    },
    configuration,
  );
};

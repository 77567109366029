import { ConfirmButton } from 'components/ConfirmButton/ConfirmButton';
import { useMessage } from 'components/Message/MessageProvider';
import { Api } from 'modules/api/api';
import { JobId } from 'modules/api/locHub/jobs/job/id/id';
import { useJobTasks } from 'modules/api/locHub/tasks/query/listByJob/listByJob';
import { Task } from 'modules/api/locHub/tasks/task/task';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { JobEditPageI18n } from './I18n';

interface Props {
  jobId: JobId;
  actions: {
    archived?: () => void;
  };
}

export const JobArchivalButton: React.FC<Props> = ({ jobId, actions }): ReactElement => {
  const [t] = useTranslation();
  const message = useMessage();
  const jobTasks = useJobTasks(jobId);
  const namespace = `${JobEditPageI18n.configuration.namespace}.archiveButton`;
  /* Methods */
  const areAllTasksInTerminalState = (tasks: Task[]): boolean => {
    for (const task of tasks) {
      if (!task.isInTerminalState()) {
        return false;
      }
    }
    return true;
  };
  /* State */
  let disabled = true;
  if (jobTasks.success && areAllTasksInTerminalState(jobTasks.data)) {
    disabled = false;
  }
  /* Render */
  return (
    <ConfirmButton
      i18nKey={namespace}
      className="is-danger"
      onConfirm={async (): Promise<void> => {
        try {
          await Api.locHub.jobs.archive(jobId);
          if (actions.archived) {
            actions.archived();
          }
          message.success(t(`${namespace}.success`));
        } catch (error) {
          message.success(t(`${namespace}.error`));
        }
      }}
      loading={!jobTasks}
      disabled={disabled}
    />
  );
};

/* eslint-disable @typescript-eslint/camelcase */
import { ComputedStatus, TapiccStatus } from 'modules/api/locHub/tasks/task/status/status';

import {
  ActionButtonLocalization,
  AppLocalization,
  ButtonLocalization,
  LanguageSettingsLocalization,
  ResourceLocalization,
  ResourceTree,
  SelectFieldLocalization,
  TextFieldLocalization,
} from './AppLocalization';
import { dictionary } from './dictionary';

const fields: { [name: string]: TextFieldLocalization | SelectFieldLocalization } = {
  username: {
    label: {
      en: 'Username',
      nl: 'Gebruikersnaam',
    },
  },
  password: {
    label: {
      en: 'Password',
      nl: 'Wachtwoord',
    },
  },
  confirmPassword: {
    label: {
      en: 'Confirm password',
      nl: 'Herhaal wachtwoord',
    },
  },
  email: {
    label: {
      en: 'Email',
      nl: 'Email',
    },
  },
  name: {
    label: {
      en: 'Full Name',
      nl: 'Volledige Naam',
    },
  },
  language: {
    label: {
      en: 'Language',
      nl: 'Taal',
    },
    options: {
      en: {
        en: 'English',
        nl: 'Engels',
      },
      nl: {
        en: 'Dutch',
        nl: 'Nederlands',
      },
      none: {
        en: 'None',
        nl: 'Geen',
      },
    },
  },
};

const buttons: { [name: string]: ButtonLocalization } = {
  cancel: {
    text: {
      en: 'Cancel',
      nl: 'Annuleer',
    },
  },
  confirm: {
    text: {
      en: 'Confirm',
      nl: 'Bevestig',
    },
  },
  disable: {
    text: {
      en: 'Disable',
      nl: 'Blokkeer',
    },
  },
  enable: {
    text: {
      en: 'Re-enable',
      nl: 'Heractiveer',
    },
  },
  edit: {
    text: {
      en: 'Edit',
      nl: 'Bewerken',
    },
  },
  view: {
    text: {
      en: 'View',
      nl: 'Bekijk',
    },
  },
};

const confirm: ResourceLocalization = {
  en: 'Click to confirm',
  nl: 'Klik om te bevestigen',
};

const columns: { [name: string]: ResourceLocalization } = {
  projectName: {
    en: 'Project Name',
    nl: 'Project Naam',
  },
  jobName: {
    en: 'Job Name',
    nl: 'Opdracht Naam',
  },
  archived: {
    en: 'Archived',
    nl: 'Gearchiveerd',
  },
  createdAt: {
    en: 'Created on',
    nl: 'Gemaakt op',
  },
  createdBy: {
    en: 'Created by',
    nl: 'Gemaakt door',
  },
  updatedAt: {
    en: 'Last modified',
    nl: 'Laatst aangepast',
  },
  sourceLanguage: {
    en: 'Source',
    nl: 'Brontaal',
  },
  targetLanguage: {
    en: 'Target Language',
    nl: 'Doeltaal',
  },
  targetLanguages: {
    en: 'Target',
    nl: 'Doeltalen',
  },
  name: {
    en: 'Name',
    nl: 'Naam',
  },
  description: {
    en: 'Description',
    nl: 'Beschrijving',
  },
  phase: {
    en: 'Status',
    nl: 'Status',
  },
  assets: {
    en: 'Assets',
    nl: 'Assets',
  },
  jobState: {
    en: 'Status',
    nl: 'Status',
  },
  jobTitle: {
    en: 'Job',
    nl: 'Opdracht',
  },
};

const buttonActions: { [name: string]: ActionButtonLocalization } = {
  create: {
    text: {
      en: 'Create',
      nl: 'Aanmaken',
    },
    cancel: buttons.cancel.text,
  },
  save: {
    text: {
      en: 'Save',
      nl: 'Opslaan',
    },
    cancel: buttons.cancel.text,
  },
};

const languageSettings: LanguageSettingsLocalization = {
  sourceLanguageField: {
    label: {
      en: 'Source Language',
      nl: 'Brontaal',
    },
    prompt: {
      en: 'Select a default source language',
      nl: 'Selecteer een standaard brontaal',
    },
  },
  targetLanguagesField: {
    label: {
      en: 'Target Languages',
      nl: 'Doeltalen',
    },
    selectAll: {
      en: 'Select all',
      nl: 'Selecteer alles',
    },
    deselectAll: {
      en: 'Deselect all',
      nl: 'Deselecteer alles',
    },
    save: {
      en: 'Save',
      nl: 'Opslaan',
    },
    filter: {
      en: 'Filter',
      nl: 'Filter',
    },
    noOptions: {
      en: 'No target languages available',
      nl: 'Geen doeltalen beschikbaar',
    },
  },
};

const taskStatuses: { [K in TapiccStatus | ComputedStatus]: ResourceLocalization } = {
  pending: {
    en: 'In preparation',
    nl: 'In voorbereiding',
  },
  confirmed: {
    en: 'In translation',
    nl: 'In vertaling',
  },
  'download-in-progress': {
    en: 'Download in progress',
    nl: 'Download bezig',
  },
  'download-failed': {
    en: 'Download failed',
    nl: 'Download mislukt',
  },
  cancelled: {
    en: 'Cancelled',
    nl: 'Geannuleerd',
  },
  'in-progress': {
    en: 'In progress',
    nl: 'Bezig',
  },
  'completed-with-warnings': {
    en: 'Completed with warnings',
    nl: 'Voltooid met waarschuwingen',
  },
  rejected: {
    en: 'Rejected',
    nl: 'Afgekeurd',
  },
  failed: {
    en: 'Failed',
    nl: 'Mislukt',
  },
  paused: {
    en: 'Paused',
    nl: 'Onderbroken',
  },
  completed: {
    en: 'Translated',
    nl: 'Vertaald',
  },
  closed: {
    en: 'Delivered',
    nl: 'Afgeleverd',
  },
};

export const pages: AppLocalization = {
  Common: {
    LoadingPage: {
      title: {
        en: 'Loading',
        nl: 'Laden',
      },
      description: {
        en: 'The content is being loaded',
        nl: 'De inhoud wordt geladen',
      },
    },
    ErrorPage: {
      title: {
        en: 'Sorry, something went wrong',
        nl: 'Sorry, er ging iets mis',
      },
      description: {
        en: 'Please try again or contact your system administrator.',
        nl: 'Probeer het opnieuw of neem contact op met uw systeembeheerder.',
      },
      home: {
        en: 'Home',
        nl: 'Huis',
      },
    },
  },
  Overview: {
    Landing: {
      title: {
        en: 'WELCOME TO LOCHUB',
        nl: 'WELKOM OP LOCHUB',
      },
      description: {
        en: 'LocHub',
        nl: 'LocHub',
      },
      logoutButton: {
        text: {
          en: 'Log out',
          nl: 'Uitloggen',
        },
      },
      introductionMessage: {
        en:
          'Localization Hub helps Language Service Providers (LSPs), Translation departments and Technology Providers to ' +
          'easily interconnect all the different content sources involved in translation and localization processes.',
        nl:
          'Localization Hub zorgt er voor dat vertaalbureaus , vertaalafdelingen en technologie ' +
          'aanbieders verbinding kunnen maken met de verschillende content systemen betrokken bij hun vertalings proces',
      },
      helpMessage: {
        en: 'If you need any help you can go to our ',
        nl: 'Voor hulp raadpleeg onze ',
      },
      helpLink: {
        en: 'Help page',
        nl: 'Help-pagina',
      },
    },
    HomePage: {
      title: {
        en: 'LocHub',
        nl: 'LocHub',
      },
      description: {
        en: 'Welcome to LocHub',
        nl: 'Welkom op LocHub',
      },
      openJobsMetric: {
        en: 'Open Jobs',
        nl: 'Openstaande Opdrachten',
      },
      openProjectCountMetric: {
        en: 'Total No. of Projects',
        nl: 'Totaal aantal Projecten',
      },
      recentJobs: {
        en: 'Recent Jobs',
        nl: 'Recente Opdrachten',
      },
      emptyTable: {
        en: 'No jobs available',
        nl: 'Geen bestaande opdrachten',
      },
      projectName: columns.projectName,
      jobName: columns.jobName,
      createdAt: columns.createdAt,
      updatedAt: columns.updatedAt,
    },
    HelpPage: {
      title: {
        en: 'Help Page',
        nl: 'Help-pagina',
      },
      description: {
        en: 'LocHub help page',
        nl: 'LocHub help-pagina',
      },
    },
    LocHubManualPage: {
      title: {
        en: 'LocHub User Manual',
        nl: 'LocHub gebruikershandleiding',
      },
      description: {
        en: 'Information on the usage of LocHub',
        nl: 'Informatie over het gebruik van LocHub',
      },
    },
    LocHubSwaggerPage: {
      title: {
        en: 'LocHub API Swagger',
        nl: 'LocHub API Swagger',
      },
      description: {
        en: 'Swagger for internal LocHub API',
        nl: 'Swagger documentatie voor de interne LocHub API',
      },
    },
    TapiccManualPage: {
      title: {
        en: 'TAPICC Dev Manual',
        nl: 'TAPICC ontwikkelaars-handleiding',
      },
      description: {
        en: 'Dev document for TAPICC API',
        nl: 'Ontwikkelaars informatie voor de TAPICC API',
      },
    },
    Tapicc007SwaggerPage: {
      title: {
        en: 'TAPICC 0.0.7 API Swagger',
        nl: 'TAPICC 0.0.7 API Swagger',
      },
      description: {
        en: 'Swagger for TAPICC API version 0.0.7',
        nl: 'Swagger documentatie voor de TAPICC API versie 0.0.7',
      },
    },
    Tapicc100BetaSwaggerPage: {
      title: {
        en: 'TAPICC 1.0.0-beta API Swagger',
        nl: 'TAPICC 1.0.0-beta API Swagger',
      },
      description: {
        en: 'Swagger for TAPICC API version 1.0.0-beta',
        nl: 'Swagger documentatie voor de TAPICC API versie 1.0.0-beta',
      },
    },
    XillioReferencePage: {
      title: {
        en: 'Xillio API Reference',
        nl: 'Xillio API referentie',
      },
      description: {
        en: 'Xillio API Reference',
        nl: 'Xillio API referentie',
      },
    },
    JobCreatePage: {
      title: {
        en: 'Create Job',
        nl: 'Opdracht Aanmaken',
      },
      description: {
        en: 'Create a new job and add it to LocHub.',
        nl: 'Voeg een nieuwe opdracht toe aan LocHub.',
      },
      nameField: {
        label: {
          en: 'Job Name',
          nl: 'Opdracht Naam',
        },
      },
      projectField: {
        label: {
          en: 'Project',
          nl: 'Project',
        },
      },
      submitButton: buttonActions.create,
      languageSettings,
    },
    JobInfoPage: {
      title: {
        en: 'Job Info',
        nl: 'Opdracht Info',
      },
      description: {
        en: 'View the properties of a job.',
        nl: 'Bekijk de opdrachtinformatie',
      },
      nameProperty: {
        label: {
          en: 'Job name',
          nl: 'Opdracht naam',
        },
      },
      createdByAutomation: {
        en: 'This job has been created by',
        nl: 'Deze opdracht is gemaakt door',
      },
      doNotTouchItIfNotNecessary: {
        en: 'Please be cautious while performing changes to it.',
        nl: 'Wees voorzichtig bij het aanbrengen van wijzigingen.',
      },
      projectProperty: {
        label: {
          en: 'Project name',
          nl: 'Project naam',
        },
      },
      createdProperty: {
        label: {
          en: 'Created on',
          nl: 'Gemaakt op',
        },
      },
      modifiedProperty: {
        label: {
          en: 'Modified on',
          nl: 'Laatste aanpassing',
        },
      },
      sourceLanguageProperty: {
        label: {
          en: 'Source language',
          nl: 'Brontaal',
        },
      },
      targetLanguageProperty: {
        label: {
          en: 'Target language',
          nl: 'Doeltaal',
        },
      },
      errorNotFound: {
        en: 'Job with id {{jobId}} does not exist.',
        nl: 'De gekozen opdracht met id {{jobId}} bestaat niet.',
      },
      downloadError: {
        en: 'Could not download this asset.',
        nl: 'Kon de asset niet downloaden.',
      },
      sendToCMSNotFoundError: {
        en: 'The translation asset was not found.',
        nl: 'Kon de vertaalde asset niet vinden.',
      },
      sendToCMSUndefinedIdError: {
        en: 'The id of the translation asset is undefined.',
        nl: 'Het id van de vertaalde asset is ongedefinieerd.',
      },
      uploadToSourceSuccess: {
        en: 'The translation asset has been uploaded to the source.',
        nl: 'De vertaalde asset is geüpload naar de bron.',
      },
      uploadToSourceError: {
        en: 'Could not upload the translation asset to the source.',
        nl: 'Kon de vertaalde asset niet naar de bron uploaden.',
      },
      viewTaskButton: {
        text: {
          en: 'View Task',
          nl: 'Taak bekijken',
        },
      },
    },
    TaskInfoPage: {
      title: {
        en: 'Task Info',
        nl: 'Taak Info',
      },
      description: {
        en: 'View the properties of a task.',
        nl: 'Bekijk de eigenschappen van een taak.',
      },
      nameProperty: {
        label: {
          en: 'Task name',
          nl: 'Taak naam',
        },
      },
      statusProperty: {
        label: {
          en: 'Task status',
          nl: 'Taak toestand',
        },
      },
      createdProperty: {
        label: {
          en: 'Created on',
          nl: 'Gemaakt op',
        },
      },
      modifiedProperty: {
        label: {
          en: 'Modified on',
          nl: 'Laatste aanpassing',
        },
      },
      errorNotFound: {
        en: 'Task with id {{taskId}} does not exist.',
        nl: 'De gekozen taak met id {{taskId}} bestaat niet.',
      },
    },
    JobsInfoPageTaskTable: {
      title: {
        en: 'Task List',
        nl: 'Takenlijst',
      },
      description: {
        en: 'Listed tasks for the job.',
        nl: 'Taken behorend tot de opdracht.',
      },
      name: {
        en: 'Task name',
        nl: 'Taaknaam',
      },
      status: {
        ...taskStatuses,
        error: {
          en: 'Error',
          nl: 'Fout',
        },
      },
      originalAsset: {
        en: 'Original asset',
        nl: 'Originele asset',
      },
      targetLanguage: columns.targetLanguage,
      createdAt: columns.createdAt,
      updatedAt: columns.updatedAt,
      phase: columns.phase,
      createButton: {
        text: {
          en: 'Create Task',
          nl: 'Taak Aanmaken',
        },
      },
      markForTranslation: {
        en: 'Mark ready for translation',
        nl: 'Markeer als klaar voor vertaling',
      },
      markForTranslation_known: {
        en: 'Mark ready for {{translator}}',
        nl: 'Markeer als klaar voor {{translator}}',
      },
      uploadTranslation: {
        en: 'Upload (new) translation',
        nl: 'Upload (nieuwe) vertaling',
      },
      downloadOriginalAsset: {
        en: 'Download original assets',
        nl: 'Download originele middelen',
      },
      ...taskStatuses,
      publishToSource: {
        en: 'Publish to source',
        nl: 'Publiceer naar bron',
      },
      downloadError: {
        en: 'The asset could not be downloaded; {{message}}',
        nl: 'The asset kon niet worden gedownload; {{message}}',
      },
      actions: {
        deleteTasks: {
          title: {
            en: 'Delete',
            nl: 'Verwijder',
          },
          type: {
            en: 'Type',
            nl: 'Type',
          },
          name: {
            en: 'Name',
            nl: 'Naam',
          },
          cancel: {
            en: 'Cancel',
            nl: 'Annuleer',
          },
          confirmation: {
            en: 'Do you really want to delete following tasks?',
            nl: 'Wilt u de volgende taken echt verwijderen?',
          },
          description: {
            en: 'Delete {{count}} tasks',
            nl: 'Verwijder {{count}} taken',
          },
          success: {
            en: 'Successfully deleted the task.',
            nl: 'De taak is successvol verwijderd.',
          },
          success_plural: {
            en: 'Successfully deleted {{count}} tasks.',
            nl: 'De {{count}} taken zijn successvol verwijderd.',
          },
          failure: {
            en: 'Could not delete the task.',
            nl: 'Kon de taak niet verwijderen',
          },
          failure_plural: {
            en: 'Could not delete the {{count}} tasks.',
            nl: 'Kon de {{count}} taken niet verwijderen.',
          },
        },
        uploadTranslation: {
          success: {
            en: "Successfully uploaded the translation for the task ('{{task}}').",
            nl: "De vertaling voor de taak ('{{task}}') is succesvol geupload.",
          },
          failure: {
            en: "Could not upload the translation for this task ('{{task}}').",
            nl: "Kon de vertaling voor de taak ('{{task}}') niet uploaden.",
          },
          error: {
            en: 'Could not upload the translation for this task.',
            nl: 'Kon de vertaling voor deze taak niet uploaden.',
          },
        },
        markForTranslation: {
          title: {
            en: 'Request translation',
            nl: 'Vertaling aanvragen',
          },
          description: {
            en: 'Mark {{count}} tasks ready for translation',
            nl: 'Markeer {{count}} taken klaar voor vertaling',
          },
          success: {
            en: 'Successfully marked the task for translation.',
            nl: 'De taak is succesvol gemarkeerd voor vertaling.',
          },
          success_plural: {
            en: 'Successfully marked {{count}} tasks for translation.',
            nl: 'Alle taken ({{count}}) zijn succesvol gemarkeerd voor vertaling.',
          },
          failure: {
            en: 'Could not mark the task for translation.',
            nl: 'Kon de taak niet markeren voor vertaling.',
          },
          failure_plural: {
            en: 'Could not mark {{count}} tasks for translation.',
            nl: 'Kon {{count}} taken niet markeren voor vertaling.',
          },
          error: {
            en: 'Could not mark this task for translation.',
            nl: 'Kon deze taak niet markeren voor vertaling.',
          },
        },
        publishToSource: {
          title: {
            en: 'Deliver',
            nl: 'Leveren',
          },
          description: {
            en: 'Publish {{count}} tasks to source',
            nl: 'Publiceer {{count}} taken naar bron',
          },
          success: {
            en: 'Successfully published the task to the CMS.',
            nl: 'De taak is succesvol naar het CMS verstuurd.',
          },
          success_plural: {
            en: 'Successfully published {{count}} tasks to the CMS.',
            nl: 'Alle taken ({{count}}) zijn succesvol naar het CMS verstuurd.',
          },
          failure: {
            en: 'Could not publish the task to the CMS.',
            nl: 'Kon de taak niet versturen naar het CMS.',
          },
          failure_plural: {
            en: 'Could not publish {{count}} tasks to the CMS.',
            nl: 'Kon {{count}} taken niet versturen naar het CMS.',
          },
          error: {
            en: 'Could not publish this task to the CMS.',
            nl: 'Kon deze taak niet versturen naar het CMS.',
          },
        },
      },
    },
    TasksInfoPageInputTable: {
      title: {
        en: 'Input List',
        nl: 'Invoerlijst',
      },
      description: {
        en: 'Listed inputs for the task.',
        nl: 'Lijst met ingangen voor de taak.',
      },
      name: {
        en: 'Input name',
        nl: 'Voer naam in',
      },
      type: {
        en: 'Input type',
        nl: 'Invoertype',
      },
      sourceLanguage: {
        en: 'Source language',
        nl: 'Brontaal',
      },
      targetLanguage: {
        en: 'Target language',
        nl: 'Doeltaal',
      },
      delivered: {
        en: 'Delivered',
        nl: 'Geleverd',
      },
      downloadOriginalAsset: {
        en: 'Download original asset',
        nl: 'Download origineel item',
      },
      uploadTranslation: {
        en: 'Upload translation',
        nl: 'Vertaling uploaden',
      },
      actions: {
        uploadTranslation: {
          success: {
            en: "Successfully uploaded the translation for the input ('{{asset}}').",
            nl: "De vertaling voor de invoer ('{{asset}}') is succesvol geupload.",
          },
          failure: {
            en: "Could not upload the translation for this input ('{{asset}}').",
            nl: "Kon de vertaling voor de invoer ('{{asset}}') niet uploaden.",
          },
          error: {
            en: 'Could not upload the translation for this input.',
            nl: 'Kon de vertaling voor deze invoer niet uploaden.',
          },
        },
      },
    },
    TaskCreatePage: {
      title: {
        en: 'Create Task',
        nl: 'Taak Aanmaken',
      },
      description: {
        en: 'Create a new task and add it to LocHub.',
        nl: 'Voeg een nieuwe taak toe aan LocHub.',
      },
      selectSourceStep: {
        title: {
          en: 'Select a method',
          nl: 'Kies een methode',
        },
        description: {
          en: 'Select the method to access your assets.',
          nl: 'Kies de methode waarop u uw assets wilt selecteren.',
        },
      },
      existingAssetStep: {
        title: {
          en: 'List of assets',
          nl: 'Lijst van assets',
        },
        description: {
          en: 'Select the assets which need to be translated.',
          nl: 'Selecteer de assets die moeten worden vertaald.',
        },
        assetTable: {
          name: columns.name,
          description: columns.description,
          sourceLanguage: columns.sourceLanguage,
          createdAt: columns.createdAt,
        },
      },
      entityBrowser: {
        title: {
          en: 'Select assets',
          nl: 'Selecteer assets',
        },
        description: {
          en: 'Select your assets by browsing through your CMS systems.',
          nl: 'Selecter uw assets door bestanden uit uw CMS te selecteren.',
        },
        table: {
          name: {
            en: 'File name',
            nl: 'Bestands naam',
          },
          sourceLanguage: {
            en: 'Source language',
            nl: 'Brontaal',
          },
          createdAt: columns.createdAt,
          updatedAt: columns.updatedAt,
          fileSize: {
            en: 'File size',
            nl: 'Bestands grootte',
          },
          contentType: {
            en: 'Content type',
            nl: 'Bestands type',
          },
        },
      },
      assetSettings: {
        title: {
          en: 'Select target languages',
          nl: 'Selecteer doel talen',
        },
        description: {
          en: 'For every asset you can select to which target languages is should be translated.',
          nl: 'Kies voor elke asset naar welke doel taal het vertaald moet worden.',
        },
      },
      summary: {
        title: {
          en: 'Tasks to be created',
          nl: 'Taken om aan te maken',
        },
        description: {
          en: 'These are all the tasks that will be created',
          nl: 'Dit zijn de taken die op het punt staan om gemaakt te worden',
        },
        assetName: {
          en: 'Asset Name',
          nl: 'Asset Naam',
        },
        languages: {
          en: 'Target Languages',
          nl: 'Doel Talen',
        },
        totalAssets: {
          en: 'Total amount of assets to be translated',
          nl: 'Totaal aantal assets om te vertalen',
        },
        totalTasks: {
          en: 'Total amount of tasks to be created',
          nl: 'Totaal aantal taken om aan te maken',
        },
        totalSourceLanguages: {
          en: 'Selected source languages',
          nl: 'Geselecteerde bron talen',
        },
        totalTargetLanguages: {
          en: 'Selected target languages',
          nl: 'Geselecteerde doel talen',
        },
        status: {
          en: 'Status',
          nl: 'Status',
        },
        sourceLanguage: columns.sourceLanguage,
        targetLanguages: columns.targetLanguages,
        createdAt: columns.createdAt,
        updatedAt: columns.updatedAt,
        submit: {
          text: {
            en: 'Submit',
            nl: 'Versturen',
          },
        },
        // Error messages
        success: {
          en: 'Successfully created a new task!',
          nl: 'Succesvol een nieuwe taak gemaakt!',
        },
        // Needed for i18n plurals
        // eslint-disable-next-line @typescript-eslint/camelcase
        success_plural: {
          en: 'Successfully created {{count}} tasks!',
          nl: '{{count}} taken zijn sucessvol aangemaakt!',
        },
        noProjectID: {
          en: 'No project info was found, please make sure your session is up-to-date.',
          nl: 'Er was geen project info gevonden, zorg er voor dat uw sessie up-to-date is.',
        },
        noJobID: {
          en: 'No job info was found, please make sure your session is up-to-date.',
          nl: 'Er was geen opdracht info gevonden, zorg er voor dat uw sessie up-to-date is.',
        },
        noAssetInfo: {
          en: 'Something went wrong while creating all assets, please try again or contact your administrator.',
          nl:
            'Er is iets misgegaan met het aanmaken van assets, probeer het nog een keer of neem contact op met uw beheerder.',
        },
        unknown: {
          en: 'Something went wrong which we could not discern, please try again or contact your administrator.',
          nl: 'Er is iets onverwacht misgegaan, probeer het nog een keer of neem contact op met uw beheerder.',
        },
        error: {
          unknown: {
            en: 'Something went wrong',
            nl: 'Er is iets fout gegaan',
          },
        },
        tooltips: {
          'non-original': {
            en: "Cannot translate non-original asset '{{reference}}': it is a translation of '{{original}}'",
            nl: 'Kan het onoriginele asset {{reference}} niet vertalen: het is een vertaling van {{original}}',
          },
          notMatching: {
            en:
              "The language info from the CMS ('{{tag}}') and the selected default language ('{{default}}') are not the same.",
            nl: "De taal-info uit het CMS ('{{tag}}') komt niet overeen met de standaard vertaling ('{{default}}').",
          },
          unknownTag: {
            en: "The language info from the CMS had no matching language tag in LocHub. (found: '{{tag}}')",
            nl: "De taal-info uit het CMS had geen overeenkomende taal in LocHub. (gevonden: '{{tag}}')",
          },
          noInfo: {
            en: 'No language information was found.',
            nl: 'Geen taal-info gevonden.',
          },
          automatic: {
            en: 'Automatically set the language information.',
            nl: 'Deze taal-info is automatisch ingevuld.',
          },
          undefined: {
            en: 'Missing',
            nl: 'Ontbrekend',
          },
          edited: {
            en: "Edited. (originally: '{{tag}}')",
            nl: "Bewerkt. (origineel: '{{tag}}')",
          },
        },
      },
      next: {
        en: 'Next',
        nl: 'Volgende',
      },
      sourceCMS: {
        title: {
          en: 'CMS',
          nl: 'CMS',
        },
        description: {
          en: 'Access your CMS service',
          nl: 'Verbind met uw CMS',
        },
      },
      sourceAsset: {
        title: {
          en: 'Existing asset',
          nl: 'Bestaande assets',
        },
        description: {
          en: 'Select from existing assets',
          nl: 'Kies uit bestaande assets',
        },
      },
      sourceFiles: {
        title: {
          en: 'Upload files*',
          nl: 'Upload bestanden*',
        },
        description: {
          en: 'Upload files from your device',
          nl: 'Upload bestanden vanaf uw apparaat',
        },
      },
      notAvailable: {
        en: 'will be available soon',
        nl: 'binnenkort beschikbaar',
      },
    },
    JobsListPage: {
      title: {
        en: 'Jobs',
        nl: 'Opdrachten',
      },
      description: {
        en: 'LocHub administration',
        nl: 'LocHub beheer',
      },
      ...columns,
      project: {
        en: 'Project',
        nl: 'Project',
      },
      name: {
        en: 'Job name',
        nl: 'Opdracht naam',
      },
      automation: {
        en: 'Automation',
        nl: 'Automatisering',
      },
      createButton: {
        text: {
          en: 'Create Job',
          nl: 'Opdracht Aanmaken',
        },
      },
      toggleButton: {
        text: {
          en: 'Include archived jobs',
          nl: 'Toon ook gearchiveerde opdrachten',
        },
      },
      archived: {
        text: {
          en: 'archived',
          nl: 'gearchiveerd',
        },
      },
      viewButton: {
        text: {
          en: 'View Job',
          nl: 'Opdracht Bekijken',
        },
      },
      editButton: {
        text: dictionary.editJob,
      },
      progressBar: {
        text: {
          en: 'Undefined',
          nl: 'Ongedefinieerd',
        },
        popup: {
          en: 'No tasks assigned',
          nl: 'Geen taken',
        },
      },
      jobStatus: {
        completed: {
          en: 'Delivered',
          nl: 'Afgeleverd',
        },
        translated: {
          en: 'Translated',
          nl: 'Vertaald',
        },
        new: {
          en: 'In preparation',
          nl: 'In voorbereiding',
        },
        inProgress: {
          en: 'In translation',
          nl: 'In vertaling',
        },
        cancelled: {
          en: 'Cancelled',
          nl: 'Geannuleerd',
        },
      },
    },
    ProjectCreatePage: {
      title: {
        en: 'Create Project',
        nl: 'Project Aanmaken',
      },
      description: {
        en: 'Create a new project and add it to LocHub.',
        nl: 'Voeg een nieuw project toe aan LocHub.',
      },
      nameField: {
        label: {
          en: 'Project Name',
          nl: 'Project Naam',
        },
      },
      descriptionField: {
        label: {
          en: 'Description',
          nl: 'Beschrijving',
        },
      },
      defaultTargetLanguages: {
        label: {
          en: 'Default Target Languages',
          nl: 'Standaard Vertalingen',
        },
      },
      submitButton: buttonActions.create,
      languageSettings,
    },
    ProjectEditPage: {
      title: {
        en: 'Edit Project',
        nl: 'Project Beheren',
      },
      description: {
        en: 'Edit the properties of a project.',
        nl: 'Projectinformatie aanpassen',
      },
      nameField: {
        label: {
          en: 'Project Name',
          nl: 'Project Naam',
        },
      },
      descriptionField: {
        label: {
          en: 'Description',
          nl: 'Beschrijving',
        },
      },
      defaultTargetLanguages: {
        label: {
          en: 'Default Target Languages',
          nl: 'Standaard Vertalingen',
        },
      },
      deleteWarningJobs: {
        en: 'You can not delete this project. It contains jobs.',
        nl: 'U kunt dit project niet verwijderen. Het bevat opdrachten.',
      },
      deleteWarningAssets: {
        en: 'You can not delete this project. It contains assets.',
        nl: 'U kunt dit project niet verwijderen. Het bevat assets.',
      },
      deleteWarningJobsAndAssets: {
        en: 'You can not delete this project. It contains jobs and assets.',
        nl: 'U kunt dit project niet verwijderen. Het bevat opdrachten en assets.',
      },
      errorNotFound: {
        en: 'Project with id {{projectId}} does not exist.',
        nl: 'Het gekozen project met id {{projectId}} bestaat niet.',
      },
      submitButton: buttonActions.save,
      languageSettings,
    },
    ProjectEditPageDangerZone: {
      title: {
        en: 'Danger Zone',
        nl: 'Gevarenzone',
      },
      description: {
        en: 'These operations are potentially destructive.',
        nl: 'Deze operaties kunnen onomkeerbare gevolgen hebben.',
      },
      archiveButton: {
        text: {
          en: 'Archive Project',
          nl: 'Archiveer Project',
        },
        confirm: confirm,
      },
      deleteButton: {
        text: {
          en: 'Delete Project',
          nl: 'Verwijder Project',
        },
        confirm: confirm,
      },
    },
    ProjectListPage: {
      ...columns,
      title: {
        en: 'Projects',
        nl: 'Projecten',
      },
      description: {
        en: 'Manage all your ongoing translation projects',
        nl: 'Beheer hier al uw vertaalprojecten',
      },
      defaultTapicc: {
        en: 'TAPICC 0.0.7',
        nl: 'TAPICC 0.0.7',
      },
      createButton: {
        text: {
          en: 'Create Project',
          nl: 'Project Aanmaken',
        },
      },
      toggleButton: {
        text: {
          en: 'Include archived projects',
          nl: 'Toon ook gearchiveerde projecten',
        },
      },
      editButton: buttons.edit,
      name: {
        en: 'Project',
        nl: 'Project',
      },
      features: {
        en: 'Features',
        nl: 'Kenmerken',
      },
      archived: {
        text: {
          en: 'archived',
          nl: 'gearchiveerd',
        },
      },
      automation: {
        en: 'Automation',
        nl: 'Automatisering',
      },
    },
  },
  Administration: {
    AdminPage: {
      title: {
        en: 'Admin',
        nl: 'Beheer',
      },
      description: {
        en: 'LocHub administration',
        nl: 'LocHub beheer',
      },
    },
    ConnectorsPage: {
      title: {
        en: 'Connectors',
        nl: 'Connectoren',
      },
      description: {
        en: 'Available Connectors',
        nl: 'Beschikbare Connectoren',
      },
      createButton: {
        en: 'Create connection',
        nl: 'Maak connectie',
      },
    },
    ProvidersPage: {
      title: {
        en: 'Providers',
        nl: 'Aanbieders',
      },
      description: {
        en: 'Available Language Service Providers',
        nl: 'Beschikbare Aanbieders van Taaldiensten',
      },
    },
    LanguageEditPage: {
      title: {
        en: 'Edit Language',
        nl: 'Taal Beheren',
      },
      description: {
        en: 'Edit the properties of a language.',
        nl: 'Taalinformatie aanpassen.',
      },
      nameField: {
        label: {
          en: 'Language Name',
          nl: 'Taal Naam',
        },
      },
      descriptionField: {
        label: {
          en: 'Description',
          nl: 'Beschrijving',
        },
      },
      codeField: {
        label: {
          en: 'Code',
          nl: 'Code',
        },
      },
      errorNotFound: {
        en: 'Language with id {{languageId}} does not exist.',
        nl: 'De gekozen taal met id {{languageId}} bestaat niet.',
      },
      submitButton: buttonActions.save,
      languageSettings,
    },
    LanguageListPage: {
      title: {
        en: 'Languages',
        nl: 'Talen',
      },
      description: {
        en: 'Description',
        nl: 'Omschrijving',
      },
      createButton: {
        text: {
          en: 'Add Language',
          nl: 'Taal Toevoegen',
        },
      },
      editButton: buttons.edit,
      name: {
        en: 'Language',
        nl: 'Taal',
      },
      code: {
        en: 'Code',
        nl: 'Code',
      },
      createdAt: {
        en: 'Created on',
        nl: 'Gemaakt op',
      },
      updatedAt: {
        en: 'Last modified',
        nl: 'Laatst aangepast',
      },
    },
    AutomationListPage: {
      title: {
        en: 'Automations',
        nl: 'Automatiseringen',
      },
      description: {
        en: 'Project automation configurations',
        nl: 'Configuraties van projectautomatisering',
      },
      createButton: {
        text: {
          en: 'Create Automation',
          nl: 'Maak automatisering',
        },
      },
      project: {
        en: 'Project',
        nl: 'Project',
      },
      name: {
        en: 'Name',
        nl: 'Naam',
      },
      active: {
        en: 'Active',
        nl: 'Actief',
      },
      createdAt: {
        en: 'Created on',
        nl: 'Gemaakt op',
      },
      archived: {
        en: 'archived',
        nl: 'gearchiveerd',
      },
      lastRunTime: {
        en: 'Last successful run time',
        nl: 'Laatste succesvolle uitvoering',
      },
      lastRunStatus: {
        en: 'Last run Status',
        nl: 'Status laatste uitvoering',
      },
      success: {
        en: 'Success',
        nl: 'Succes',
      },
      error: {
        en: 'Error',
        nl: 'Fout',
      },
      pending: {
        en: 'Pending',
        nl: 'In afwachting',
      },
      toggleButton: {
        en: 'Include archived automations',
        nl: 'Toon ook gearchiveerde automatiseringen',
      },

      viewButton: buttons.view,
      editButton: buttons.edit,
    },
    AutomationInfoPage: {
      title: {
        en: 'Automation Info',
        nl: 'Automatisering informatie',
      },
      description: {
        en: 'View the properties of the Project Automation',
        nl: 'Bekijk de eigenschappen van de projectautomatisering',
      },
      yes: {
        en: 'Yes',
        nl: 'Ja',
      },
      no: {
        en: 'No',
        nl: 'Nee',
      },
      automation: {
        project: {
          label: {
            en: 'Associated project name',
            nl: 'Bijbehorende projectnaam',
          },
        },
        name: {
          label: {
            en: 'Name',
            nl: 'Naam',
          },
        },
        description: {
          label: {
            en: 'Description',
            nl: 'Omschrijving',
          },
        },
        active: {
          label: {
            en: 'Active',
            nl: 'Actief',
          },
        },
        period: {
          label: {
            en: 'Time between runs',
            nl: 'Tijd tussen uitvoeringen',
          },
        },
        delay: {
          label: {
            en: 'New files delay',
            nl: 'Vertraging voor nieuwe bestanden',
          },
          none: {
            en: 'None',
            nl: 'Geen',
          },
        },
        autoMarkForTranslation: {
          label: {
            en: 'Automatically marking for translation',
            nl: 'Automatisch markeren voor vertaling',
          },
        },
        autoAcceptTranslation: {
          label: {
            en: 'Automatically accepting deliverables',
            nl: 'Automatisch vertaling accepteren',
          },
        },
        engineConfigurationName: {
          label: {
            en: 'CMS',
            nl: 'CMS',
          },
        },
        paths: {
          label: {
            en: 'Associated paths',
            nl: 'Geassocieerde paden',
          },
        },
        recursiveCrawl: {
          label: {
            en: 'Include subfolders',
            nl: 'Submappen meetellen',
          },
        },
        sourceLanguageFilter: {
          label: {
            en: 'Accepted source languages',
            nl: 'Geaccepteerde brontalen',
          },
        },
        targetLanguages: {
          label: {
            en: 'Target languages',
            nl: 'Doeltalen',
          },
        },
      },
      errorNotFound: {
        en: 'Automation with id {{automationId}} does not exist.',
        nl: 'Automatisering met id {{automationId}} bestaat niet.',
      },
    },
    AutomationEditPage: {
      title: {
        en: 'Automation Edit',
        nl: 'Automatisering bewerken',
      },
      description: {
        en: 'Edit the properties of the Project Automation',
        nl: 'Bewerk de eigenschappen van de Project Automation',
      },
      errorNotFound: {
        en: 'Automation with id {{automationId}} does not exist.',
        nl: 'Automatisering met id {{automationId}} bestaat niet.',
      },
      archivedEntityMayNotBeEdited: {
        en: 'Automation with id {{automationId}} is archived, therefore it may not be edited.',
        nl: 'Automatisering met id {{automationId}} wordt gearchiveerd en mag daarom niet worden bewerkt',
      },
    },
    LanguageCreatePage: {
      title: {
        en: 'Register New Language',
        nl: 'Registreer Nieuwe Taal',
      },
      description: {
        en: 'Create a new language and add it to the list',
        nl: 'Voeg een nieuw taal toe aan de lijst',
      },
      nameField: {
        label: {
          en: 'Language Name',
          nl: 'Taal Naam',
        },
      },
      codeField: {
        label: {
          en: 'Code',
          nl: 'Code',
        },
      },
      descriptionField: {
        label: {
          en: 'Description',
          nl: 'Beschrijving',
        },
      },
      submitButton: buttonActions.create,
      languageSettings,
    },
    UserListPage: {
      title: {
        en: 'Users',
        nl: 'Gebruikers',
      },
      description: {
        en: 'Create, manage, and disable user accounts',
        nl: 'Maak, beheer en blokkeer gebruikers',
      },
      you: {
        en: 'You',
        nl: 'Uzelf',
      },
      username: fields.username.label,
      name: fields.name.label,
      email: fields.email.label,
      disabled: {
        en: 'Disabled',
        nl: 'Geblokkeerd',
      },
      editButton: buttons.edit,
      createButton: {
        text: {
          en: 'Create User',
          nl: 'Gebruiker Aanmaken',
        },
      },
    },
    UserCreatePage: {
      title: {
        en: 'Add User',
        nl: 'Nieuwe Gebruiker',
      },
      description: {
        en: 'Create a new user and add them to LocHub',
        nl: 'Voeg een nieuwe gebruiker toe',
      },
      usernameField: fields.username,
      emailField: fields.email,
      nameField: fields.name,
      languageField: fields.language,
      submitButton: buttonActions.create,
    },
    UserEditPage: {
      title: {
        en: 'Edit User',
        nl: 'Gebruiker Aanpassen',
      },
      description: {
        en: 'Edit user properties and permissions',
        nl: 'Gebruikersinformatie en rechten aanpassen',
      },
      errorNotFound: {
        en: 'User with id {{userId}} does not exist.',
        nl: 'De gekozen gebruiker met id {{userId}} bestaat niet.',
      },
      nameField: fields.name,
      emailField: fields.email,
      languageField: fields.language,
      submitButton: buttonActions.save,
    },
    UserEditPageDangerZone: {
      title: {
        en: 'Danger Zone',
        nl: 'Gevarenzone',
      },
      description: {
        en: 'These operations are potentially destructive',
        nl: 'Deze operaties kunnen onomkeerbare gevolgen hebben',
      },
      disableButton: {
        ...buttons.disable,
        confirm: confirm,
      },
      enableButton: buttons.enable,
    },
    AutomationEditPageDangerZone: {
      title: {
        en: 'Danger Zone',
        nl: 'Gevarenzone',
      },
      description: {
        en: 'These operations are potentially destructive',
        nl: 'Deze operaties kunnen onomkeerbare gevolgen hebben',
      },
      disableButton: {
        ...buttons.disable,
        confirm: confirm,
      },
      deleteButton: {
        text: {
          en: 'Delete',
          nl: 'Verwijderen',
        },
        success: {
          en: 'Project Automation archived successfully.',
          nl: 'Project Automation succesvol gearchiveerd.',
        },
        confirm: confirm,
      },
      archiveButton: {
        text: {
          en: 'Archive',
          nl: 'Archief',
        },
        success: {
          en: 'Project Automation archived successfully.',
          nl: 'Project Automation succesvol gearchiveerd.',
        },
        confirm: confirm,
      },
      enableButton: buttons.enable,
    },
  },
  Authentication: {
    LoginPage: {
      title: {
        en: 'Log in',
        nl: 'Inloggen',
      },
      description: {
        en: 'Log in',
        nl: 'Inloggen',
      },
      usernameField: fields.username,
      passwordField: fields.password,
      loginButton: {
        text: {
          en: 'Log in',
          nl: 'Log in',
        },
      },
      forgotPasswordButton: {
        text: {
          en: 'Forgot password?',
          nl: 'Wachtwoord vergeten?',
        },
      },
      badCredentialsError: {
        en: 'No account with this password and username combination was found.',
        nl: 'Er was geen account met deze gebruikersnaam en wachtwoord gevonden.',
      },
      backLoginPage: {
        text: {
          en: 'Go back to Log in',
          nl: 'Ga terug naar Log in',
        },
      },
    },
    ForgotPasswordPage: {
      title: {
        en: 'Password recovery',
        nl: 'Wachtwoord herstellen',
      },
      description: {
        en: 'In order to send you a link to reset your password, please fill in your email address.',
        nl: 'Om uw wachtwoord te kunnen herstellen moet u hieronder uw email adres invullen.',
      },
      emailField: fields.email,
      resetButton: {
        text: {
          en: 'Reset password',
          nl: 'Herstel wachtwoord',
        },
      },
      cancelButton: buttons.cancel,
    },
    ForgotPasswordPageConfirmation: {
      title: {
        en: 'Password reset link sent',
        nl: 'Wachtwoord-herstel link verzonden',
      },
      description: {
        en: `<p>An email has been sent to the specified email <strong>{{email}}</strong><br />if it is associated with a user. In that email, you will find a link which can be used to reset your password.</p>
<p>It might take a couple of minutes before you will receive this email. If it takes too long you could also make sure the email did not end up in your spam folder.</p>`,
        nl: `<p>Er is een email verstuurd naar de opgegeven email <strong>{{email}}</strong><br/>als het is gekoppeld aan een gebruiker. In dit email, vind u een link waarmee u uw wachtwoord kunt resetten.</p>
<p>Het kan enkele minuten duren voordat u dit email ontvangt. Als het te lang duurt dan zou het kunnen zijn dat de email bij uw spam is beland.</p>`,
      },
    },
    ChangePasswordPage: {
      title: {
        en: 'New password',
        nl: 'Nieuw wachtwoord',
      },
      description: {
        en: 'Please enter your new password in the input fields below to set your new password.',
        nl: 'Voer uw nieuwe wachtwoord in de onderstaande velden in om hem opnieuw in te stellen.',
      },
      confirmButton: buttons.confirm,
      cancelButton: buttons.cancel,
      passwordField: fields.password,
      confirmField: fields.confirmPassword,
      passwordMatchError: {
        en: 'Passwords do not match.',
        nl: 'Wachtwoorden komen niet overeen.',
      },
      changeFailedError: {
        en: 'Could not change password, something went wrong. Please try again later.',
        nl: 'Het wachtwoord kon niet veranderd worden, er is iets fout gegaan. Probeer later nog een keer.',
      },
    },
    ChangePasswordPageConfirmation: {
      title: {
        en: 'Password has been updated!',
        nl: 'Wachtwoord is veranderd!',
      },
      description: {
        en: 'Your password has been updated. You can now use this password to login.',
        nl: 'Uw wachtwoord is veranderd. Met dit wachtwoord kunt u nu inloggen.',
      },
      loginLink: {
        text: {
          en: 'Go to the login page.',
          nl: 'Ga naar de login pagina.',
        },
      },
    },
  },
  Errors: {
    NotFoundPage: {
      title: {
        en: 'Lost in translations?',
        nl: 'Verdwaald in de vertaling?',
      },
      description: {
        en: 'The page you were looking for could not be found.',
        nl: 'The pagina die u zoekt is niet gevonden.',
      },
    },
  },
};

export const errors: ResourceTree = {
  NotBlank: {
    name: {
      nl: 'De naam mag niet leeg zijn',
      en: 'The name must not be blank',
    },
  },
  IETFCode: {
    en: 'This not a valid language code.',
    nl: 'Dit is geen geldige taalcode.',
  },
  Pattern: {
    username: {
      en: 'The username may only contain lowercase letters, numbers, dashes, and underscores.',
      nl: 'The gebruikersnaam mag enkel kleine letters, getallen of streepjes bevatten.',
    },
    name: {
      en: 'The name must not be blank.',
      nl: 'De naam mag niet leeg zijn.',
    },
  },
  unknown: {
    en: 'An unknown error has occurred. Please contact your system administrator.',
    nl: 'Er is iets misgegaan. Neem contact op met uw systeembeheerder.',
  },
  Unique: {
    username: {
      en: 'A different user with the same username already exists.',
      nl: 'Er bestaat al een andere gebruiker met deze gebruikersnaam.',
    },
    email: {
      en: 'A different user with the same email address already exists.',
      nl: 'Er bestaat al een andere gebruiker met dit email adres.',
    },
    languageCode: {
      en: 'A language already exists with this code.',
      nl: 'Er bestaat al een andere taal met deze code.',
    },
    languageName: {
      en: 'A language already exists with this name.',
      nl: 'Er bestaat al een andere taal met deze naam.',
    },
  },
  Email: {
    email: {
      en: 'Please enter a valid email address.',
      nl: 'Geef een geldig email adres.',
    },
  },
  Api: {
    en:
      'Unknown issue has occured during the communication with the server. Please check your connection and retry or contact your system administrator.',
    nl:
      'Er is een onbekend probleem opgetreden tijdens de communicatie met de server. Controleer uw verbinding en probeer het opnieuw of neem contact op met uw systeembeheerder.',
  },
  Missing: {
    createJobRequest: {
      projectId: {
        en: 'Please select a project.',
        nl: 'Selecteer alstublieft een project.',
      },
      target: {
        en: 'Please select a target language.',
        nl: 'Selecteer alstublieft een doeltaal.',
      },
    },
  },
  Invalid: {
    email: {
      en: 'Please enter a valid email address.',
      nl: 'Geef een geldig email adres.',
    },
    username: {
      en: 'Username may only contain small letters, numbers and theese special characters: . - _ ',
      nl: 'De gebruikersnaam mag alleen kleine letters, cijfers en deze speciale tekens bevatten: . - _',
    },
    languageCode: {
      en: 'Please enter a valid IETF language code.',
      nl: 'Voer een geldige IETF-taalcode in.',
    },
  },
  Size: {
    en: 'The value of this field may not be longer than 256 characters.',
    nl: 'Dit veld mag niet langer dan 256 karakters zijn.',
  },
};

export const notifications: ResourceTree = {
  close: {
    en: 'Close',
    nl: 'Sluiten',
  },
  copyErrorId: {
    en: 'Copy error id',
    nl: 'Fout-id kopiëren',
  },
  errorIdInClipboard: {
    en: 'Error id copied to your clipboard successfully.',
    nl: 'Fout-ID met succes naar uw klembord gekopieerd.',
  },
  type: {
    normal: {
      en: 'Notification',
      nl: 'Mededeling',
    },
    success: {
      en: 'Success',
      nl: 'Succes',
    },
    error: {
      en: 'Error',
      nl: 'Fout',
    },
    info: {
      en: 'Information',
      nl: 'Informatie',
    },
    warning: {
      en: 'Warning',
      nl: 'Waarschuwing',
    },
    loading: {
      en: 'Loading',
      nl: 'Bezig met laden',
    },
  },
};

export const locale: ResourceTree = {
  date: {
    nl: '{{date, LL}}',
    en: '{{date, LL}}',
  },
  fromNow: {
    nl: '{{date, fromNow}}',
    en: '{{date, fromNow}}',
  },
  hours: {
    nl: '{{duration, hours}}',
    en: '{{duration, hours}}',
  },
  minutes: {
    nl: '{{duration, minutes}}',
    en: '{{duration, minutes}}',
  },
};

export const actions: ResourceTree = {
  select: {
    en: 'Select',
    nl: 'Selecteer',
  },
};

import { Extended } from 'common/types/extended/extended';
import { JobAutomationTag } from 'components/JobAutomationTag/JobAutomationTag';
import { JobDto } from 'modules/api/locHub/jobs/job/job';
import { useLanguages } from 'modules/api/locHub/languages/query/list/list';
import { ProjectDto } from 'modules/api/locHub/projects/project/project';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Translatable } from '../../../../common/language';
import { HtmlProps } from '../../../../common/utils/HTMLProps';
import { Box } from '../../../../components/Box/Box';
import { Columns } from '../../../../components/Columns/Columns';
import { Property } from '../../../../components/Property/Property';

export interface Props extends HtmlProps, Translatable {
  job: Extended<JobDto, { project: ProjectDto }>;
}

export const Metadata: React.FC<Props> = ({ i18nKey, className, id, job }): ReactElement | null => {
  const [t] = useTranslation();
  const languages = useLanguages();

  const getLanguageNameByCode = (code: string): string => {
    if (!languages.success) {
      return '';
    }
    return (
      languages.data.find(language => {
        return language.code === code;
      })?.name || ''
    );
  };

  return (
    <Box i18nKey={i18nKey} className={className} id={id}>
      {job ? (
        <Columns>
          {job.automationId ? (
            <div className="column is-full">
              <div className="notification is-info">
                <div className="columns">
                  <div className="column is-1 is-hidden-mobile">
                    <p className="bd-notification is-primary icon is-large">
                      <i className="fas fa-exclamation-triangle fa-2x" />
                    </p>
                  </div>
                  <div className="column is-10">
                    <p className="bd-notification has-text-centered">
                      <strong style={{ paddingRight: '8px' }}>{t(`${i18nKey}.createdByAutomation`)}</strong>
                      <JobAutomationTag automationId={job.automationId} />
                    </p>
                    <p className="bd-notification has-text-centered">{t(`${i18nKey}.doNotTouchItIfNotNecessary`)}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="column is-full is-hidden"></div>
          )}
          <Property i18nKey={`${i18nKey}.nameProperty`} icon="fa-tag" title={job.name}>
            {job.name}
          </Property>
          <Property i18nKey={`${i18nKey}.projectProperty`} icon="fa-list-alt" title={job.project.name}>
            {job.project.name}
          </Property>
          <Property
            i18nKey={`${i18nKey}.createdProperty`}
            icon="fa-calendar-day"
            title={t('locale.date', { date: new Date(job.createdAt) })}
          >
            {t('locale.date', { date: new Date(job.createdAt) })}
          </Property>
          <Property
            i18nKey={`${i18nKey}.modifiedProperty`}
            icon="fa-clock"
            title={t('locale.fromNow', { date: new Date(job.updatedAt) })}
          >
            {t('locale.fromNow', { date: new Date(job.updatedAt) })}
          </Property>
          <Property i18nKey={`${i18nKey}.sourceLanguageProperty`} icon="fa-language" title={undefined}>
            {getLanguageNameByCode(job.defaultSourceLanguage || '') || '-'}
          </Property>
          <Property i18nKey={`${i18nKey}.targetLanguageProperty`} icon="fa-language" title={'target language(s)'}>
            {job.defaultTargetLanguages.length
              ? job.defaultTargetLanguages
                  .map(code => {
                    return getLanguageNameByCode(code);
                  })
                  .join(', ')
              : '-'}
          </Property>
        </Columns>
      ) : (
        <div className="has-text-centered">
          <i className="fas fa-spin fa-spinner is-centered" data-testid="Overview.JobInfo.Page.loader" />
        </div>
      )}
    </Box>
  );
};

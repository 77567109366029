import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Translatable } from '../../../common/language';
import { LanguageInfo } from '../../../pages/Overview/TaskCreatePage/TaskCreatePage';
import { LanguageTag } from '../../LanguageTag/LanguageTag';

export const DistinctSourceLanguages: React.FC<Translatable & {
  languageInfo: LanguageInfo<object>[];
}> = ({ i18nKey, languageInfo }): ReactElement => {
  const [t] = useTranslation();

  return (
    <div className="overview-line">
      {t(`${i18nKey}.totalSourceLanguages`)}
      {languageInfo
        .map(info => info.sourceLanguage.code)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort()
        .map(lang => (
          <div className="tags has-addons is-pulled-right" key={lang}>
            <LanguageTag language={lang} />
            <span className="tag is-info metric">
              {languageInfo.map(info => info.sourceLanguage.code).filter(l => l === lang).length}
            </span>
          </div>
        ))}
    </div>
  );
};

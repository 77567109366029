import { useApiError } from 'common/hooks/useApiError';
import { Api } from 'modules/api/api';
import { AssetDto } from 'modules/api/locHub/assets/asset/asset';
import { InputDto } from 'modules/api/locHub/inputs/input/input';
import { TapiccStatus } from 'modules/api/locHub/tasks/task/status/status';
import { Task } from 'modules/api/locHub/tasks/task/task';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Translatable } from '../../../../common/language';
import { HtmlProps } from '../../../../common/utils/HTMLProps';
import { Dropdown, DropdownDivider, DropdownOption } from '../../../../components/Dropdown/Dropdown';
import { useMessage } from '../../../../components/Message/MessageProvider';
import { uploadTranslation } from '../utillities';

export interface Props extends Translatable, HtmlProps {
  asset: AssetDto;
  task: Task;
  input: InputDto;
  delivered: boolean;
  actions: {
    delivered: () => void;
  };
}

export const DropdownActions: React.FC<Props> = ({
  i18nKey,
  className,
  id,
  input,
  task,
  asset,
  delivered,
  actions,
}): ReactElement | null => {
  const apiError = useApiError();
  const [t] = useTranslation();
  const message = useMessage();
  const dropdownActions: ReactElement[] = [];

  /* Actions */
  const onUploadTranslation = async (): Promise<void> => {
    try {
      const taskStatusAfterUpload = await uploadTranslation(input);
      if (taskStatusAfterUpload !== task.status) {
        task.status = taskStatusAfterUpload;
      }
      actions.delivered();
      message.success(t(`${i18nKey}.actions.uploadTranslation.success`, { asset: asset.fileOriginalName }));
    } catch (error) {
      apiError.locHub.handle(
        error,
        t(`${i18nKey}.actions.uploadTranslation.failure`, { asset: asset.fileOriginalName }),
      );
    }
  };

  if (task.isReadyToBeUploaded() && !delivered) {
    dropdownActions.push(
      <DropdownOption
        i18nKey={`${i18nKey}.uploadTranslation`}
        key={TapiccStatus.CONFIRMED}
        icon="upload"
        onClick={onUploadTranslation}
      />,
    );
  }

  return (
    <Dropdown direction="right" className={className} id={id}>
      {dropdownActions}
      {dropdownActions.length > 0 && <DropdownDivider />}
      <DropdownOption
        i18nKey={`${i18nKey}.downloadOriginalAsset`}
        icon="file-download"
        onClick={async (): Promise<void> => {
          try {
            await Api.locHub.inputs.download(input);
          } catch (error) {
            apiError.locHub.handle(error, t('Overview.JobInfoPage.downloadError'));
          }
        }}
      />
    </Dropdown>
  );
};

import { XillioProvider } from 'common/interfaces/xillio';

export enum XillioProviderType {
  KALEIDOSCOPE = 'Kaleidoscope',
  LANGUAGELINE = 'LanguageLine',
}

export class XillioHumanTranlsationProviders {
  private static humanTranslation = new Map<XillioProviderType, XillioProvider>([
    [
      XillioProviderType.KALEIDOSCOPE,
      {
        configurationType: XillioProviderType.KALEIDOSCOPE,
      },
    ],
    [
      XillioProviderType.LANGUAGELINE,
      {
        configurationType: XillioProviderType.LANGUAGELINE,
      },
    ],
  ]);

  public static getAll(): XillioProvider[] {
    return Array.from(XillioHumanTranlsationProviders.humanTranslation.values());
  }
}

export enum TaskType {
  TRANSLATION = 'translation',
  LOCALIZATION = 'localization',
  INTERNATIONALIZATION = 'internationalization',
  ADAPTION = 'adaptation',
  LINGUISTIC_EDITING = 'linguistic-editing',
  TECHNICAL_EDITING = 'technical-editing',
  PROOFREADING = 'proofreading',
  COPYEDITING = 'copyediting',
  MT_POST_EDITING = 'mt-post-editing',
  RECONCILIATION = 'reconciliation',
  REVIEW = 'review',
  REVIEW_WITH_LQA = 'review-with-lqa',
  TERMINOLOGY_WORK = 'terminology-work',
  LINGUISTIC_VERIFICATION = 'linguistic-verification',
  TECHNICAL_VERIFICATION = 'technical-verification',
  SERVICE_LINGUISTIC_TASK = 'service-linguistic-task',
  TRANSCRIPTION = 'transcription',
  RETYPING_HANDWRITTEN_TEXT = 'retyping-handwritten-text',
  CONTENT_CREATION = 'content-creation',
  SCOPING = 'scoping',
  DESKTOP_PUBLISHING = 'desktop-publishing',
}

import { AssetDto } from 'modules/api/locHub/assets/asset/asset';
import { JobId } from 'modules/api/locHub/jobs/job/id/id';
import React, { Dispatch, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Translatable } from '../../../../common/language';
import { LanguageTag } from '../../../../components/LanguageTag/LanguageTag';
import { useMessage } from '../../../../components/Message/MessageProvider';
import { AssetOverviewTable } from '../../../../components/Task/TaskSummary/AssetOverviewTable';
import { DistinctSourceLanguages } from '../../../../components/Task/TaskSummary/DistinctSourceLanguages';
import { DistinctTargetLanguages } from '../../../../components/Task/TaskSummary/DistinctTargetLanguages';
import { ToBeCreatedTasks } from '../../../../components/Task/TaskSummary/ToBeCreatedTasks';
import { TotalAssets } from '../../../../components/Task/TaskSummary/TotalAssets';
import Wizard from '../../../../components/Wizard/Wizard';
import { SubmitButton } from '../SubmitButton';
import { LanguageInfo } from '../TaskCreatePage';
import { submit } from './utilities';

export interface TaskErrorMessage {
  key: string;
  args?: Record<string, unknown>;
}

export interface ConfirmStepProps extends Translatable {
  jobId: JobId;
  languageInfo: LanguageInfo<AssetDto>[];
  setLanguageInfo: Dispatch<LanguageInfo<AssetDto>>;
}

export const ConfirmStep: React.FC<ConfirmStepProps> = ({
  i18nKey,
  jobId,
  languageInfo,
  setLanguageInfo,
}): ReactElement => {
  const [t] = useTranslation();
  const history = useHistory();
  const message = useMessage();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (): Promise<void> => {
    setLoading(true);

    submit(jobId, languageInfo, setLanguageInfo)
      .then(() => {
        message.success(
          t(`${i18nKey}.success`, {
            count: languageInfo.reduce((acc, info) => acc + info.targetLanguageCodes.length, 0),
          }),
        );
        history.push('/jobs/' + jobId);
      })
      .catch(e => {
        message.error(t([`${i18nKey}.${e}`, `${i18nKey}.unknown`]));
      });

    setLoading(false);
  };

  return (
    <Wizard.Step
      i18nKey={`${i18nKey}`}
      render={(): ReactElement => (
        <>
          <div className="asset-overview">
            <AssetOverviewTable
              i18nKey={i18nKey}
              languageInfo={languageInfo}
              render={(info): ReactElement => <AssetOverviewRow languageInfo={info} />}
            />
          </div>
          <hr />
          <div className="general-overview">
            <TotalAssets i18nKey={i18nKey} languageInfo={languageInfo} />
            <ToBeCreatedTasks i18nKey={i18nKey} languageInfo={languageInfo} />
            <DistinctSourceLanguages i18nKey={i18nKey} languageInfo={languageInfo} />
            <DistinctTargetLanguages i18nKey={i18nKey} languageInfo={languageInfo} />
          </div>
          <hr />
          <SubmitButton i18nKey={i18nKey} loading={loading} disabled={loading} onClick={onSubmit} />
        </>
      )}
    />
  );
};

const AssetOverviewRow: React.FC<{
  languageInfo: LanguageInfo<AssetDto>;
}> = ({ languageInfo }) => {
  const targetLanguages = languageInfo.targetLanguageCodes;
  targetLanguages.sort();
  return (
    <tr className="asset-info">
      <td>{languageInfo.data.name}</td>
      <td>
        <LanguageTag language={languageInfo.sourceLanguage.code} />
      </td>
      <td>
        {targetLanguages.map(lang => (
          <LanguageTag language={lang} key={lang} />
        ))}
      </td>
    </tr>
  );
};

import { classNames } from 'common/utils/classNames';
import { Chip } from 'components/Chips/Chip/Chip';
import { Chips } from 'components/Chips/Chips';
import { Columns } from 'components/Columns/Columns';
import { Property } from 'components/Property/Property';
import { useAutomationDto } from 'modules/api/locHub/automations/query/detail/detail';
import { useProjectDto } from 'modules/api/locHub/projects/query/detail/detail';
import { LoadingPage } from 'pages/Common/LoadingPage/LoadingPage';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { AppLayout } from '../../../components/AppLayout/AppLayout';
import { Box } from '../../../components/Box/Box';
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs';
import { useMessage } from '../../../components/Message/MessageProvider';
import { HomePageCrumb } from '../../Overview/HomePage/HomePage';
import { AdminPageCrumb } from '../AdminPage/AdminPage';
import { AutomationListPageCrumb } from '../AutomationListPage/AutomationListPage';

export const AutomationEditPageCrumb: Breadcrumb = {
  page: 'Administration.AutomationInfoPage',
  icon: 'info',
  location: '/admin/automations/:automationId',
};

export const AutomationInfoPage: React.FC = (): ReactElement => {
  const [t] = useTranslation();
  const message = useMessage();
  const history = useHistory();
  const automationId = useParams<{ automationId: string }>().automationId;
  const namespace = AutomationEditPageCrumb.page;
  const handleApiError = (_error: Error): void => {
    history.push('/admin/automations');
    message.error(t('Administration.AutomationEditPage.errorNotFound', { automationId: automationId }));
  };
  const automation = useAutomationDto(automationId, { error: { handler: handleApiError }, query: {} });
  const automationProject = useProjectDto(automation.success ? automation.data.projectId : undefined, {
    error: { handler: handleApiError },
    query: {},
  });
  if (!automation.success || !automationProject.success) {
    return <LoadingPage></LoadingPage>;
  }
  const yes = t(`${namespace}.yes`);
  const no = t(`${namespace}.no`);
  const dataActiveIcon = automation.data.active ? 'fa-play' : 'fa-pause';
  const dataActive = automation.data.active ? yes : no;
  const dataDelay =
    automation.data.delay === 0
      ? t(`${namespace}.automation.delay.none`)
      : t('locale.minutes', { duration: automation.data.delay });
  const dataAutoMFT = automation.data.autoMarkForTranslation ? yes : no;
  const dataAutoAT = automation.data.autoAcceptTranslation ? yes : no;
  const dataRecCrawl = automation.data.recursiveCrawl ? yes : no;
  return (
    <AppLayout breadcrumbs={[HomePageCrumb, AdminPageCrumb, AutomationListPageCrumb, AutomationEditPageCrumb]}>
      <Box i18nKey={namespace}>
        <Columns>
          <Property i18nKey={`${namespace}.automation.project`} icon="fa-archive" title={automationProject.data.name}>
            <Link to={`/projects/${automationProject.data.id}`}>{automationProject.data.name}</Link>
          </Property>
          <Property
            i18nKey={`${namespace}.automation.engineConfigurationName`}
            icon="fa-box"
            title={automation.data.engineConfigurationId}
          >
            {automation.data.engineConfigurationId}
          </Property>
          <Property i18nKey={`${namespace}.automation.name`} icon="fa-tag" title={automation.data.name}>
            {automation.data.name}
          </Property>
          <Property
            i18nKey={`${namespace}.automation.description`}
            icon="fa-list-alt"
            title={automation.data.description}
          >
            {automation.data.description}
          </Property>
          <Property i18nKey={`${namespace}.automation.active`} icon={dataActiveIcon} title={dataActive}>
            {dataActive}
          </Property>
          <Property
            i18nKey={`${namespace}.automation.period`}
            icon="fa-history"
            title={t('locale.minutes', { duration: automation.data.period })}
          >
            {t('locale.minutes', { duration: automation.data.period })}
          </Property>
          <Property i18nKey={`${namespace}.automation.delay`} icon="fa-hourglass-start" title={dataDelay}>
            {dataDelay}
          </Property>
          <Property
            i18nKey={`${namespace}.automation.autoMarkForTranslation`}
            icon="fa-check-circle"
            title={dataAutoMFT}
          >
            {dataAutoMFT}
          </Property>
          <Property i18nKey={`${namespace}.automation.autoAcceptTranslation`} icon="fa-file-upload" title={dataAutoAT}>
            {dataAutoAT}
          </Property>
          <Property i18nKey={`${namespace}.automation.recursiveCrawl`} icon="fa-level-down-alt" title={dataRecCrawl}>
            {dataRecCrawl}
          </Property>
          <div className="field">
            <label className="label">{t(`${namespace}.automation.paths.label`)}</label>
            <div className={classNames('control', 'has-icons-left')}>
              <div className="property">
                <Chips>
                  {automation.data.paths.map((path: string) => {
                    return <Chip key={path} id={path} label={path} actions={{}} />;
                  })}
                </Chips>
              </div>
            </div>
          </div>
        </Columns>
      </Box>
    </AppLayout>
  );
};

import { AppLayout } from 'components/AppLayout/AppLayout';
import { Api } from 'modules/api/api';
import { useProjects } from 'modules/api/locHub/projects/query/list/list';
import { Field } from 'modules/form/field/field';
import { LoadingPage } from 'pages/Common/LoadingPage/LoadingPage';
import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Box } from '../../../components/Box/Box';
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs';
import { Form } from '../../../components/Form/Form';
import { Name } from '../../../components/Input';
import { ProjectSelect } from '../../../components/Input/SelectFields';
import { LanguageSettings } from '../../../components/LanguageSettings/LanguageSettings';
import { HomePageCrumb } from '../HomePage/HomePage';
import { JobsListPageCrumb } from '../JobsListPage/JobsListPage';

export const JobCreatePageCrumb: Breadcrumb = {
  page: 'Overview.JobCreatePage',
  icon: 'plus',
  location: '/jobs/new',
};

export const JobCreatePage: React.FC = (): ReactElement => {
  const [name, setName] = useState('');
  const [projectId, setProjectId] = useState<string>();
  const projects = useProjects();
  const [sourceLanguage, setSourceLanguage] = useState<string>();
  const [targetLanguages, setTargetLanguages] = useState<string[]>([]);
  // sync project selection with language settings
  useEffect((): void => {
    if (projectId && projects.success) {
      const project = projects.data.find(item => {
        return item.id === projectId;
      });
      if (project) {
        if (project.defaultSourceLanguage) {
          setSourceLanguage(project.defaultSourceLanguage);
        } else {
          setSourceLanguage('');
        }
        if (project.defaultTargetLanguages) {
          setTargetLanguages(project.defaultTargetLanguages);
        } else {
          setTargetLanguages([]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, projects.success]);

  const [error, setError] = useState();
  const history = useHistory();

  if (!projects.success) {
    return <LoadingPage />;
  }

  return (
    <AppLayout breadcrumbs={[HomePageCrumb, JobsListPageCrumb, JobCreatePageCrumb]}>
      <Box i18nKey={JobCreatePageCrumb.page}>
        <Form
          i18nKey="Overview.JobCreatePage"
          cancelTo="/jobs"
          disabled={
            Field.isEmpty(name) ||
            Field.isEmpty(projectId) ||
            Field.isEmpty(sourceLanguage) ||
            !targetLanguages.length ||
            error
          }
          error={error}
          onSubmit={async (): Promise<void> => {
            if (projectId === undefined) {
              throw new Error('Form has been submitted without selection of the project.');
            }

            try {
              setError(undefined);
              const newJob = await Api.locHub.jobs.create({
                name,
                projectId,
                defaultSourceLanguage: sourceLanguage,
                defaultTargetLanguages: targetLanguages,
              });
              history.push(`/tasks/new/${newJob.id}`);
            } catch (newJobError) {
              setError(newJobError);
            }
          }}
        >
          <Name i18nKey="Overview.JobCreatePage.nameField" value={name} onChange={setName} required />
          <ProjectSelect
            i18nKey="Overview.JobCreatePage.projectField"
            options={projects.data}
            onChange={setProjectId}
            value={projectId}
          />
          <div className="column is-full">
            <LanguageSettings
              i18nKey="Overview.JobCreatePage.languageSettings"
              source={sourceLanguage}
              onSourceChange={setSourceLanguage}
              targets={targetLanguages}
              onTargetsChange={setTargetLanguages}
            />
          </div>
        </Form>
      </Box>
    </AppLayout>
  );
};

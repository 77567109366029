import { TFunction } from 'i18next';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { isValidationError } from '../../common/interfaces/common';
import { classNames } from '../../common/utils/classNames';
import { translateError } from '../../common/utils/translateError';

export interface Props {
  i18nKey: string;
  leftIcon?: string;
  rightIcon?: string;
  rightIconAction?: () => void;
  name: string;
  errors?: unknown;
  required?: boolean;
}

function getError(t: TFunction, i18nKey: string, errors: unknown, fieldName: string): string | undefined {
  if (!isValidationError(errors)) {
    return undefined;
  }
  const firstError = errors.errors.filter((err): boolean => err.field === fieldName)[0];
  if (firstError) {
    return translateError(t, firstError.codes, i18nKey);
  }
}

export const Field: React.FC<Props> = ({
  leftIcon,
  rightIcon,
  rightIconAction,
  name,
  i18nKey,
  children,
  errors,
  required,
}): ReactElement => {
  const [t] = useTranslation('');
  const fieldError = getError(t, i18nKey, errors, name);
  return (
    <div className={classNames('field', required && 'is-required')}>
      <label className="label" htmlFor={`${name}-input`} data-testid={`${i18nKey}.label`}>
        {t(`${i18nKey}.label`)}
      </label>
      <div
        className={classNames(
          'control',
          leftIcon && 'has-icons-left',
          rightIcon && 'has-icons-right',
          fieldError && 'is-danger',
        )}
      >
        {children}
        <span className="icon is-left">
          <i className={`fas ${leftIcon}`} />
        </span>
        {rightIconAction ? (
          <span
            style={{ pointerEvents: 'initial', cursor: 'pointer' }}
            className="icon is-right"
            onClick={rightIconAction}
          >
            <i className={`fas ${rightIcon}`} />
          </span>
        ) : (
          <span className="icon is-right">
            <i className={`fas ${rightIcon}`} />
          </span>
        )}
      </div>
      <p className="help is-danger">{fieldError}</p>
    </div>
  );
};

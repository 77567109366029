import { XillioEntity } from '../../common/interfaces/xillio';

export type EntityID = Pick<XillioEntity, 'path' | 'configurationId'>;
export function getEntityId(entity: EntityID): string {
  return `${entity.configurationId}${entity.path}`;
}

export function asEntityID(entity: EntityID): EntityID {
  return { configurationId: entity.configurationId, path: entity.path };
}

export function equal(a: EntityID, b: EntityID): boolean {
  return a.configurationId === b.configurationId && a.path === b.path;
}

export function isFolder(entity: XillioEntity): boolean {
  return entity.original.container?.hasChildren || false;
}

export function getEntityName(e: XillioEntity): string {
  return e.original.name.displayName || e.original.name.systemName;
}

export function getDescription(e: XillioEntity): string | undefined {
  return e.original.description?.short;
}

export function getPrettyFileZie(e: XillioEntity): string | undefined {
  const size = e.original.file?.size;
  if (!size) {
    return undefined;
  }

  const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(size) / Math.log(1000));

  const result = size / Math.pow(1000, i);
  return (i > 0 ? result.toFixed(2) : result) + ' ' + sizes[i];
}

export function getShort(content: undefined | string, length = 64): string | undefined {
  if (content && content.length > length) {
    content = content.substring(0, length) + '...';
  }
  return content;
}

export function withParent(entity: XillioEntity, parent: undefined | XillioEntity): XillioEntity {
  if (entity.original.parent?.id || !parent) {
    return entity;
  }
  return Object.assign(entity, { original: { ...entity.original, parent: { id: getEntityId(parent) } } });
}

import { Columns } from 'components/Columns/Columns';
import { JobNameInput } from 'components/HookForm/Entity/Job/Name/JobName';
import { CancelInput } from 'components/HookForm/Input/Cancel/Cancel';
import { ResetInput } from 'components/HookForm/Input/Reset/Reset';
import { SubmitInput } from 'components/HookForm/Input/Submit/Submit';
import { useMessage } from 'components/Message/MessageProvider';
import { Api } from 'modules/api/api';
import { JobId } from 'modules/api/locHub/jobs/job/id/id';
import React, { FormEvent, ReactElement } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UnpackNestedValue } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';

import { JobEditPageI18n } from './I18n';

interface Props {
  jobId: JobId;
  jobName: string;
}

interface Form {
  jobName: string;
}

export const JobEditForm: React.FC<Props> = ({ jobId, jobName }): ReactElement => {
  const { register, handleSubmit, reset, errors, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      jobName,
    },
  });
  const message = useMessage();
  const [t] = useTranslation();
  const loading = formState.isSubmitting;
  /* Actions */
  const submitAction: SubmitHandler<Form> = async (data: UnpackNestedValue<Form>): Promise<void> => {
    try {
      await Api.locHub.jobs.update(jobId, { name: data.jobName });
      reset({ jobName: data.jobName });
      message.success(t(`${JobEditPageI18n.configuration.namespace}.jobUpdateSuccess`));
    } catch (error) {
      message.error(t(`${JobEditPageI18n.configuration.namespace}.jobUpdateFailure`));
    }
  };
  const resetAction = (event: FormEvent): void => {
    event.preventDefault();
    reset();
  };
  return (
    <form onSubmit={handleSubmit(submitAction)} onReset={resetAction}>
      <Columns>
        <JobNameInput register={register} errors={errors} />
      </Columns>
      <div className={'buttons is-pulled-right'}>
        <SubmitInput loading={loading} disabled={!formState.isValid || !formState.isDirty} />
        <ResetInput disabled={loading} />
        <CancelInput to={'/jobs'} />
      </div>
    </form>
  );
};

import { Breadcrumb } from 'modules/router/breadcrumb/breadcrumb';
import { Page } from 'modules/router/page/page';
import { RouteConfiguration } from 'modules/router/route/route';

import { HomePageCrumb } from '../HomePage/HomePage';
import { JobsListPageCrumb } from '../JobsListPage/JobsListPage';
import { JobEditPageI18n } from './I18n';
import { JobEditPage } from './JobEditPage';

const configuration: RouteConfiguration = {
  exact: true,
  path: '/jobs/:jobId/edit',
  component: JobEditPage,
};

const breadcrumb: Breadcrumb = {
  page: JobEditPageI18n.configuration.namespace,
  icon: 'fas fa-edit',
  location: configuration.path,
  label: `${JobEditPageI18n.configuration.namespace}.${JobEditPageI18n.configuration.labelKey}`,
};

const breadcrumbs: Breadcrumb[] = [HomePageCrumb, JobsListPageCrumb, breadcrumb];

export const jobEditPage: Page = {
  configuration,
  breadcrumbs,
  i18n: JobEditPageI18n.configuration,
};

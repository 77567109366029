import { Page, Pagination } from 'common/interfaces/common';
import { Api } from 'modules/api/api';
import { queryCache } from 'react-query';

import { QueryBuilder } from '../entity/query/builder/builder';
import { EntityRelation } from '../entity/relation/relation';
import { LocHub } from '../lochub';
import { ProjectId } from '../projects/project/id/id';
import { AssetDto, NewAsset, NewXillioAsset, XillioAssetDto } from './asset/asset';
import { AssetId } from './asset/id/id';

export class Assets {
  public static readonly path: string = '/api/internal/assets';
  private static getBaseUrl(parameters: Record<string, string | number | undefined> = {}): URL {
    return Api.getUrl(Assets.path, parameters);
  }

  private static getDetailUrl(id: AssetId): URL {
    return Api.getUrl(`${Assets.path}/${id}`);
  }

  private static getCustomUrl(path: string): URL {
    return Api.getUrl(`${Assets.path}/${path}`);
  }

  public static async getMany(page: number, options?: Pagination): Promise<Page<AssetDto>> {
    return LocHub.getJson(Assets.getBaseUrl({ page, ...options }));
  }

  public static async getManyByProject(
    projectId: ProjectId,
    page: number,
    options?: Pagination,
  ): Promise<Page<AssetDto>> {
    return LocHub.getJson(
      Assets.getBaseUrl({
        page,
        ...options,
        query: new QueryBuilder().addRelation(EntityRelation.PROJECT, projectId).build(),
      }),
    );
  }

  public static async getDto(assetId: AssetId): Promise<AssetDto> {
    return LocHub.getJson(Assets.getDetailUrl(assetId));
  }

  public static async getAll(parameters?: Pagination): Promise<AssetDto[]> {
    return LocHub.getAll(Assets.path, { ...parameters });
  }

  public static uploadContent(assetId: AssetId, content: string | ArrayBuffer): Promise<Response> {
    return LocHub.putFile(Assets.getCustomUrl(`${assetId}/content`), content);
  }

  public static async createFromXillioEngine(asset: NewXillioAsset): Promise<XillioAssetDto> {
    return LocHub.postJson(Api.getUrl(`api/internal/xillio/assets`), asset);
  }

  public static async create(asset: NewAsset): Promise<AssetDto> {
    const result = await LocHub.postJson<NewAsset, AssetDto>(Assets.getBaseUrl(), asset);
    queryCache.invalidateQueries(Assets.path);
    return result;
  }

  public static async update(assetId: AssetId, asset: Partial<AssetDto>): Promise<AssetDto> {
    const result = await LocHub.patchJson(Assets.getDetailUrl(assetId), asset);
    queryCache.invalidateQueries(Assets.path);
    return result;
  }

  public static async delete(assetId: AssetId): Promise<void> {
    await LocHub.delete(Assets.getDetailUrl(assetId));
    queryCache.invalidateQueries(Assets.path);
  }

  public static async download(assetId: AssetId): Promise<void> {
    const asset = await Assets.getDto(assetId);
    return Api.locHub.downloadFile(Assets.getCustomUrl(`${assetId}/content`), asset.fileOriginalName);
  }
}

import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { classNames } from '../../common/utils/classNames';
import { HtmlProps } from '../../common/utils/HTMLProps';

export interface Props extends HtmlProps {
  i18nKey: string;
  location: string;
  icon?: string;
}

export const Tile: React.FC<Props> = ({ i18nKey, location, icon, className, id }): ReactElement => {
  const [t] = useTranslation();
  return (
    <div id={id} className={classNames('tile', 'is-parent', className)}>
      <a
        rel="noopener noreferrer"
        href={location}
        target="_blank"
        className="tile is-child box has-text-centered admin-section-card"
      >
        <i className={`fas fa-4x fa-${icon}`} />
        <h3 className="title is-4 is-spaced">{t(`${i18nKey}.title`)}</h3>
        <p className="subtitle is-6 has-text-weight-semibold has-text-grey">{t(`${i18nKey}.description`)}</p>
      </a>
    </div>
  );
};

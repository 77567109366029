import { Api } from 'modules/api/api';
import { QueryConfiguration } from 'modules/api/locHub/entity/query/configuration/configuration';
import { useLocHubListAllByQuery } from 'modules/api/locHub/entity/query/listAllBy/listAllBy';
import { QueryResult } from 'modules/api/locHub/entity/query/query';
import { JobId } from 'modules/api/locHub/jobs/job/id/id';

import { Task, TaskDto } from '../../task/task';

export const useJobTasksDto = (
  jobId: JobId | undefined,
  configuration: QueryConfiguration<TaskDto[] | undefined, unknown> = { error: {}, query: {} },
): QueryResult<TaskDto[]> => {
  return useLocHubListAllByQuery(Api.locHub.tasks, Api.locHub.tasks.getAllDtoByJob, jobId, configuration);
};

export const useJobTasks = (
  jobId: JobId | undefined,
  configuration: QueryConfiguration<Task[] | undefined, unknown> = { error: {}, query: {} },
): QueryResult<Task[]> => {
  return useLocHubListAllByQuery(Api.locHub.tasks, Api.locHub.tasks.getAllByJob, jobId, configuration);
};

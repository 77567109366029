import React, { ReactElement } from 'react';

import { CmsConfiguration, XillioProvider } from '../../common/interfaces/xillio';

export const ProviderIcon: React.FC<{ configuration: CmsConfiguration | XillioProvider }> = ({
  configuration,
}): ReactElement => {
  const type = configuration.configurationType.toLowerCase();

  const providers = [
    'acquia',
    'aem',
    'bitbucket',
    'contentful',
    'connecting-content',
    'dropbox',
    'drupal',
    'gitlab',
    'google-drive',
    'kaleidoscope',
    'languageline',
    'lochub-qa-insights',
    'memoq',
    'sitecore',
    'sharepoint',
    'translate-5',
  ];
  if (providers.includes(type)) {
    return (
      <i className={'provider-' + type}>
        <img src={`${process.env.PUBLIC_URL}/providers/${type}.png`} alt={type} />
      </i>
    );
  }
  return <i className="provider-unknown" />;
};

/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactElement, useRef } from 'react';
import { useClickAway } from 'react-use';

export interface Props {
  close: () => void;
}

export type MessageType = 'normal' | 'success' | 'error' | 'info' | 'warning' | 'loading';

export const Modal: React.FC<Props> = ({ close, children }): ReactElement => {
  const modalContentRef = useRef(null);
  useClickAway(modalContentRef, () => {
    close();
  });
  return (
    <div id="modal" className="modal is-active">
      <div className="modal-background" />
      <div
        className="container is-widescreen"
        style={{ marginTop: '75px', height: '90%', width: '90%', overflow: 'scroll' }}
        ref={modalContentRef}
      >
        <div className="box" style={{ padding: '0' }}>
          {children}
        </div>
      </div>
      <button className="modal-close is-large" aria-label="close" onClick={close} />
    </div>
  );
};

import React, { Fragment, ReactElement, ReactNode } from 'react';

function asArray(children: ReactNode): ReactNode[] {
  if (Array.isArray(children)) {
    return children;
  } else {
    return [children];
  }
}

function hasProps(child: ReactNode): child is ReactElement {
  return !!(child && typeof child === 'object' && 'props' in child);
}

function wrapColumn(child: ReactNode, columnWidth: number): ReactElement {
  if (hasProps(child) && child.props.className && child.props.className.indexOf('column') >= 0) {
    // this element is already a column
    return child;
  } else {
    // wrap this element in a column of the desired width
    return <div className={`column is-${columnWidth}-desktop`}>{child}</div>;
  }
}

export interface Props {
  columnCount?: 1 | 2 | 3 | 4 | 6 | 12;
}

export const Columns: React.FC<Props> = ({ columnCount = 2, children }): ReactElement => {
  const columnWidth = 12 / columnCount;
  return (
    <div className="columns is-desktop is-multiline">
      {asArray(children).map(
        (child, i): ReactElement => (
          <Fragment key={i}>{wrapColumn(child, columnWidth)}</Fragment>
        ),
      )}
    </div>
  );
};

import { useEffect, useState } from 'react';

export function useKeyEvent(fn: () => void, key: string): void {
  const [pressed, setPressed] = useState<string>();

  useEffect(() => {
    document.addEventListener('keydown', e => setPressed(e.code));
    document.addEventListener('keyup', () => setPressed(undefined));
    return (): void => {
      document.removeEventListener('keydown', e => setPressed(e.code));
      document.removeEventListener('keyup', () => setPressed(undefined));
    };
  });

  useEffect(() => {
    if (!pressed) return;

    if (pressed.toLowerCase() === key.toLowerCase()) {
      fn();
    }
  }, [fn, key, pressed]);
}

export function useEscapeEvent(fn: () => void): void {
  useKeyEvent(fn, 'Escape');
}

import { queryString } from 'common/utils/queryString';
import { Button } from 'components/Button/Button';
import { Password, Username } from 'components/Input';
import { LandingLayout } from 'components/LandingLayout/LandingLayout';
import { LoadingButton } from 'components/LoadingButton/LoadingButton';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import { Api } from '../../../modules/api/api';
import { UserDto } from '../../../modules/api/locHub/users/user/user';

export interface Props {
  onLogin: (user: UserDto) => void;
}

export const LoginPage: React.FC<Props> = ({ onLogin }): ReactElement => {
  const [t] = useTranslation('');
  const history = useHistory();
  const location = useLocation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <LandingLayout id={'login'}>
      <h3 className="title">{t('Authentication.LoginPage.title')}</h3>
      {error && <div className="notification is-danger">{t('Authentication.LoginPage.badCredentialsError')}</div>}
      <form
        onSubmit={async (event): Promise<void> => {
          event.preventDefault();
          setError(false);
          setLoading(true);
          try {
            onLogin(await Api.login(username, password));
            history.push(queryString(location.search).from || '/home');
          } catch (e) {
            console.error(e);
            setError(true);
            setLoading(false);
          }
        }}
      >
        <Username required value={username} onChange={setUsername} i18nKey="Authentication.LoginPage.usernameField" />
        <Password required value={password} onChange={setPassword} i18nKey="Authentication.LoginPage.passwordField" />

        <div className="field is-grouped-wide controls">
          <p className="control">
            <LoadingButton loading={loading} className="is-success" i18nKey="Authentication.LoginPage.loginButton" />
          </p>
          <p className="control">
            <Button to="/login/forgot-password" i18nKey="Authentication.LoginPage.forgotPasswordButton" />
          </p>
        </div>
      </form>
    </LandingLayout>
  );
};

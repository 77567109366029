/**
 * Wrapper to transform the `FileReader`-object into a promise like construct.
 *
 * It will use the `onProgress` callback to push the status of the current read.
 * The FileReader will only periodically update its status, thus the progress might
 * jump from 0% to 100% in an instant, if the file size is rather small.
 *
 * @param file        The file to be read
 * @param onProgress  Callback with the updated progress
 */
export async function asyncFileReader(
  file: File,
  onProgress?: (percentage: number) => void,
): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Initially send 0% as the progress
    onProgress && onProgress(0);

    reader.onprogress = (e): void => {
      // On every update send the new progress
      onProgress && onProgress(e.loaded / e.total);
    };
    reader.onload = (e): void => {
      // When done set the progress to 100%
      onProgress && onProgress(1);
      resolve(e.target?.result);
    };
    reader.onerror = (): void => {
      reject();
    };

    reader.readAsArrayBuffer(file);
  });
}

/**
 * Promise-like method of opening a native file browser for a user to select files.
 */
export const openFileBrowser = (multiple = false): Promise<File[]> => {
  return new Promise(resolve => {
    // Prepare the DOM element to be appended to the input
    const input = window.document.createElement('input') as HTMLInputElement;
    input.type = 'file';
    input.multiple = multiple;
    input.value = '';

    // Once there is a change to the component we can resolve with all the files,
    // and remove the input element from the document.
    input.onchange = (): void => {
      resolve(Array.from(input.files ?? []));
    };

    input.click();
  });
};

import { SelectOption } from 'components/Input/Select/Dropdown/Options/Option';
import { MultipleSelect, MultipleSelectTexts } from 'components/Input/Select/MultipleSelect/MultipleSelect';
import { IETFLanguageCode } from 'modules/types/language/ietf/ietf';
import React, { ReactElement } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SourceLanguageFilterSelectI18n } from './I18n';

interface Props {
  entity: string;
  options: Map<IETFLanguageCode, SelectOption>;
  control: UseFormMethods['control'];
  errors: UseFormMethods['errors'];
  required: boolean;
}

export const SourceLanguageFilterSelect: React.FC<Props> = ({ entity, options, control, required }): ReactElement => {
  const [t] = useTranslation();
  const namespace = SourceLanguageFilterSelectI18n.configuration.namespace;
  const name = `${entity}SourceLanguagesFilter`;
  const icon = 'fa-globe';
  /* Texts */
  const texts: MultipleSelectTexts = {
    controls: {
      selectAll: t(`${namespace}.selectAll`),
      deselectAll: t(`${namespace}.deselectAll`),
      save: t(`${namespace}.save`),
    },
    options: { noOptions: t(`${namespace}.noOptions`) },
    filter: {
      placeholder: t(`${namespace}.filterPlaceholder`),
    },
    input: {
      label: t(`${namespace}.label`),
      placeholder: t(`${namespace}.placeholder`),
    },
  };
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: (data: Set<IETFLanguageCode> | undefined): boolean => {
          return required === false || (data !== undefined && data.size > 0);
        },
      }}
      render={({ onChange, value }): ReactElement => {
        return (
          <MultipleSelect
            texts={texts}
            icon={icon}
            name={name}
            options={options}
            values={value}
            actions={{
              valuesUpdated: onChange,
            }}
            required={required}
          />
        );
      }}
    />
  );
};

import React, { ReactElement } from 'react';

import { Field, Props as FieldProps } from '../Field/Field';
import { TestIds } from '../testIds';

export interface SimpleInputActions {
  readonly focus: () => void;
}

export interface SimpleInputTexts {
  label: string;
  placeholder: string;
}

export interface Props extends FieldProps {
  readonly texts: SimpleInputTexts;
  readonly value: string;
  readonly actions: SimpleInputActions;
  readonly required: boolean;
  readonly disabled?: boolean;
}

export const SimpleInput: React.FC<Props> = ({
  texts,
  leftIcon: icon,
  name,
  value,
  error,
  actions,
  required,
  disabled,
}): ReactElement => {
  return (
    <Field
      texts={{ label: texts.label }}
      leftIcon={icon}
      rightIcon="fa-search"
      name={name}
      required={required}
      error={error}
    >
      <input
        className="input"
        id={`${name}-input`}
        name={name}
        type="text"
        placeholder={texts.placeholder}
        required={true}
        value={value}
        readOnly={true}
        disabled={disabled}
        data-testid={TestIds.SIMPLE_INPUT}
        autoComplete="off"
        onClick={
          disabled
            ? (): void => {
                /* no-op */
              }
            : actions.focus
        }
      />
    </Field>
  );
};

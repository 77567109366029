import React, { ReactElement } from 'react';

export interface Props {
  currentPage: number;
  onChangePage: (page: number) => void;
  lastPage: number;
}

export const Pagination: React.FC<Props> = ({ currentPage, onChangePage, lastPage }): ReactElement => (
  <nav className="pagination" role="navigation" aria-label="pagination">
    <ul className="pagination-list">
      {currentPage > 1 && (
        <li>
          <button
            id="previous-page-button"
            onClick={(): void => onChangePage(currentPage - 1)}
            className="pagination-link"
          >
            <i className="fas fa-chevron-left" />
          </button>
        </li>
      )}
      {currentPage > 1 && (
        <li>
          <button onClick={(): void => onChangePage(1)} className="pagination-link">
            1
          </button>
        </li>
      )}
      {currentPage > 2 && (
        <li>
          <span className="pagination-ellipsis">&hellip;</span>
        </li>
      )}
      <li>
        <button onClick={(): void => onChangePage(currentPage)} className="pagination-link is-current">
          {currentPage}
        </button>
      </li>
      {currentPage < lastPage - 1 && (
        <li>
          <span className="pagination-ellipsis">&hellip;</span>
        </li>
      )}
      {currentPage < lastPage && (
        <li>
          <button onClick={(): void => onChangePage(lastPage)} className="pagination-link">
            {lastPage}
          </button>
        </li>
      )}
      {currentPage < lastPage && (
        <li>
          <button id="next-page-button" onClick={(): void => onChangePage(currentPage + 1)} className="pagination-link">
            <i className="fas fa-chevron-right" />
          </button>
        </li>
      )}
    </ul>
  </nav>
);

import { classNames } from 'common/utils/classNames';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ResetInputI18n } from './I18n';

interface Props {
  disabled: boolean;
}

export const ResetInput: React.FC<Props> = ({ disabled }): ReactElement => {
  const [t] = useTranslation();
  const resetButtonText: string = t(`${ResetInputI18n.configuration.namespace}.label`);
  return (
    <input
      type="reset"
      id="reset-button"
      className={classNames('button')}
      disabled={disabled}
      value={resetButtonText}
      //onClick={actions.reset}
    />
  );
};

import React, { ReactElement, cloneElement, useState } from 'react';

import { classNames } from '../../common/utils/classNames';

export interface Props {
  children: ReactElement;
  onDrop: (data: DataTransfer) => void;
  disabled?: boolean;
}

export const FileDrop: React.FC<Props> = ({ children, onDrop, disabled = false }): ReactElement => {
  const child = children;
  const [isDragging, setDragging] = useState(false);

  const preventEvents = (event: DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();
  };

  const className = classNames(child.props.className, isDragging && (disabled ? 'no-drag' : 'is-dragging'));

  return cloneElement(child, {
    className: className || undefined,
    onDragOver: (event: DragEvent) => {
      preventEvents(event);
      setDragging(true);
    },
    onDragEnter: (event: DragEvent) => {
      preventEvents(event);
      setDragging(true);
    },
    onDragEnd: (event: DragEvent) => {
      preventEvents(event);
      setDragging(false);
    },
    onDragExit: (event: DragEvent) => {
      preventEvents(event);
      setDragging(false);
    },
    onDragLeave: (event: DragEvent) => {
      preventEvents(event);
      setDragging(false);
    },
    onDrop: (event: DragEvent) => {
      preventEvents(event);
      setDragging(false);
      if (!disabled && event.dataTransfer) {
        onDrop(event.dataTransfer);
      }
    },
  });
};

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isValidationError } from '../../common/interfaces/common';
import { Future } from '../../common/utils/Future';
import { translateError } from '../../common/utils/translateError';
import { ActionOrCancelButton } from '../ActionOrCancelButton/ActionOrCancelButton';
import { Columns, Props as ColumnsProps } from '../Columns/Columns';

export interface Props extends ColumnsProps {
  i18nKey: string;
  cancelTo: string;
  onSubmit: () => Future<void>;
  error: unknown;
  disabled?: boolean;
}

const Error: React.FC<{ error: unknown; i18nKey: string }> = ({ error, i18nKey }): ReactElement | null => {
  const [t] = useTranslation();

  if (!error) {
    return null;
  }

  if (isValidationError(error)) {
    return (
      <>
        {error.errors
          .filter((err): boolean => !err.field)
          .map(
            (err, i): ReactElement => (
              <div key={i} className="notification is-danger">
                {translateError(t, err.codes, i18nKey)}
              </div>
            ),
          )}
      </>
    );
  } else {
    return <div className="notification is-danger">{t('errors.unknown')}</div>;
  }
};

export const Form: React.FC<Props> = ({
  i18nKey,
  onSubmit,
  columnCount,
  children,
  cancelTo,
  error,
  disabled,
}): ReactElement => {
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(false);
  useEffect((): (() => void) => {
    isMounted.current = true;
    return (): void => {
      // we keep track of mount state to prevent state updates after dismount
      isMounted.current = false;
    };
  });

  return (
    <form
      onSubmit={async (event): Promise<void> => {
        event.preventDefault();
        try {
          setLoading(true);
          await onSubmit();
        } finally {
          if (isMounted.current) {
            setLoading(false);
          }
        }
      }}
    >
      <Error error={error} i18nKey={i18nKey}></Error>
      <Columns columnCount={columnCount}>{children}</Columns>
      <ActionOrCancelButton
        i18nKey={`${i18nKey}.submitButton`}
        loading={loading}
        cancelTo={cancelTo}
        disabled={disabled}
      />
    </form>
  );
};

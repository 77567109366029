import React, { ReactElement } from 'react';

import { CmsConfiguration } from '../../common/interfaces/xillio';
import { classNames } from '../../common/utils/classNames';
import { ConnectorIcon } from './ConnectorIcon';

interface Props {
  configurations: CmsConfiguration[];
  selected: undefined | CmsConfiguration;
  setSelected: (selection: CmsConfiguration) => void;
}

export const ConfigurationSelect: React.FC<Props> = ({ configurations, selected, setSelected }): ReactElement => {
  const shouldCenter = /* window.innerWidth > 1024 && */ configurations.length <= 4;

  return (
    <div className={classNames('configuration-select', shouldCenter && 'is-centered')}>
      {configurations.map((config, index) => (
        <div
          key={config.id}
          className={classNames('configuration-tile', 'is-clickable', selected === config && 'is-selected')}
          onClick={(): void => setSelected(config)}
        >
          <ConnectorIcon configuration={config} />
          <div>{getPrettyConfigurationName(config)}</div>
        </div>
      ))}
    </div>
  );
};

export function getPrettyConfigurationName({ name, id, configurationType }: CmsConfiguration): string {
  return name === id || name === undefined
    ? `${configurationType} (${id.substring(0, 6)})`
    : `${name} (${id.substring(0, 6)})`;
}

import { SelectOption } from 'components/Input/Select/Dropdown/Options/Option';
import { SingleSelect, SingleSelectTexts } from 'components/Input/Select/SingleSelect/SingleSelect';
import { IETFLanguageCode } from 'modules/types/language/ietf/ietf';
import React, { ReactElement } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SourceLanguageSelectI18n } from './I18n';

interface Props {
  entity: string;
  options: Map<IETFLanguageCode, SelectOption>;
  control: UseFormMethods['control'];
  errors: UseFormMethods['errors'];
  required: boolean;
}

export const SourceLanguageSelect: React.FC<Props> = ({ entity, options, control, required }): ReactElement => {
  const [t] = useTranslation();
  const namespace = SourceLanguageSelectI18n.configuration.namespace;
  const name = `${entity}SourceLanguage`;
  const icon = 'fa-globe';
  /* Texts */
  const texts: SingleSelectTexts = {
    controls: {
      clear: t(`${namespace}.clear`),
      save: t(`${namespace}.save`),
    },
    options: { noOptions: t(`${namespace}.noOptions`) },
    filter: {
      placeholder: t(`${namespace}.filterPlaceholder`),
    },
    input: {
      label: t(`${namespace}.label`),
      placeholder: t(`${namespace}.placeholder`),
    },
  };
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: (data: Set<IETFLanguageCode> | undefined): boolean => {
          return required === false || (data !== undefined && data.size > 0);
        },
      }}
      render={({ onChange, value }): ReactElement => {
        return (
          <SingleSelect
            texts={texts}
            icon={icon}
            name={name}
            options={options}
            value={value ? (Array.from(value)[0] as string) : undefined}
            actions={{
              valuesUpdated: (id: IETFLanguageCode | null): void => {
                onChange(id ? new Set([id]) : new Set());
              },
            }}
            required={required}
          />
        );
      }}
    />
  );
};

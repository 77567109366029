import { classNames } from 'common/utils/classNames';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';

import { Api } from '../../modules/api/api';
import { Button } from '../Button/Button';

export interface Props {
  showItems?: boolean;
}

export const NavBar: React.FC<Props> = ({ showItems }): ReactElement => {
  const [expanded, setExpanded] = useState(false);
  const [t] = useTranslation();
  return (
    <nav className="navbar ">
      <div className="container">
        <div className="navbar-brand">
          <Link id="lochub-logo" className="navbar-item" to="/">
            <img src={`${process.env.PUBLIC_URL}/logos/logo-white-comp-16kb.png`} alt="LogHub" />
          </Link>
          <button
            className={classNames('navbar-burger', 'has-text-white', expanded && 'is-active')}
            aria-label="menu"
            aria-expanded={expanded}
            onClick={(): void => setExpanded(!expanded)}
          >
            {expanded ? <i className="fas fa-times" /> : <i className="fas fa-bars" />}
          </button>
        </div>

        <div className={classNames('navbar-menu', expanded && 'is-active')}>
          {(showItems === true || showItems === undefined) && (
            <div className={classNames('navbar-start')}>
              <NavLink id="admin-link" className={classNames('navbar-item', expanded && 'has-text-dark')} to="/admin">
                <i className="fas fa-cogs" />
                {t('Administration.AdminPage.title')}
              </NavLink>
              <NavLink
                id="projects-link"
                className={classNames('navbar-item', expanded && 'has-text-dark')}
                to="/projects"
              >
                <i className="fas fa-archive" />
                {t('Overview.ProjectListPage.title')}
              </NavLink>
              <NavLink id="jobs-link" className={classNames('navbar-item', expanded && 'has-text-dark')} to="/jobs">
                <i className="fas fa-list" />
                {t('Overview.JobsListPage.title')}
              </NavLink>
              <NavLink id="help-link" className={classNames('navbar-item', expanded && 'has-text-dark')} to="/help">
                <i className="fas fa-question-circle" />
                {t('Overview.HelpPage.title')}
              </NavLink>
            </div>
          )}

          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                {(showItems === true || showItems === undefined) && (
                  <Button
                    id="logout-button"
                    i18nKey="Overview.Landing.logoutButton"
                    onClick={(): void => {
                      Api.logout();
                      window.location.reload();
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

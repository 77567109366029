import React from 'react';

import { Dropdown, DropdownActions, DropdownTexts } from './Dropdown/Dropdown';
import { SelectOption, getSelectedLabels } from './Dropdown/Options/Option';
import { FilterInput, FilterInputActions, FilterInputTexts } from './FilterInput/FilterInput';
import { Filter } from './FilterInput/filterUtils';
import { SimpleInput, SimpleInputActions, SimpleInputTexts } from './SimpleInput/SimpleInput';

export type SelectActions = FilterInputActions & DropdownActions & SimpleInputActions;

export interface SelectTexts {
  filter: FilterInputTexts;
  input: SimpleInputTexts;
  dropdown: DropdownTexts;
}

interface Props {
  readonly texts: SelectTexts;
  readonly icon: string;
  readonly name: string;
  readonly error?: string;
  readonly options: Map<string, SelectOption>;
  readonly selected: Set<string>;
  readonly filter: Filter;
  readonly actions: SelectActions;
  readonly required: boolean;
  readonly disabled?: boolean;
}

export const Select: React.FC<Props> = ({
  texts,
  icon,
  name,
  error,
  options,
  selected,
  filter,
  actions,
  required,
  disabled,
}) => {
  /* Actions */
  const filterInputActions: FilterInputActions = {
    clear: actions.clear,
    filter: actions.filter,
  };
  const dropdownActions: DropdownActions = {
    close: actions.close,
    select: actions.select,
    deselect: actions.deselect,
    selectAll: actions.selectAll,
    deselectAll: actions.deselectAll,
  };
  const simpleInputActions: SimpleInputActions = {
    focus: actions.focus,
  };
  return (
    <>
      {filter.active ? (
        <>
          <FilterInput texts={texts.filter} icon={icon} name={name} value={filter.by} actions={filterInputActions} />
          <Dropdown
            texts={texts.dropdown}
            options={options}
            selected={selected}
            filterBy={filter.by}
            actions={dropdownActions}
          />
        </>
      ) : (
        <SimpleInput
          texts={texts.input}
          leftIcon={icon}
          name={name}
          error={error}
          value={getSelectedLabels(options, selected).join(', ')}
          actions={simpleInputActions}
          required={required}
          disabled={disabled}
        />
      )}
    </>
  );
};

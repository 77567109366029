/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Colors } from 'modules/ui/colors/colors';
import React, { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { classNames } from '../../common/utils/classNames';
import { HtmlProps } from '../../common/utils/HTMLProps';

export interface Props extends HtmlProps {
  content: string | ReactNode;
  type: MessageType;
  onClose?: () => void;
  onCopyErrorId?: () => void;
}

export type MessageType = 'normal' | 'success' | 'error' | 'info' | 'warning' | 'loading';

export const Message: React.FC<Props> = ({ id, className, content, type, onClose, onCopyErrorId }): ReactElement => {
  const [t] = useTranslation();
  return (
    <div
      id={id}
      className={classNames('message', 'message-' + type, `is-${getColor(type)}`, 'is-light', className)}
      style={{ padding: '0px', margin: '0px', border: '0px' }}
    >
      <div className="message-header">
        <span>
          <i className={`${getIcon(type)}`} style={{ marginRight: '8px' }} />
          {t(`notification.type.${type}`)}
        </span>
        <span style={{ justifyContent: 'flex-end', marginLeft: '16px' }}>
          {onCopyErrorId && (
            <button onClick={onCopyErrorId} className={`button is-white is-rounded`} style={{ marginRight: '8px' }}>
              <i className="fas fa-copy" style={{ marginRight: '8px' }} /> {t(`notification.copyErrorId`)}
            </button>
          )}
          {onClose && (
            <button onClick={onClose} className="button is-white is-rounded" style={{ marginRight: '8px' }}>
              <i className="fas fa-times" style={{ marginRight: '8px' }} /> {t(`notification.close`)}
            </button>
          )}
        </span>
      </div>
      <div className="message-body has-text-black">{content}</div>
    </div>
  );
};

function getColor(type: MessageType): string {
  switch (type) {
    case 'success':
      return Colors.SUCCESS;
    case 'error':
      return Colors.DANGER;
    case 'info':
      return Colors.INFO;
    case 'warning':
      return Colors.WARNING;
    case 'loading':
    case 'normal':
    default:
      return Colors.LIGHT;
  }
}

function getIcon(type: MessageType): string {
  switch (type) {
    case 'success':
      return 'fas fa-check-circle';
    case 'error':
      return 'fas fa-times-circle';
    case 'info':
      return 'fas fa-info-circle';
    case 'warning':
      return 'fas fa-exclamation-triangle';
    case 'loading':
      return 'fas fa-spinner fa-spin';
    case 'normal':
    default:
      return 'is-hidden';
  }
}

import { isValidationError } from 'common/interfaces/common';
import { classNames } from 'common/utils/classNames';
import React, { ReactElement } from 'react';

export interface Props {
  texts: {
    label: string;
  };
  leftIcon?: string;
  rightIcon?: string;
  rightIconAction?: () => void;
  name: string;
  error?: string;
  errors?: unknown;
  required?: boolean;
}

function getError(errors: unknown, fieldName: string): string | undefined {
  if (!isValidationError(errors)) {
    return undefined;
  }
  const firstError = errors.errors.filter((err): boolean => err.field === fieldName)[0];
  if (firstError) {
    return firstError.field;
  }
}
export const Field: React.FC<Props> = ({
  leftIcon,
  rightIcon,
  rightIconAction,
  name,
  texts,
  children,
  error,
  errors,
  required,
}): ReactElement => {
  const fieldError = error ? error : getError(errors, name);
  return (
    <div className={classNames('field', required && 'is-required')}>
      <label className="label" htmlFor={`${name}-input`} data-testid={texts.label}>
        {texts.label}
      </label>
      <div
        className={classNames(
          'control',
          leftIcon && 'has-icons-left',
          rightIcon && 'has-icons-right',
          fieldError && 'is-danger',
        )}
      >
        {children}
        <span className="icon is-left">
          <i className={`fas ${leftIcon}`} />
        </span>
        {rightIconAction ? (
          <span
            style={{ pointerEvents: 'initial', cursor: 'pointer' }}
            className="icon is-right"
            onClick={rightIconAction}
          >
            <i className={`fas ${rightIcon}`} />
          </span>
        ) : (
          <span className="icon is-right">
            <i className={`fas ${rightIcon}`} />
          </span>
        )}
      </div>
      <p className="help is-danger">{fieldError}</p>
    </div>
  );
};

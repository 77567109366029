import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { classNames } from '../../common/utils/classNames';

export interface Props {
  i18nKey: string;
  icon?: string;
  title?: string;
}

export const Property: React.FC<Props> = ({ i18nKey, icon, title, children }): ReactElement => {
  const [t] = useTranslation();
  return (
    <div className="field">
      <label className="label" data-testid={`${i18nKey}.label`}>
        {t(`${i18nKey}.label`)}
      </label>
      <div className={classNames('control', icon && 'has-icons-left')}>
        <div className="input property" title={title}>
          {children}
        </div>
        <span className="icon is-left">
          <i className={`fas ${icon}`} />
        </span>
      </div>
    </div>
  );
};

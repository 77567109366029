import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Translatable } from '../../../common/language';

export const ToBeCreatedTasks: React.FC<Translatable & { languageInfo: { targetLanguageCodes: string[] }[] }> = ({
  i18nKey,
  languageInfo,
}): ReactElement => {
  const [t] = useTranslation();

  return (
    <div className="overview-line">
      {t(`${i18nKey}.totalTasks`)}
      <span className="is-pulled-right metric" id="total-tasks">
        {languageInfo.map(info => info.targetLanguageCodes.length).reduce((total, current) => total + current, 0)}
      </span>
    </div>
  );
};

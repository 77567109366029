import { Api } from 'modules/api/api';
import { AssetState } from 'modules/api/locHub/assets/asset/state/state';

import { TaskError } from '../../error/error';
import { TaskId } from '../../id/id';
import { TaskDto } from '../../task';
import { ComputedStatus, TapiccStatus, TaskStatus } from '../status';

export class CombinedTaskStatus {
  public constructor(
    public readonly original: TapiccStatus,
    public readonly computed: TaskStatus,
    public readonly error: TaskError | null = null,
  ) {}
}

export class TaskStatusFactory {
  private static async getPendingStatus(taskId: TaskId, taskStatus: TapiccStatus): Promise<CombinedTaskStatus> {
    const inputs = await Api.locHub.inputs.getAllByTask(taskId);
    if (inputs.length) {
      let downloadError: string | null = null;
      let downloadInProgress = false;
      for (const input of inputs) {
        const asset = await Api.locHub.assets.getDto(input.assetId);
        if (asset.downloadError) {
          downloadError = asset.downloadError;
          break;
        }
        if (asset.state === AssetState.INCOMPLETE) {
          downloadInProgress = true;
        }
      }
      if (downloadError) {
        return new CombinedTaskStatus(taskStatus, ComputedStatus.DOWNLOAD_FAILED, {
          id: downloadError,
          parameters: {},
        });
      }
      if (downloadInProgress) {
        return new CombinedTaskStatus(taskStatus, ComputedStatus.DOWNLOAD_IN_PROGRESS);
      }
    }
    return new CombinedTaskStatus(taskStatus, TapiccStatus.PENDING);
  }

  private static async getCompletedStatus(taskId: TaskId, taskStatus: TapiccStatus): Promise<CombinedTaskStatus> {
    const deliverables = await Api.locHub.deliverables.getAllByTask(taskId);
    if (deliverables.length) {
      for (const deliverable of deliverables) {
        if (!deliverable.resultUploadedAt) {
          return new CombinedTaskStatus(taskStatus, taskStatus);
        }
      }
      return new CombinedTaskStatus(taskStatus, ComputedStatus.CLOSED);
    }
    return new CombinedTaskStatus(taskStatus, taskStatus);
  }

  public static async create(task: TaskDto): Promise<CombinedTaskStatus> {
    switch (task.status) {
      case TapiccStatus.PENDING:
        return TaskStatusFactory.getPendingStatus(task.id, task.status);
      case TapiccStatus.COMPLETED:
      case TapiccStatus.COMPLETED_WITH_WARNINGS:
        return TaskStatusFactory.getCompletedStatus(task.id, task.status);
      default:
        return new CombinedTaskStatus(task.status, task.status);
    }
  }
}

import { Api } from 'modules/api/api';
import { QueryConfiguration } from 'modules/api/locHub/entity/query/configuration/configuration';
import { useLocHubDtoQuery } from 'modules/api/locHub/entity/query/detail/detail';
import { QueryResult } from 'modules/api/locHub/entity/query/query';

import { LanguageId } from '../../language/id/id';
import { LanguageDto } from '../../language/language';

export const useLanguageDto = (
  id: LanguageId | undefined,
  configuration: QueryConfiguration<LanguageDto | undefined, unknown> = { error: {}, query: {} },
): QueryResult<LanguageDto> => {
  return useLocHubDtoQuery(Api.locHub.languages, id, configuration);
};

import { Page } from 'common/interfaces/common';

import { Api } from '../api';
import { HttpError } from '../error/error';
import { Assets } from './assets/assets';
import { Automations } from './automations/automations';
import { Deliverables } from './deliverables/deliverables';
import { LocHubHttpError } from './error/error';
import { Inputs } from './inputs/inputs';
import { Jobs } from './jobs/jobs';
import { Languages } from './languages/languages';
import { Projects } from './projects/projects';
import { Tasks } from './tasks/tasks';
import { Users } from './users/users';
import { XillioApi } from './xillioApi/xillioApi';

export type LocHubApi =
  | typeof Automations
  | typeof Projects
  | typeof Jobs
  | typeof Users
  | typeof Tasks
  | typeof Inputs
  | typeof Assets
  | typeof Deliverables
  | typeof Languages;

export class LocHub {
  /* Size of the page for get all requests */
  private static readonly BUFFER_LIMIT = 1000;
  public static readonly automations = Automations;
  public static readonly projects = Projects;
  public static readonly jobs = Jobs;
  public static readonly users = Users;
  public static readonly tasks = Tasks;
  public static readonly inputs = Inputs;
  public static readonly xillioApi = XillioApi;
  public static readonly assets = Assets;
  public static readonly deliverables = Deliverables;
  public static readonly languages = Languages;

  private static async getResponse(response: Response): Promise<Response> {
    try {
      return await Api.getResponse(response);
    } catch (error) {
      if (error instanceof HttpError && LocHubHttpError.satisfy(error.body)) {
        throw new LocHubHttpError(error.response, error.body);
      }
      throw error;
    }
  }

  private static async getJsonResponse<ResponseBody>(response: Response): Promise<ResponseBody> {
    try {
      return await Api.getJsonResponse<ResponseBody>(response);
    } catch (error) {
      if (error instanceof HttpError && LocHubHttpError.satisfy(error.body)) {
        throw new LocHubHttpError(error.response, error.body);
      }
      throw error;
    }
  }

  private static async handleResponse(response: Response): Promise<void> {
    try {
      await Api.getResponse(response);
    } catch (error) {
      if (error instanceof HttpError && LocHubHttpError.satisfy(error.body)) {
        throw new LocHubHttpError(error.response, error.body);
      }
      throw error;
    }
  }

  private static async getJsonRequest<ResponseBody>(
    requestUrl: URL,
    headers: Record<string, string>,
  ): Promise<ResponseBody> {
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${Api.getTokenDetail().accessToken}`,
        ...headers,
      },
    });
    return LocHub.getJsonResponse<ResponseBody>(response);
  }

  public static async getVersion(): Promise<string> {
    const ping: { version: string } = await Api.getJson(Api.getUrl('/api/system/ping'));
    return ping.version;
  }

  public static async getJson<Response>(url: URL, headers: Record<string, string> = {}): Promise<Response> {
    return LocHub.getJsonRequest(url, headers);
  }

  public static async getAll<Entity>(
    path: string,
    parameters: Record<string, string | number | undefined> = {},
  ): Promise<Entity[]> {
    let pageNumber = 1;
    const result: Entity[] = [];
    while (true) {
      const page: Page<Entity> = await LocHub.getJson(
        Api.getUrl(path, {
          ...parameters,
          page: pageNumber,
          limit: LocHub.BUFFER_LIMIT,
        }),
      );
      if (page.content.length) {
        result.push(...page.content);
      }
      if (page.content.length < LocHub.BUFFER_LIMIT) {
        return result;
      }
      pageNumber++;
    }
  }

  public static async post(url: URL): Promise<void> {
    const response = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${Api.getTokenDetail().accessToken}`,
      },
    });
    return LocHub.handleResponse(response);
  }

  public static async postJson<Body, ResponseBody>(url: URL, body: Body): Promise<ResponseBody> {
    const response = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${Api.getTokenDetail().accessToken}`,
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    return LocHub.getJsonResponse<ResponseBody>(response);
  }

  public static async patchJson<ResponseBody>(url: URL, body: Partial<ResponseBody>): Promise<ResponseBody> {
    const response = await fetch(url.toString(), {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${Api.getTokenDetail().accessToken}`,
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    return LocHub.getJsonResponse<ResponseBody>(response);
  }

  public static async putFile(url: URL, body: string | ArrayBuffer): Promise<Response> {
    const response = await fetch(url.toString(), {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${Api.getTokenDetail().accessToken}`,
        'content-type': 'application/octet-stream',
      },
      body,
    });

    return LocHub.getResponse(response);
  }

  public static async delete(url: URL): Promise<void> {
    const response = await fetch(url.toString(), {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${Api.getTokenDetail().accessToken}`,
      },
    });
    return LocHub.handleResponse(response);
  }

  public static async downloadFile(url: URL, fileName: string): Promise<void> {
    try {
      return await Api.downloadFile(
        url,
        fileName,
        new Headers({
          Authorization: `Bearer ${Api.getTokenDetail().accessToken}`,
        }),
      );
    } catch (error) {
      if (error instanceof HttpError && LocHubHttpError.satisfy(error.body)) {
        throw new LocHubHttpError(error.response, error.body);
      }
      throw error;
    }
  }
}

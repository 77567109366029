import { classNames } from 'common/utils/classNames';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { SubmitInputI18n } from './I18n';

interface Props {
  loading: boolean;
  disabled: boolean;
}

export const SubmitInput: React.FC<Props> = ({ loading, disabled }): ReactElement => {
  const [t] = useTranslation();
  const submitButtonText: string = t(`${SubmitInputI18n.configuration.namespace}.label`);
  if (loading) {
    return (
      <button type="submit" id="submit-button" className={classNames('button', 'is-success')} disabled={true}>
        <i className="fas fa-spin fa-spinner" style={{ marginRight: '8px' }} /> {submitButtonText}
      </button>
    );
  }
  return (
    <input
      type="submit"
      id="submit-button"
      className={classNames('button', 'is-success')}
      disabled={disabled}
      value={submitButtonText}
    />
  );
};

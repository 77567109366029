import { Api } from 'modules/api/api';
import { ProjectId } from 'modules/api/locHub/projects/project/id/id';
import { AutomationListPageCrumb } from 'pages/Administration/AutomationListPage/AutomationListPage';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAsync } from 'react-use';

export interface Props {
  projectId: ProjectId;
}

export const ProjectAutomationTag: React.FC<Props> = ({ projectId }): ReactElement => {
  const [t] = useTranslation();
  const automations = useAsync(Api.locHub.automations.getAllByProject.bind(undefined, projectId));
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if (automations.loading || automations.error || !automations.value!.length) {
    return <></>;
  }
  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
      <Link to={`${AutomationListPageCrumb.location}/${automations.value![0].id}`} style={{ marginRight: '4px' }}>
        <span className="tag is-light is-info">{t('Overview.ProjectListPage.automation')}</span>
      </Link>
    </>
  );
};
